import { message, notification } from "antd";
import axios from "axios";
import { useMutation } from "react-query";

export const slackApi = axios.create({
  baseURL: "https://slack.com/api",
});

slackApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = "/conversations";
    }
    return Promise.reject(error);
  }
);

// slackApi.interceptors.request.use((config) => {
//   if (config.method === "post") {
//     config.data = {
//       ...config.data,
//       token: "token", // Replace "token" with the actual token value
//     };
//   }
//   return config;
// });
export function useUserEmail() {
  const url = "users.profile.get";
  return useMutation(
    async (payload: FormData) => {
      const res = await slackApi.post(url, payload, {
        transformRequest: [
          (data, headers) => {
            delete headers["Content-Type"];
            return data;
          },
        ],
      });
      return res.data;
    },
    {
      onSuccess: (response: any) => {
        console.log(response);
        notification.success({
          message: "Success!",
          description: "User info fetched successfully.",
        });
      },
      onError: (error: any) => {
        // console.log(" ERROR", error);
        notification.error({
          message: "Error!",
          description: "Failed to get user info.",
        });
      },
    }
  );
}
export function useGetUsersInfo() {
  const url = "users.info";
  return useMutation(
    async (payload: FormData) => {
      const res = await slackApi.post(url, payload, {
        transformRequest: [
          (data, headers) => {
            delete headers["Content-Type"];
            return data;
          },
        ],
      });
      return res.data;
    },
    {
      onSuccess: (response: any) => {
        console.log(response);
        notification.success({
          message: "Success!",
          description: "User info fetched successfully.",
        });
      },
      onError: (error: any) => {
        // console.log(" ERROR", error);
        notification.error({
          message: "Error!",
          description: "Failed to get user info.",
        });
      },
    }
  );
}
export function useUserId() {
  const url = "users.lookupByEmail";
  return useMutation(
    async (payload: FormData) => {
      const res = await slackApi.post(url, payload, {
        transformRequest: [
          (data, headers) => {
            delete headers["Content-Type"];
            return data;
          },
        ],
      });
      return res.data;
    },
    {
      onSuccess: (response: any) => {
        // console.log(response);
        notification.success({
          message: "Success!",
          description: "User details fetched successfully..",
        });
      },
      onError: (error: any) => {
        console.log("CONVERSATION ERROR", error);
        notification.error({
          message: "Error!",
          description: "Failed to get User.",
        });
      },
    }
  );
}

export function useStartConversation() {
  const url = "conversations.create";
  return useMutation(
    async (payload: FormData) => {
      const res = await slackApi.post(url, payload, {
        transformRequest: [
          (data, headers) => {
            delete headers["Content-Type"];
            return data;
          },
        ],
      });
      return res.data;
    },
    {
      onSuccess: (response: any) => {
        // console.log(response);
        notification.success({
          message: "Success!",
          description: "New conversation created successfully.",
        });
      },
      onError: (error: any) => {
        console.log("CONVERSATION ERROR", error);
        notification.error({
          message: "Error!",
          description: "Failed to create conversation.",
        });
      },
    }
  );
}
export function useListConversation() {
  const url = "conversations.list";
  return useMutation(
    async (payload: Record<string, string> | FormData) => {
      const res = await slackApi.post(url, payload, {
        transformRequest: [
          (data, headers) => {
            delete headers["Content-Type"];
            return data;
          },
        ],
      });
      return res.data;
    },
    {
      onSuccess: (response: any) => {
        // console.log(response);
      },
      onError: (error: any) => {
        console.log("CONVERSATION ERROR", error);
        notification.error({
          message: "Error!",
          description: "Failed to get conversations.",
        });
      },
    }
  );
}
export function useConversationHistory() {
  const url = "conversations.history";
  return useMutation(
    async (payload: Record<string, string> | FormData) => {
      const res = await slackApi.post(url, payload, {
        transformRequest: [
          (data, headers) => {
            delete headers["Content-Type"];
            return data;
          },
        ],
      });
      return res.data;
    },
    {
      onSuccess: (response: any) => {
        // console.log(response);
        // notification.success({
        //   message: "Success!",
        //   description: "Fetched conversations successfully.",
        // });
      },
      onError: (error: any) => {
        console.log("CONVERSATION ERROR", error);
        notification.error({
          message: "Error!",
          description: "Failed to get previous conversation.",
        });
      },
    }
  );
}
export function useFileUpload() {
  const url = "files.upload";
  return useMutation(
    async (payload: Record<string, string> | FormData) => {
      const res = await slackApi.post(url, payload, {
        transformRequest: [
          (data, headers) => {
            delete headers["Content-Type"];
            return data;
          },
        ],
      });
      return res.data;
    },
    {
      onSuccess: (response: any) => {
        // console.log(response);
        notification.success({
          message: "Success!",
          description: "File shared successfully.",
        });
      },
      onError: (error: any) => {
        console.log("CONVERSATION ERROR", error);
        notification.error({
          message: "Error!",
          description: "Failed to share file.",
        });
      },
    }
  );
}
export function useInvite() {
  const url = "conversations.invite";
  return useMutation(
    async (payload: Record<string, string> | FormData) => {
      const res = await slackApi.post(url, payload, {
        transformRequest: [
          (data, headers) => {
            delete headers["Content-Type"];
            return data;
          },
        ],
      });
      return res.data;
    },
    {
      onSuccess: (response: any) => {
        // console.log(response);
        notification.success({
          message: "Success!",
          description: "Invitation sent successfully",
        });
      },
      onError: (error: any) => {
        console.log("INVITATION ERROR", error);
        notification.error({
          message: "Error!",
          description: "Invitation failed",
        });
      },
    }
  );
}
export function usePostConversation() {
  const url = "chat.postMessage";
  return useMutation(
    async (payload: Record<string, string> | FormData) => {
      const res = await slackApi.post(url, payload, {
        transformRequest: [
          (data, headers) => {
            delete headers["Content-Type"];
            return data;
          },
        ],
      });
      return res.data;
    },
    {
      onSuccess: (response: any) => {
        // console.log(response);
        // notification.success({
        //   message: "Success!",
        //   description: "Fetched conversations successfully.",
        // });
      },
      onError: (error: any) => {
        console.log("POST MESSAGE ERROR", error);
        notification.error({
          message: "Error!",
          description: "Failed to send message.",
        });
      },
    }
  );
}
export function useListMembers() {
  const url = "conversations.members";
  return useMutation(
    async (payload: Record<string, string> | FormData) => {
      const res = await slackApi.post(url, payload, {
        transformRequest: [
          (data, headers) => {
            delete headers["Content-Type"];
            return data;
          },
        ],
      });
      return res.data;
    },
    {
      onSuccess: (response: any) => {
        // console.log(response);
        // notification.success({
        //   message: "Success!",
        //   description: "Fetched conversations successfully.",
        // });
      },
      onError: (error: any) => {
        console.log("CONVERSATION MEMBERS ERROR", error);
        notification.error({
          message: "Error!",
          description: "Failed to send message.",
        });
      },
    }
  );
}
