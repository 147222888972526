import { useContext } from "react";
import { AxiosContext } from "../../context/AxiosContext";
import { useQuery } from "react-query";
import { getRequest } from "../../context/requestTypes";
import { AxiosInstance } from "axios";
import { notification } from "antd";
import { useLocation, useNavigate } from "react-router";
import { checkOddValue } from "../../context/utils";

export function useGetSearchResults(id: string) {
  const url = "/api_backend/lectures/general-search?search=";
  const navigate = useNavigate();
  const location = useLocation();
  const axios = useContext(AxiosContext);
  return useQuery(
    ["get:search_results", id],
    () => getRequest(axios as unknown as AxiosInstance, url + id),
    {
      onError: (error: any) => {
        if (error?.message?.includes("authenticated")) {
          sessionStorage.setItem("fallback", JSON.stringify(location));
          navigate("/auth");
        }
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        });
      },
      enabled: Boolean(!checkOddValue(id)),
    }
  );
}

export function useGetSuggestions(id: string) {
  const url = "/api_backend/lectures/suggestions?search=";
  const navigate = useNavigate();
  const location = useLocation();
  const axios = useContext(AxiosContext);
  return useQuery(
    ["get:search_results", id],
    () => getRequest(axios as unknown as AxiosInstance, url + id),
    {
      onError: (error: any) => {
        if (error?.message?.includes("authenticated")) {
          sessionStorage.setItem("fallback", JSON.stringify(location));
          navigate("/auth");
        }
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        });
      },
      enabled: Boolean(id.length),
      refetchInterval: 2000,
    }
  );
}
