import { Button, Modal, notification } from "antd";
import React, { useState } from "react";
import { useInvite, useUserId } from "../../hooks/conversations/conversations";
import { useRecoilState, useRecoilValue } from "recoil";
import { slackAuthAtom } from "../../atoms/auth/slack.atom";
import { useParams } from "react-router-dom";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const InviteSlackUser = ({ open, setOpen }: Props) => {
  const { id } = useParams<{ id: string }>();
  const [email, setEmail] = useState("");
  const slackUser = useRecoilValue(slackAuthAtom);
  const { mutate: mailMutate, isLoading: mailLoading } = useUserId();
  const { mutate: invitationMutate, isLoading: inviteLoading } = useInvite();
  const inviteUser = () => {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("token", slackUser?.authed_user?.access_token);

    mailMutate(formData, {
      onSuccess: (data) => {
        if (!data?.user?.id) {
          notification.warning({
            message: "User not found",
          });
        } else {
          const payload = new FormData();
          payload.append("channel", id!!);
          payload.append("token", slackUser?.authed_user?.access_token);
          payload.append("users", data?.user?.id);
          invitationMutate(payload);
        }
      },
    });
  };

  return (
    <main>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        footer={false}
        closeIcon={false}
        className="w-full lg:max-w-[614px]"
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
      >
        <form action="">
          <div className="flex flex-col">
            <label className="px-3 py-2 rounded-full text-[12px] bg-black text-white w-fit z-10 relative ">
              Email
            </label>
            <input
              type="text"
              placeholder="enter Email of user"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="-mt-2 border border-gray p-3 rounded-xl"
            />
          </div>

          <div className="w-fit mx-auto">
            <Button
              loading={inviteLoading || mailLoading}
              onClick={inviteUser}
              className="mx-auto w-fit bg-primary rounded-full mt-5 text-white hover:text-white"
            >
              Invite
            </Button>
          </div>
        </form>
      </Modal>
    </main>
  );
};

export default InviteSlackUser;
