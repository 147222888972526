import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist({
  key: "lecture_key",
  storage: localStorage,
});

export default atom({
  key: "lecture_key",
  default: {
    lecture: {},
  },
  effects_UNSTABLE: [persistAtom],
});
