import { useContext } from "react";
import { useMutation, useQuery } from "react-query";
import { getRequest } from "../../context/requestTypes"
import { AxiosContext } from "../../context/AxiosContext";
import { AxiosInstance } from "axios";
import { notification } from "antd";


export function useGetUserChallenge(successAction?: any) {
  const url = "/api_backend/user/get_user_challenge";
  const axios = useContext(AxiosContext);
  return useQuery(
    ["get:user_challenge"],
    () => getRequest(axios as unknown as AxiosInstance, url),
    {
      onSuccess: (response: any) => {
        successAction?.(response);
      },
      onError: (error: any) =>
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        }),
    }
  );
}

export function useGetUserClaimBonus(successAction?: any) {
  const url = "/api_backend/user/claim_challenge_bonus";
  const axios = useContext(AxiosContext);
  return useMutation(
    () => getRequest(axios as unknown as AxiosInstance, url),
    {
      onSuccess: (response: any) => {
        successAction?.(response);
        notification.success({
          message: "Success!",
          description: response?.message || "action successful.",
        })
      },
      onError: (error: any) =>
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        }),
    }
  );
}

export function useGetUserLeaderboard(successAction?: any) {
  const url = "/api_backend/user/user_learderboard";
  const axios = useContext(AxiosContext);
  return useQuery(
    ["get:user_leaderboard"],
    () => getRequest(axios as unknown as AxiosInstance, url),
    {
      onSuccess: (response: any) => {
        successAction?.(response);
      },
      onError: (error: any) =>
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        }),
    }
  );
}


export function useGetLeaderboard(successAction?: any) {
  const url = "/api_backend/user/users_leaderboard_ranking";
  const axios = useContext(AxiosContext);
  return useQuery(
    ["get:leaderboard"],
    () => getRequest(axios as unknown as AxiosInstance, url),
    {
      onSuccess: (response: any) => {
        successAction?.(response);
      },
      onError: (error: any) =>
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        }),
    }
  );
}