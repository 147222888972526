import React from "react";
import logo from "../../../assets/Nurovant-logo.svg";
type Props = {};

const AuthenticateConversations = (props: Props) => {
  return (
    <main className="min-h-[100svh] flex justify-center items-center px-5">
      <section className="text-center max-w-2xl">
        <figure className="w-fit mx-auto">
          <img src={logo} alt="" className="w-44 h-44 object-contain" />
        </figure>
        <h1 className="text-[18px] font-medium text-gray/90 mb-5">
          Start wonderful study groups with nurovant.
        </h1>
        <div className="space-y-3 pb-5">
          <a href="https://slack.com/oauth/v2/authorize?redirect_uri=https://nurovant-webapp-staging.vercel.app/conversations&client_id=6818908487970.6812799660230&scope=&user_scope=chat:write,channels:write,channels:read,groups:write,im:write,mpim:write,users.profile:read,groups:read,channels:history,groups:history,mpim:history,im:history,users:read,users:read.email,files:write">
            <button className="bg-primary p-3 px-10 rounded-full text-white">
              Sign in with Slack
            </button>
          </a>
          <p className="hover:underline text-primary font-medium">
            <a
              href="https://join.slack.com/t/nurovantai/shared_invite/zt-2f6dkuji9-FgFfDl19fvL167py3whleA"
              target="__blank"
              referrerPolicy="no-referrer"
            >
              Don't have a workspace. Join Us
            </a>
          </p>
        </div>
        <p className="text-sm text-gray/70">
          Click the join us button above to join our workspace, which gives you
          access to our workspace which gives you access to our carefully
          curated and interactive study groups.
        </p>
        <p className="text-primary mt-6 text-sm">
          Nurovant conversations is powered by Slack
        </p>
      </section>
    </main>
  );
};

export default AuthenticateConversations;
