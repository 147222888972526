import React from 'react';
import { Carousel, Image } from 'antd';
import { Link } from 'react-router-dom';
import questionicon from "../assets/auth/question-mark.svg";
import spinicon from "../assets/auth/star-spin.svg";
import chaticon from "../assets/auth/live-chat.svg";
import bookicon from "../assets/auth/book-icon.svg";


type Props = {
  description?: string,
  className?: any,
  children?: any,
  title?: string,
  icon?: any,
}
function ImageCardContainer({
  className,
  children,
}: Props) {
  const slides = [
    {
      icon: questionicon,
      title: "Engaging Quiz",
      description: "Test your understanding, reinforce concepts, and stay on top of your game.",
    },
    {
      icon: spinicon,
      title: "Quick Recaps",
      description: "Effortlessly navigate through your lecture recordings and revisit key moments.",
    },
    {
      icon: chaticon,
      title: "Live chats",
      description: "Discuss concepts, ask questions, and collaborate seamlessly during lectures.",
    },
    {
      icon: bookicon,
      title: "Study Guides",
      description: "Organise your learning materials effortlessly.",
    },
    {
      icon: bookicon,
      title: "Flashcards",
      description: "Create personalized digital flashcards to reinforce key concepts from your lectures.",
    },
  ]
  return (
    <div className="w-full bg-primary flex flex-col lg:flex-row h-screen max-h-screen overflow-hidden items-center justify-center">
      {/* Left Section*/}
      <div className="w-full md:w-[50%] lg:w-[55%] !h-full bg-pattern bg-center bg-no-repeat hidden lg:flex flex-col justify-center items-center'">
        <Carousel className='!w-full !h-full' autoplay fade dots={false}>
          {slides.map(({icon, title, description}) => (
            <div key={title} className='!w-full !h-full !flex !flex-col !justify-center !items-center gap-5 text-white'>
              <Link to="/auth"><Image alt={title} src={icon} preview={false} /></Link>
              <div className="flex flex-col items-center justify-center gap-[24px]">
                <p className="text-[44px] leading-[48px] font-bold tracking-[-0.88px] text-center">{title || "Record Live Lectures"}</p>
                <p className="text-[16px] leading-[25px] font-medium tracking-[0.32px] text-center max-w-[427px]">{description || "Capturing Knowledge, One Live Lecture at a Time."}</p>
              </div>
            </div>
          ))}
        </Carousel>
      </div>

      {/* Right Section*/}
      <div className="w-full md:w-[50%] lg:w-[45%] h-full flex flex-col justify-center items-center">
        <div className={`w-full md:w-[95%] mx-auto h-full md:h-[95%] overflow-y-auto bg-white rounded-lg ${className}`}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default ImageCardContainer