import { Modal, message } from "antd";
import React, { useState } from "react";
import {
  useAddFlashcards,
  useEditFlashcards,
  useGetFlashcard,
} from "../../hooks/flashcards/flashcards";
import { useSearchParams } from "react-router-dom";
import { VscLoading } from "react-icons/vsc";

type Props = {
  open: boolean;
  setShowCustomize: React.Dispatch<React.SetStateAction<boolean>>;
  setCustomized: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddFlashcardModal = ({
  open,
  setShowCustomize,
  setCustomized,
}: Props) => {
  const [meaningValue, setMeaningValue] = useState("");
  const [word, setWord] = useState("");
  const [params] = useSearchParams();
  const id = params.get("id");

  const { mutate: addMutate, isLoading } = useAddFlashcards(id!);

  const handleSubmit = () => {
    const payload = {
      word,
      meaning: meaningValue,
    };
    if (!word || !meaningValue) {
      message.warning("enter word and meaning");
    } else {
      addMutate(payload, {
        onSuccess: () => {
          setCustomized(true);
          setShowCustomize(false);
        },
      });
    }
  };
  return (
    <Modal
      open={open}
      onCancel={() => setShowCustomize(false)}
      footer={false}
      className="w-full lg:w-[640px]"
    >
      <main>
        <h1 className="text-center mb-[40px] font-bold text-[30px]">
          Add More Flashcards
        </h1>
        <section className="w-full space-y-[24px]">
          <div className="w-full space-y-2">
            <label className="font-medium text-[#646462] text-sm">
              Name of flashcard
            </label>
            <input
              type="text"
              value={word}
              onChange={(e) => setWord(e.target.value)}
              className="w-full py-3 px-[25px] border border-[#67676766] rounded-xl"
            />
          </div>
          <div className="w-full space-y-2">
            <label className="font-medium text-[#646462] text-sm">
              Meaning
            </label>
            <textarea
              value={meaningValue}
              onChange={(e) => setMeaningValue(e.target.value)}
              // rows={1}
              className="w-full py-3 px-[25px] border border-[#67676766] rounded-xl"
            />
          </div>
        </section>
        <div className="mx-auto w-fit mt-10">
          <button
            onClick={handleSubmit}
            className="rounded-xl mx-auto w-fit py-4 px-9 text-sm border border-primary text-primary"
          >
            {isLoading ? <VscLoading className="animate-spin" /> : <p>Save</p>}
          </button>
        </div>
      </main>
    </Modal>
  );
};

export default AddFlashcardModal;
