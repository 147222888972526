import React from "react";
import { Image } from "antd";
import { Link } from "react-router-dom";
import wlogo from "../assets/WelcomeLogo.png";
import icon from "../assets/icons/icon.png";
import logo from "../assets/logo.png";

type Props = {
  isIcon?: boolean;
  noLink?: boolean;
  isWLogo?: boolean;
  className?: string;
};
function Logo({ isIcon, isWLogo, noLink, className }: Props) {
  const image = isIcon ? icon : isWLogo ? wlogo : logo;
  return (
    <Link to={noLink ? "" : "/"} className="w-auto">
      <Image
        alt="logo"
        src={image}
        preview={false}
        className={`w-auto mx-auto ${className}`}
      />
    </Link>
  );
}

export default Logo;
