import { notification } from "antd";
import { useMutation, useQuery } from "react-query";
import {
  deleteRequest,
  getRequest,
  postRequest,
} from "../../context/requestTypes";
import { useContext } from "react";
import { AxiosContext } from "../../context/AxiosContext";
import { AxiosInstance } from "axios";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import authAtom from "../../atoms/auth/auth.atom";
import { useLocation, useNavigate } from "react-router";
import lectureAtom from "../../atoms/lecture/lecture.atom";
import { analytics } from "../../firebaseAuth/config";
import { logEvent } from "firebase/analytics";

export function useGetLectures(params?: any) {
  const url = `/api_backend/lectures`;
  const axios = useContext(AxiosContext);
  return useQuery(
    ["get:all_lectures"],
    () => getRequest(axios as unknown as AxiosInstance, url, params),
    {
      onError: (error: any) =>
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        }),
      onSuccess: (response: any) => {
        // console.log(response);
      },
    }
  );
}

export function useGetLecture(id: string, successAction?: any) {
  const url = `/api_backend/lectures/`;
  const axios = useContext(AxiosContext);
  return useQuery(
    ["get:lecture", id],
    () => getRequest(axios as unknown as AxiosInstance, url + id),
    {
      enabled: Boolean(id),
      onError: (error: any) =>
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        }),
      onSuccess: (response: any) => successAction?.(response),
    }
  );
}

export function usePostLecture(successAction?: any) {
  const url = "/api_backend/lectures/create";
  const axios = useContext(AxiosContext);
  return useMutation(
    async (payload: any) =>
      postRequest(axios as unknown as AxiosInstance, url, payload),
    {
      onSuccess: (response: any) => {
        successAction?.(response);
        logEvent(analytics, "create_lecture");
        notification.success({
          message: "Success!",
          description: response?.message || "action successful.",
        });
      },
      onError: (error: any) =>
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        }),
    }
  );
}

export function useGetLectureById(id: string, enabled?: boolean) {
  const url = "/api_backend/lectures/";
  const navigate = useNavigate();
  const location = useLocation();
  const axios = useContext(AxiosContext);
  const setLectures = useSetRecoilState(lectureAtom);
  return useQuery(
    ["get:single_lecture", id],
    () => getRequest(axios as unknown as AxiosInstance, url + id),
    {
      enabled: Boolean(enabled || id),
      onError: (error: any) => {
        if (error?.message?.includes("authenticated")) {
          sessionStorage.setItem("fallback", JSON.stringify(location));
          navigate("/auth");
        }
        if (error?.message?.includes("not exist")) {
        } else {
          notification.error({
            message: "Error!",
            description: error?.message
              ? Object.entries(error?.errors || { key: [error?.message] })
                  ?.map(([, value]) => (value as any)?.join(", "))
                  ?.join(", ")
              : "something went wrong please check internet connection.",
          });
        }
      },
      onSuccess: (response: any) => {
        setLectures({ lecture: response });
      },
    }
  );
}

export function useDeleteLecture(successAction?: any, errorAction?: any) {
  const axios = useContext(AxiosContext);
  const url = "/api_backend/lectures/";
  return useMutation(
    async (id: any) =>
      deleteRequest(axios as unknown as AxiosInstance, url + id),
    {
      onSuccess: (response: any) => {
        successAction?.(response);
        logEvent(analytics, "delete_lecture");
        notification.success({
          message: "Success!",
          description: response?.message || "action successful.",
        });
      },
      onError: (error: any) => {
        errorAction?.();
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        });
      },
    }
  );
}
