import { notification } from "antd";
import { useMutation, useQuery } from "react-query";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../context/requestTypes";
import { useContext } from "react";
import { AxiosContext } from "../../context/AxiosContext";
import { AxiosInstance } from "axios";
import { analytics } from "../../firebaseAuth/config";
import { logEvent } from "firebase/analytics";

export function useGetAllCourses(params?: any) {
  const url = "/api_backend/courses";
  const axios = useContext(AxiosContext);
  return useQuery(
    ["get:all_courses"],
    () => getRequest(axios as unknown as AxiosInstance, url, params),
    {
      onError: (error: any) =>
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        }),
    }
  );
}

export function useGetCourse(id: string, params?: any) {
  const url = "/api_backend/courses/";
  const axios = useContext(AxiosContext);

  return useQuery(
    ["get:single_course", id, ...(params || [])],
    () => getRequest(axios as unknown as AxiosInstance, url + id),
    {
      enabled: Boolean(id),
      onError: (error: any) => {
        if (error?.message?.includes("not exist")) {
        } else {
          notification.error({
            message: "Error!",
            description: error?.message
              ? Object.entries(error?.errors || { key: [error?.message] })
                  ?.map(([, value]) => (value as any)?.join(", "))
                  ?.join(", ")
              : "something went wrong please check internet connection.",
          });
        }
      },
    }
  );
}

export function usePostCourses(successAction?: any, errorAction?: any) {
  const url = "/api_backend/courses/create";
  const axios = useContext(AxiosContext);
  return useMutation(
    async (payload: any) =>
      postRequest(axios as unknown as AxiosInstance, url, payload),
    {
      onSuccess: (response: any) => {
        successAction?.(response);
        logEvent(analytics, "create_course");
        notification.success({
          message: "Success!",
          description: response?.message || "action successful.",
        });
      },
      onError: (error: any) => {
        errorAction?.();
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        });
      },
    }
  );
}

export function useDeleteCourse(successAction?: any, errorAction?: any) {
  const axios = useContext(AxiosContext);
  const url = "/api_backend/courses/";
  return useMutation(
    async (id: any) =>
      deleteRequest(axios as unknown as AxiosInstance, url + id),
    {
      onSuccess: (response: any) => {
        successAction?.(response);
        logEvent(analytics, "delete_course");
        notification.success({
          message: "Success!",
          description: response?.message || "action successful.",
        });
      },
      onError: (error: any) => {
        errorAction?.();
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        });
      },
    }
  );
}

export function usePostCourseLecture(id: string, successAction?: any) {
  const url = `/api_backend/courses/add_lecture/${id}`;
  const axios = useContext(AxiosContext);

  return useMutation(
    async (payload: any) => {
      postRequest(axios as unknown as AxiosInstance, url, {
        lectures: [payload],
      });
    },
    {
      onSuccess: (response: any) => {
        successAction?.(response);
        logEvent(analytics, "create_course_lecture");
        notification.success({
          message: "Success!",
          description: response?.message || "action successful.",
        });
      },
      onError: (error: any) => {
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        });
      },
    }
  );
}

export function useDeleteCourseLecture(successAction?: any) {
  const url = `/api_backend/courses/remove_lecture/`;
  const axios = useContext(AxiosContext);

  return useMutation(
    async (id: any) => {
      postRequest(axios as unknown as AxiosInstance, url + id);
    },
    {
      onSuccess: (response: any) => {
        successAction?.(response);
        logEvent(analytics, "delete_course_lecture");
        notification.success({
          message: "Success!",
          description: response?.message || "action successful.",
        });
      },
      onError: (error: any) => {
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        });
      },
    }
  );
}
