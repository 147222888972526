import React, { useEffect, useState } from "react";
import useHistory, { useNavigate, useParams } from "react-router-dom";
import {
  slackApi,
  useConversationHistory,
  useFileUpload,
  useGetUsersInfo,
  useListMembers,
  usePostConversation,
} from "../../../hooks/conversations/conversations";
import { slackAuthAtom } from "../../../atoms/auth/slack.atom";
import { useRecoilValue } from "recoil";
import { BsSendArrowUp } from "react-icons/bs";
import { slackUserInfo } from "../../../atoms/conversations/userInfo";
import { IoIosSend, IoMdCloudUpload } from "react-icons/io";
import { MdClose } from "react-icons/md";
import { FaRegFileLines } from "react-icons/fa6";
import { Button } from "antd";
import { PiDotsThreeVerticalLight } from "react-icons/pi";
import InviteSlackUser from "../../../components/modals/InviteSlackUserModal";
import SlackGroupMembers from "../../../components/modals/SlackGroupMembers";
import { channelAtom } from "../../../atoms/conversations/channelAtom";
import { RiArrowGoBackFill } from "react-icons/ri";
import { getLinkPreview } from "link-preview-js";

type Props = {};

const Conversation = (props: Props) => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [openUpload, setOpenUpload] = useState(false);
  const [text, setText] = useState("");
  const [file, setFile] = useState<any>(null);
  const [messages, setMessages] = useState<any>([]);
  const [menuPopover, setMenuPopover] = useState(false);
  const [viewMmebers, setViewMembers] = useState(false);
  const [inviteModal, setInviteModal] = useState(false);
  const [members, setMembers] = useState([] as any);
  const slackUserProfile = useRecoilValue(slackUserInfo);
  const slackUser = useRecoilValue(slackAuthAtom);
  const slackChannel = useRecoilValue(channelAtom);
  const [linkPreviews, setLinkPreviews] = useState([] as any);

  useEffect(() => {
    // console.log("slackChannel", slackChannel);
  }, []);

  const { mutate, isLoading, data } = useConversationHistory();
  const {
    data: textData,
    isLoading: postLoading,
    mutate: postMutate,
  } = usePostConversation();
  const { mutate: fileMutate, isLoading: fileUploadLoading } = useFileUpload();
  const { data: membersData, mutate: membersMutate } = useListMembers();
  const { data: usersData, mutate: usersMutate } = useGetUsersInfo();
  const getHistory = () => {
    const formData = new FormData();
    formData.append("channel", id!!);
    formData.append("inclusive", "true");
    formData.append("token", slackUser?.authed_user?.access_token);
    mutate(formData);
  };

  const getMembers = () => {
    const formData = new FormData();
    formData.append("channel", id!!);
    formData.append("token", slackUser?.authed_user?.access_token);
    membersMutate(formData);
  };

  const postMessage = (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("channel", id!!);
    formData.append("text", text);
    formData.append("token", slackUser?.authed_user?.access_token);

    postMutate(formData, {
      onSuccess: () => {
        setText("");
        getHistory();
      },
    });
  };

  const uploadFile = () => {
    const formData = new FormData();
    formData.append("channels", id!!);
    formData.append("file", file!!);
    formData.append("token", slackUser?.authed_user?.access_token);

    fileMutate(formData);
  };
  useEffect(() => {
    if (id) {
      getHistory();
      getMembers();
      const interval = setInterval(getHistory, 5000);
      return () => clearInterval(interval);
    }
  }, [id]);

  useEffect(() => {
    const getFullMembersInfo = async () => {
      if (Array.isArray(membersData?.members)) {
        const promises = membersData.members.map(async (member: any) => {
          const formData = new FormData();
          formData.append("token", slackUser?.authed_user?.access_token);
          formData.append("user", member);
          // Add any other formData parameters you need

          return await slackApi.post("users.info", formData, {
            transformRequest: [
              (data, headers) => {
                delete headers["Content-Type"];
                return data;
              },
            ],
          });
        });

        Promise.all(promises)
          .then((results: any) => {
            setMembers(results.map((result: any) => result.data));
          })
          .catch((error) => {
            // Handle any errors that occurred during the promises
            console.error(error);
          });
      }
    };

    getFullMembersInfo();
  }, [membersData]);
  useEffect(() => {
    if (Array.isArray(data?.messages)) {
      const reversedMessages = [...data?.messages].reverse();
      // setMessages(reversedMessages);

      if (Array.isArray(members)) {
        const memberMap = members.reduce((map: any, member: any) => {
          map[member.user?.id] = member;
          return map;
        }, {});

        const messagesWithMembers = reversedMessages.map((message: any) => {
          const member = memberMap[message.user];
          return {
            ...message,
            memberName: member?.user?.profile?.real_name || "Unknown User",
            memberImage: member?.user?.profile?.image_24,
          };
        });
        setMessages(messagesWithMembers);
      }
    }
  }, [data, members]);

  const goBack = () => {
    navigate(-1);
  };

  const getPreview = async (text: string) => {
    const urlRegex = /https?:\/\/[^\s]+/g;
    const containsUrl = urlRegex.test(text);

    if (containsUrl) {
      const url = text.replace(/<|>/g, "");
      try {
        const data = await getLinkPreview(url); // Assuming getLinkPreview is async
        return data; // Return the fetched data
      } catch (error) {
        console.error("Error fetching link preview:", error);
        return {}; // Return an empty object on error
      }
    } else {
      return {}; // Return an empty object for non-URLs
    }
  };
  const processMessages = async () => {
    if (messages.length > 0) {
      const previews = await Promise.all(
        messages.map(async (message: any) => {
          let text = message.text;
          if (message.files && Array.isArray(message.files)) {
            message.files.forEach((file: any) => {
              if (file.url_private) {
                text += ` ${file.url_private}`;
              }
            });
          }
          const preview = await getPreview(text);
          // const preview = await getPreview(message.text);
          return preview;
        })
      );
      setLinkPreviews(previews);
    }
  };

  useEffect(() => {
    processMessages();
  }, [messages]);

  return (
    <main className="relative flex flex-col justify-between !w-full !max-w-full container h-full">
      <section className="bg-white h-full md:min-h-[80%] md:max-h-[80%] md:overflow-y-auto">
        <div className="bg-white fixed md:sticky top-[50px] md:top-0 md:py-3 left-0 font-bold flex items-center justify-between px-5 w-full">
          <div className="flex gap-3 items-center">
            <RiArrowGoBackFill
              className="w-8 h-8 cursor-pointer"
              onClick={goBack}
            />
            {typeof slackChannel === "string" ? (
              <h1 className="">{slackChannel}</h1>
            ) : null}
          </div>
          <div className="relative">
            <PiDotsThreeVerticalLight
              onClick={() => setMenuPopover(!menuPopover)}
              className="cursor-pointer font-bold h-8 w-8"
            />
            {menuPopover && (
              <div className="absolute space-y-4 top-8 right-2 bg-white rounded-xl p-3 shadow-md duration-300 ease-in-out h-20 w-28">
                <button
                  onClick={() => setInviteModal(true)}
                  className="cursor-pointer"
                >
                  Invite
                </button>
                <button onClick={() => setViewMembers(true)}>Members</button>
              </div>
            )}
          </div>
        </div>
        <div className="space-y-3 w-full max-w-full mt-10 pb-24 md:pb-0 overflow-y-auto overflow-hidden ">
          {messages?.map((message: any, i: any) => {
            return (
              <section
                key={i}
                className={`flex text-white px-3 py-2 w-full ${
                  message.user === slackUserProfile?.user?.id
                    ? "justify-end"
                    : "justify-start"
                }`}
              >
                <div className="flex flex-col space-y-3 w-full">
                  {message?.text && (
                    <div
                      className={`!max-w-full w-full whitespace-break-spaces break-words ${
                        message.user === slackUserProfile?.user?.id
                          ? ""
                          : "flex gap-2"
                      }`}
                    >
                      <img
                        src={message?.memberImage}
                        alt=""
                        className={`h-6 w-6 rounded-full ${
                          message.user === slackUserProfile?.user?.id
                            ? "hidden"
                            : ""
                        }`}
                      />
                      <div
                        className={`px-3 py-2 break-words whitespace-break-spaces w-[70%] ${
                          message.user === slackUserProfile?.user?.id
                            ? " rounded-t-2xl rounded-bl-2xl bg-primary text-white ml-auto"
                            : "rounded-t-2xl rounded-br-2xl bg-gray/5 text-black"
                        }`}
                      >
                        {linkPreviews.length > 0 &&
                          linkPreviews[i] &&
                          Object.keys(linkPreviews[i]).length > 0 && (
                            <a href={linkPreviews[i]?.url} target="__blank">
                              <div
                                className={`bg-white p-3 rounded-xl my-1 text-center`}
                              >
                                <div className="flex items-center gap-3 justify-center">
                                  {linkPreviews[i] && (
                                    <img
                                      src={linkPreviews[i]?.favicons[0]}
                                      alt=""
                                      className="max-w-[50px] max-h-[50px] object-contain"
                                    />
                                  )}
                                  <h1 className="font-medium">
                                    {linkPreviews[i]?.title}
                                  </h1>
                                </div>
                                <div className="h-[2px] w-full bg-gray/40 rounded-full my-2" />
                                <p>{linkPreviews[i]?.description}</p>
                              </div>
                            </a>
                          )}

                        <p>{message?.text}</p>
                        {/* <p>{getPreview(message?.text)}</p> */}
                        <p
                          className={`text-[12px] text-gray ${
                            message.user === slackUserProfile?.user?.id &&
                            "hidden"
                          }`}
                        >
                          ~ {message?.memberName}
                        </p>
                      </div>
                    </div>
                  )}
                  {message?.files?.length > 0 && (
                    <div className="space-y-6">
                      {message?.files?.map((file: any, i: any) => (
                        <aside className="flex gap-2">
                          <img
                            src={message?.memberImage}
                            alt=""
                            className={`h-6 w-6 rounded-full ${
                              message.user === slackUserProfile?.user?.id
                                ? "hidden"
                                : ""
                            }`}
                          />
                          <div
                            key={i}
                            className={`  px-3 py-2 whitespace-break-spaces break-words  w-fit !max-w-[70%]  ${
                              message.user === slackUserProfile?.user?.id
                                ? " rounded-t-2xl rounded-bl-2xl bg-primary text-white ml-auto"
                                : "rounded-t-2xl rounded-br-2xl bg-gray/5 text-black mr-auto"
                            }`}
                          >
                            <a href={file?.url_private}>{file?.url_private}</a>
                          </div>
                        </aside>
                      ))}
                    </div>
                  )}
                </div>
              </section>
            );
          })}
        </div>
      </section>
      <div className="bg-white w-full fixed left-0 right-0 mx-auto md:relative -bottom-5 md:bottom-0 py-5 pb-10 px-5 flex justify-center">
        <div className="w-full max-w-6xl">
          {openUpload ? (
            <main className="bg-black/40 inset-0 fixed flex justify-center items-center z-50">
              <div className="relative flex flex-col  justify-center items-center rounded-2xl bg-white w-[85%] h-[80%] max-w-[400px] max-h-[600px]">
                <div className=" flex justify-end m-3">
                  <MdClose
                    onClick={() => setOpenUpload(false)}
                    className="cursor-pointer gap-5 h-7 w-7 justify-end absolute right-2 top-2 text-white p-1 rounded-full bg-gray"
                  />
                </div>
                {file && (
                  <div>
                    <FaRegFileLines className="h-52 w-52 mb-2" />
                    <p className="mb-3">{file && file?.name}</p>
                    <div className="w-fit mx-auto mb-3">
                      <Button
                        loading={fileUploadLoading}
                        onClick={uploadFile}
                        className="bg-primary px-4 rounded-full !hover:text-white text-white"
                      >
                        upload
                      </Button>
                    </div>
                  </div>
                )}
                <div>
                  <div className="relative flex justify-center items-center px-5 lg:px-20 py-8 rounded-md border border-dashed border-gray-300 cursor-pointer bg-gray-200 text-bold w-fit mx-auto">
                    <input
                      type="file"
                      onChange={(e) => setFile(e.target.files?.[0])}
                      className="opacity-0 absolute inset-0"
                    />
                    <p className="">Upload Your File Here</p>
                  </div>
                  <p className="p-2 px-5 text-sm text-center ">
                    Upload Your File to contribute to the collective learning
                    experience. Let's enhance our study sessions together!
                  </p>
                </div>
              </div>
            </main>
          ) : null}
          <form
            action=""
            onSubmit={postMessage}
            className=" flex items-center border border-gray h-fit  focus:border-primary px-3 py-2 rounded-2xl w-full max-w-6xl mx-auto justify-between"
          >
            <textarea
              value={text}
              onChange={(e) => setText(e.target.value)}
              disabled={postLoading}
              className="disabled:text-gray max-h-[200px] min-h-fit resize-none border-none outline-0 ring-0 focus:border-0 focus:outline-none w-full"
            />
            <div className="flex items-center gap-2 text-gray">
              <button onClick={() => setOpenUpload(true)}>
                <IoMdCloudUpload className="h-6 w-6" />
              </button>

              <button type="submit">
                <IoIosSend className="h-6 w-6" />{" "}
              </button>
            </div>
          </form>
        </div>
      </div>
      <InviteSlackUser open={inviteModal} setOpen={setInviteModal} />
      <SlackGroupMembers
        open={viewMmebers}
        setOpen={setViewMembers}
        members={members}
      />
    </main>
  );
};

export default Conversation;
