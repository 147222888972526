import { useLocation, useSearchParams } from "react-router-dom";
import { notification } from "antd";
import { useMutation, useQuery } from "react-query";
import {
  deleteRequest,
  getRequest,
  otherRequest,
  postRequest,
  putRequest,
} from "../../context/requestTypes";
import { useContext } from "react";
import { AxiosContext } from "../../context/AxiosContext";
import { AxiosInstance } from "axios";
import { useNavigate } from "react-router";
import { checkOddValue, isEqual } from "../../context/utils";
import { analytics } from "../../firebaseAuth/config";
import { logEvent } from "firebase/analytics";

export function useGetAllRecap(params?: any) {
  const url = "/api_backend/recaps/";
  const axios = useContext(AxiosContext);
  return useQuery(
    ["get:all_recaps"],
    () => getRequest(axios as unknown as AxiosInstance, url, params),
    {
      refetchInterval: 30000,
      onError: (error: any) =>
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        }),
    }
  );
}

export function useGetPublicRecap(id: string) {
  const url = "https://nurovant-ml-server-h5ppzgcbxa-uc.a.run.app/reflection_result/";
  return useQuery(
    ["get:public_recaps"],
    () => (id ? otherRequest(url + id, "GET") : ({} as any)),
    {
      enabled: Boolean(id),
      refetchInterval: 90000,
      onSuccess: (res) => {
        if (isEqual(res?.reflectly_status, "COMPLETED"))
          notification.success({
            description: "Your material recap is ready to be viewed.",
            message: "Success!",
          });
      },
      onError: (error: any) => {
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        });
      },
    }
  );
}

export function useGetRecap(id: string) {
  const url = "/api_backend/recaps/";
  const navigate = useNavigate();
  const location = useLocation();
  const axios = useContext(AxiosContext);
  return useQuery(
    ["get:single_recaps", id],
    () => getRequest(axios as unknown as AxiosInstance, url + id),
    {
      onError: (error: any) => {
        if (error?.message?.includes("authenticated")) {
          sessionStorage.setItem("fallback", JSON.stringify(location));
          navigate("/auth");
        }
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        });
      },
      enabled: Boolean(id),
    }
  );
}

export function useFetchSingleRecap(id: string, enabled?: boolean) {
  const url = "/api_backend/recaps/";
  const navigate = useNavigate();
  const location = useLocation();

  const [params, setParams] = useSearchParams();
  const activeAction = params.get("action");
  const idParams = params.get("id");
  const axios = useContext(AxiosContext);
  return useQuery(
    ["get:single_lecture_recaps", id],
    () => getRequest(axios as unknown as AxiosInstance, url + id),
    {
      refetchInterval: 30000,
      enabled: Boolean(enabled && !checkOddValue(id)),
      onError: (error: any) => {
        if (error?.message?.includes("authenticated")) {
          sessionStorage.setItem("fallback", JSON.stringify(location));
          navigate("/auth");
        }
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        });
      },
    }
  );
}

export function usePostRecap(successAction?: any, errorAction?: any) {
  const url = "/api_backend/recaps/create";
  const axios = useContext(AxiosContext);
  return useMutation(
    async (payload: any) =>
      postRequest(axios as unknown as AxiosInstance, url, payload),
    {
      onSuccess: (response: any) => {
        successAction?.();
        logEvent(analytics, "create_recaps");

        notification.success({
          message: "Success!",
          description: response?.message || "action successful.",
        });
      },
      onError: (error: any) => {
        errorAction?.();
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        });
      },
    }
  );
}

export function usePostLectureRecap(successAction?: any, errorAction?: any) {
  const [params, setParams] = useSearchParams();
  const activeAction = params.get("action");

  const url = "/api_backend/recaps/create";
  const axios = useContext(AxiosContext);
  return useMutation(
    async (payload: any) =>
      postRequest(axios as unknown as AxiosInstance, url, payload),
    {
      onSuccess: (response: any) => {
        successAction?.(response);
        notification.success({
          message: "Success!",
          description: response?.message || "action successful.",
        });
        logEvent(analytics, "create_lecture_recaps");

        // setParams({ action: activeAction, id: response?.data?._id } as {
        //   action: string;
        //   id: string;
        // });
      },
      onError: (error: any) => {
        errorAction?.();
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        });
      },
    }
  );
}

export function usePostRecapAnswer(successAction?: any) {
  const url = "/api_backend/recaps/submit_recaps";
  const axios = useContext(AxiosContext);
  return useMutation(
    async (payload: any) =>
      postRequest(axios as unknown as AxiosInstance, url, payload),
    {
      onSuccess: (response: any) => {
        successAction?.();
        notification.success({
          message: "Success!",
          description: response?.message || "action successful.",
        });
      },
      onError: (error: any) =>
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        }),
    }
  );
}

export function useDeleteRecap(successAction?: any) {
  const url = "/api_backend/recaps/";
  const axios = useContext(AxiosContext);
  return useMutation(
    async (id: any) =>
      deleteRequest(axios as unknown as AxiosInstance, url + id),
    {
      onSuccess: (response: any) => {
        successAction?.();
        logEvent(analytics, "delete_recaps");
        notification.success({
          message: "Success!",
          description: response?.message || "action successful.",
        });
      },
      onError: (error: any) =>
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        }),
    }
  );
}

export function useEditRecap(id: string) {
  const url = `/api_backend/recaps/edit_recaps/${id}`;
  const axios = useContext(AxiosContext);

  return useMutation(
    async (payload: any) => {
      putRequest(axios as unknown as AxiosInstance, url, payload);
    },
    {
      onSuccess: (response: any) => {
        notification.success({
          message: "Success!",
          description: response?.message || "action successful.",
        });
        logEvent(analytics, "edit_recap");
      },
      onError: (error: any) => {
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        });
      },
    }
  );
}
