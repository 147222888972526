import { Button, Divider, Spin, message } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEditRecap, useGetRecap } from "../../../../hooks/recap/recap";
import { RiArrowGoBackFill } from "react-icons/ri";
import { useEffect, useMemo, useRef, useState } from "react";
import { FaChevronRight, FaClipboard } from "react-icons/fa";
import { isEqual } from "../../../../context/utils";
import Loading from "../../../../components/loading";
import { BiDownload } from "react-icons/bi";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { FeedbackModal } from "../../../../components/modals/FeedbackModal";
import {
  useDropDownContent,
  useGetYoutubeVideo,
} from "../../../../hooks/otherhooks";
import DropDownFeedComponent from "../../../../components/DropDownFeedComponent";
import { RateLecturerModal } from "../../../../components/modals/RateLecturerModal";
import ShareToUsersModal from "../../../../components/modals/ShareToUsersModal";
import SummaryModal from "../../../../components/modals/SummaryModal";
import WikipidiaSummaryOnboarding from "../../../../components/modals/WikipidiaSummaryOnboarding";
import { RecapPageComponent } from "../../../../components";

const RecapSection = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [activeTab, setActiveTab] = useState("mini_reflections");
  const pdfRef = useRef<HTMLDivElement>(null);
  const [isBounded, setIsBounded] = useState(false);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [openWiki, setOpenWiki] = useState(false);
  const [selected, setSelected] = useState<string>("");
  const contentId = params.get("content");
  const materialId = params.get("id");
  const id = contentId || materialId;
  const [showFeedBack, setShowFeedBack] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [showRateLecturer, setShowRateLecturer] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const { dropDownOptions } = useDropDownContent({
    setShowFeedBack,
    setShowDropDown,
    exportToPDF: true,
    exportToPDFFunc: () => toPdf(),
    canRateLecturer: true,
    setShowRateLecturer,
    shareToUser: () => shareToUser(),
    type: "recaps",
  });

  const goBack = () => navigate("/recap");

  const {
    data: getRecapData,
    isFetching: getRecapLoad,
    isSuccess: isGetRecapSuccess,
  } = useGetRecap(id!);
  const {
    data: youTubeData,
    isFetching: getYouTubeDataLoad,
    refetch: refetchYoutubeData,
  } = useGetYoutubeVideo(getRecapData?.data?.metadata[1]);
  const { mutate: editRecap } = useEditRecap(id!);

  const shareToUser = () => {
    setOpenShare(true);
  };
  const tabs = useMemo(
    () =>
      [
        {
          title: "Summary",
          extra: "bullet_points",
          key: "mini_reflections",
          label: "Mini Recaps",
          description:
            "Tailored for speedy understanding, it pinpoints essential details instantly.",
        },
        {
          title: "Overall",
          key: "reflections",
          label: "Overall Recaps",
          description: "Uncover a comprehensive understanding of your content.",
        },
        {
          title: "Transcription",
          label: "Transcription",
          key: "full_transcriptions",
          description: "Your original file converted to transcript.",
        },
      ].filter(({ key }) =>
        Object.keys(getRecapData?.data?.recaps || {})?.includes(key)
      ),
    [getRecapData]
  );

  const currentTab = useMemo(
    () => tabs.find(({ key }) => isEqual(key, activeTab)),
    [activeTab, tabs]
  );
  const handleCopy = () => {
    message.success("Copied to clipboard");
    // Mini Recaps: ${getRecapData?.data?.recaps?.mini_reflections?.toString()},
    navigator.clipboard.writeText(`
      Key Points: ${getRecapData?.data?.recaps?.bullet_points?.join(", ")}.
      Overall Recaps: ${getRecapData?.data?.recaps?.reflections}.
      ${
        getRecapData?.data?.recaps?.full_transcriptions
          ? `Transcription: ${getRecapData?.data?.recaps?.full_transcriptions}`
          : ""
      }
    `);
  };

  const toPdf = () => {
    const input = pdfRef.current;
    if (input) {
      (html2canvas as any)(input, { scale: 2 }).then((canvas: any) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "px",
          format: [canvas.width, canvas.height],
        });

        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        let contentHeight = pdfHeight;
        let position = 0;

        while (contentHeight > 0) {
          pdf.addImage(imgData, "PNG", 0, position, pdfWidth, pdfHeight);
          contentHeight -= pdf.internal.pageSize.getHeight();
          position -= pdf.internal.pageSize.getHeight();

          if (contentHeight > 0) {
            pdf.addPage();
          }
        }

        pdf.save(titles + ".pdf");
      });
    }
  };

  let selectionTimeout: string | number | NodeJS.Timeout | undefined;

  const handleTextSelection = () => {
    clearTimeout(selectionTimeout); // Clear the timeout if it's already set
    const selection = window.getSelection();
    const page = document.querySelector(`.page_canvas`);

    if (
      selection !== null &&
      selection.toString().length > 0 &&
      page?.contains(selection.anchorNode)
    ) {
      // Set a timeout to check if the user has finished selecting after 500ms
      selectionTimeout = setTimeout(() => {
        const selectedText = selection.toString();
        setSelected(selectedText);
        setShowSummaryModal(true);
      }, 500);
    }
  };

  useEffect(() => {
    setOpenWiki(true);
    // Add event listener for selectionchange event
    document.addEventListener("selectionchange", handleTextSelection);

    return () => {
      // Remove event listener when component unmounts
      document.removeEventListener("selectionchange", handleTextSelection);
    };
  }, []);

  // const showSelectedText = () => {
  //   const selectedText = window?.getSelection()?.toString();
  //   if (selectedText) {
  //     setSelected(selectedText);
  //     setShowSummaryModal(true);
  //   }
  // };
  // useEffect(() => {
  //   if (isBounded) {
  //     document?.addEventListener("mouseup", showSelectedText);
  //   }

  //   return () => {
  //     document.removeEventListener("mouseup", showSelectedText);
  //   };
  // }, [isBounded]);

  useEffect(() => {
    if (getRecapData?.data?.recaps?.["mini_reflections"])
      setActiveTab("mini_reflections");
    else if (getRecapData?.data?.recaps?.["reflections"])
      setActiveTab("reflections");
    else if (getRecapData?.data?.recaps?.["full_transcriptions"])
      setActiveTab("full_transcriptions");

    if (isGetRecapSuccess) {
      if (
        getRecapData?.data?.recaps?.youtube_videos === undefined ||
        getRecapData?.data?.recaps?.youtube_videos.length === 0
      ) {
        refetchYoutubeData().then((youTubeData) => {
          const video_id = youTubeData?.data?.items[0]?.id?.videoId;
          const title = youTubeData?.data?.items[0]?.snippet?.title;
          const thumbnail =
            youTubeData?.data?.items[0]?.snippet?.thumbnails?.high?.url;
          const date = youTubeData?.data?.items[0]?.snippet?.publishedAt;

          editRecap({
            yt_videos: [
              {
                video_id,
                title,
                thumbnail,
                date,
              },
            ],
          });
        });
      }
    }
  }, [getRecapData]);

  const titles = useMemo(() => {
    if (activeTab === "mini_reflections")
      return `${getRecapData?.data?.name} - Mini Recaps`;
    else if (activeTab === "reflections")
      return `${getRecapData?.data?.name} - Overal Recaps`;
    else if (activeTab === "full_transcriptions")
      return `${getRecapData?.data?.name} - Transcription`;
  }, [activeTab, getRecapData?.data?.name]);

  if (getRecapLoad) return <Loading />;
  if (!isEqual(getRecapData?.data?.status, "Success"))
    return (
      <div className="w-full h-screen flex justify-center items-center px-5 md:px-10 gap-5">
        <RiArrowGoBackFill
          className="cursor-pointer text-xl text-[#646462]"
          onClick={goBack}
        />
        <p className="text-[28px] font-bold text-dark">
          Recaps Data Not Ready Yet!
        </p>
      </div>
    );

  return (
    <Spin spinning={getRecapLoad || getYouTubeDataLoad}>
      <div className="w-full min-h-[95vh] flex flex-col items-center md:py- space-y-5 relative">
        <div className="absolute right-5 w-[24px]">
          <DropDownFeedComponent
            dropDownOptions={dropDownOptions}
            setShowDropDown={setShowDropDown}
            showDropDown={showDropDown}
          />
        </div>
        <RecapPageComponent
          data={getRecapData}
          load={getRecapLoad}
          youTubeData={youTubeData}
          showFeedBack={showFeedBack}
          setShowFeedBack={setShowFeedBack}
          openShare={openShare}
          setOpenShare={setOpenShare}
          pdfRef={pdfRef}
        />
        <aside
          ref={pdfRef}
          className="w-full max-w-[596px] absolute -top-[200vh] -left-full"
        >
          <div className="h-9 bg-[#4970fc]" />
          <div className="">
            <div className="flex gap-3 items-center pt-4 px-4">
              <img
                src="/icon.png"
                alt=""
                className="w-[60px] h-[60px] object-contain"
              />
              <p className="font-bold text-2xl">{titles}</p>
            </div>
            <Divider />
          </div>
          <div className="px-4 pb-6">
            {activeTab === "mini_reflections" &&
            typeof getRecapData?.data?.recaps?.[activeTab] === "object" ? (
              <div className="space-y-3">
                {Object.entries(
                  getRecapData?.data?.recaps?.[activeTab] || {}
                )?.map(([key, val]) => (
                  <div className="space-y-3">
                    <p className="text-base font-medium text-[#1B1B1B] leading-6 uppercase">
                      {key?.replaceAll("_", " ")}:
                    </p>
                    {Array.isArray(val) ? (
                      <ul className="!list-disc !pl-5">
                        {val?.map((d) => (
                          <li className="text-base font-normal text-[#1B1B1B] leading-6">
                            {d || "NIL"}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p className="text-base font-normal text-[#1B1B1B] leading-6">
                        {String(val) || "NIL"}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div className="space-y-3">
                <p className="text-base font-normal text-[#1B1B1B] text-center leading-6">
                  {getRecapData?.data?.recaps?.[activeTab]}
                </p>
                <div
                  className="space-y-3"
                  hidden={!currentTab?.extra || getRecapLoad}
                >
                  <p className="text-sm font-medium text-dark">Key Points</p>
                  <ul className="text-sm text-dark !list-disc px-5">
                    {getRecapData?.data?.recaps?.[
                      currentTab?.extra as any
                    ]?.map((d: any) => (
                      <li>{d}</li>
                    ))}
                  </ul>
                </div>
                <div className="sticky left-[97%] bottom-5 flex flex-col gap-3 w-fit">
                  <Button
                    className=" bg-primary"
                    icon={<FaClipboard />}
                    onClick={handleCopy}
                    shape="circle"
                    type="primary"
                  />
                </div>
              </div>
            )}
          </div>
        </aside>

        {/* <FeedbackModal
          showModal={showFeedBack}
          setShowModal={setShowFeedBack}
          featureId={id}
          feature="recaps"
        />

        <RateLecturerModal
          showModal={showRateLecturer}
          setShowModal={setShowRateLecturer}
          featureId={id}
          feature="recaps"
        />
        <ShareToUsersModal
          open={openShare}
          setOpenShare={setOpenShare}
          type="recaps"
        />
        <WikipidiaSummaryOnboarding
          openSuccess={openWiki}
          setOpenSuccess={setOpenWiki}
        />

        {showSummaryModal ? (
          <SummaryModal
            query={selected}
            openSuccess={showSummaryModal}
            setOpenSuccess={setShowSummaryModal}
          />
        ) : null} */}
      </div>
    </Spin>
  );
};

export default RecapSection;
