import React, { useState, FC } from "react";
import { useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { useGetSuggestions } from "../hooks/search/search";

type Props = {
  mobile?: boolean;
};

export const Search: FC<Props> = ({ mobile = false }) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");

  const { isLoading: getSuggestionsLoad, data: getSuggestionsData } =
    useGetSuggestions(searchText);

  return (
    <div
      className={`py-[10px] px-[14px] border border-[#d0d5dd] rounded-[40px] flex items-center justify-between ${
        mobile ? "w-[50%]" : "w-[274px]"
      } relative`}
    >
      <input
        type="text"
        value={searchText}
        placeholder="Looking for something?"
        className="text-[14px] leading-[24px] placeholder:text-[14px] placeholder:leading-[24px] w-full outline-none border-none focus:ring-0"
        onChange={(e: any) => {
          setSearchText(e.target.value);
        }}
      />
      <FaSearch
        size={14}
        className="cursor-pointer"
        onClick={() => {
          navigate(`/search/?action=quiz&id=${searchText}`);
        }}
      />

      <div className="w-full absolute top-[40px] left-0 right-0 bg-white max-h-[208px] z-30 overflow-y-auto shadow-md">
        {getSuggestionsData?.data?.suggestions?.map(
          (suggestions: string, index: number) => (
            <div
              className={`w-full p-2 cursor-pointer hover:bg-[#F9F9F9] ${
                index !== getSuggestionsData?.data?.suggestions.length - 1 &&
                "border-b border-b-[#E0E0E0]"
              }`}
              key={index}
              onClick={() => {
                setSearchText(suggestions);
              }}
            >
              <span className="text-[16px] leading-[24px] font-medium">
                {suggestions}
              </span>
            </div>
          )
        )}
      </div>
    </div>
  );
};
