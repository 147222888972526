import { Button, Image, Modal } from "antd";
import React, { useEffect, useState } from "react";
import {
  useEditFlashcards,
  useGetFlashcard,
  useShare,
} from "../../hooks/flashcards/flashcards";
import { useSearchParams } from "react-router-dom";
import { VscLoading } from "react-icons/vsc";
import feedbackImg from "../../assets/Modal.png";
import { useShareNew } from "../../hooks/otherhooks";

type Props = {
  open: boolean;
  setOpenShare: React.Dispatch<React.SetStateAction<boolean>>;
  type: string;
  extractedId?: string;
};

const ShareToUsersModal = ({
  open,
  setOpenShare,
  type,
  extractedId,
}: Props) => {
  const [params] = useSearchParams();
  const [nuroCode, setNuroCode] = useState("");
  // const [isSuccess, setIsSuccess] = useState(false);
  const action = params.get("action");
  const id = extractedId ? extractedId : params.get("id");

  const url = `/api_backend/${type}/share/${id}`;

  // const { mutate, isLoading, isSuccess } = useShare(id!, type);
  const {
    mutate: handleShare,
    isLoading: handleShareLoading,
    isSuccess,
  } = useShareNew(url);

  const handleSubmit = () => {
    const payload = {
      reflectly_code: nuroCode,
    };

    // mutate(payload);
    handleShare(payload);
  };

  return (
    <Modal
      open={open}
      onCancel={() => setOpenShare(false)}
      footer={false}
      styles={{
        content: {
          background: isSuccess ? "none" : "#fff",
          boxShadow: "none",
        },
      }}
      closeIcon={isSuccess ? false : true}
      className={isSuccess ? "w-fit h-fit !shadow-none" : "w-full lg:w-[640px]"}
    >
      {isSuccess === false ? (
        <section>
          <h1 className="text-[26px] font-semibold">Send to Nuro users</h1>
          <div className="w-full relative">
            <label
              htmlFor=""
              className="!z-20 ml-4 w-fit rounded-full border border-[#D1D1D1] bg-black px-[10px] py-[5px] text-[10px] font-semibold text-white absolute top-0"
            >
              Nuro user
            </label>
            <input
              type="text"
              value={nuroCode}
              onChange={(e) => setNuroCode(e.target.value)}
              placeholder="Enter Nuro code here"
              className="mt-4 w-full rounded-xl border border-[#D1D1D1] px-[27px] py-[14px] focus:border-primary focus:outline-primary"
            />
          </div>
          <div className="w-full">
            <Button
              className="!font-medium !text-[14px] !leading-[20px] bg-primary !text-white rounded-full mt-4 md:mt-5 !border-primary !h-[56px] w-full"
              onClick={handleSubmit}
              loading={handleShareLoading}
              danger
            >
              Share
            </Button>
          </div>
        </section>
      ) : (
        <div
          className=" cursor-pointer !w-full !H-full"
          onClick={() => setOpenShare(false)}
        >
          <Image
            alt="success"
            src={feedbackImg}
            preview={false}
            className="object-cover !w-full !h-full"
          />
        </div>
      )}
    </Modal>
  );
};

export default ShareToUsersModal;
