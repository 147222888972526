import React, { useState, useEffect, useRef, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import authAtom from "../../../atoms/auth/auth.atom";
import lectureAtom from "../../../atoms/lecture/lecture.atom";
import { FaCloudUploadAlt, FaLink, FaSearch, FaShare } from "react-icons/fa";
import { CustomButton } from "../../../components";
import {
  useDeleteLecture,
  useGetLecture,
  useGetLectures,
  usePostLecture,
} from "../../../hooks/lecture/lecture";
import BookIcon from "../../../assets/Book.png";
import {
  Avatar,
  Button,
  Form,
  Image,
  Input,
  List,
  Modal,
  Spin,
  Tooltip,
  Upload,
  UploadProps,
  notification,
} from "antd";
import CustomTable from "../../../components/CustomTable";
import { ColumnsType } from "antd/es/table";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import { BiTestTube } from "react-icons/bi";
import { TbCards } from "react-icons/tb";
import { PiRepeatFill } from "react-icons/pi";
import { AiOutlineClose, AiOutlineMessage } from "react-icons/ai";
import { LuAlarmClock, LuDownloadCloud, LuInbox } from "react-icons/lu";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import dotIcon from "../../../assets/icons/dot.png";
import { BsSoundwave } from "react-icons/bs";
import AWS from "aws-sdk";
import { ImSpinner } from "react-icons/im";
import { MdOutlineClose } from "react-icons/md";
import successImg from "../../../assets/images/success-image.png";
import LectureDetailsSection from "./sections/lectureDetailsSection";
import {
  getBase64,
  getBinary,
  isEqual,
  useHandleRecorder,
} from "../../../context/utils";
import { GoTrash } from "react-icons/go";
import { ReactMic } from "react-mic";
import createLecturesAtom from "../../../atoms/modal/createLectures.atom";
import { LoaderComponent } from "../../../components/LoaderComponent";
import { io } from "socket.io-client";
import { usePostCourseLecture } from "../../../hooks/course/course";
import ShareToUsersModal from "../../../components/modals/ShareToUsersModal";
import { useGetSuggestions } from "../../../hooks/search/search";
import { Search } from "../../../components/Search";
import Joyride, { STATUS, Step, ACTIONS } from "react-joyride";
import onboardingAtom from "../../../atoms/onboarding/onboarding.atom";

type StepStateProps = {
  run: boolean;
  steps: Step[];
};

export function removeSpacesFromPdfName(pdfName: string) {
  const trimmedName = pdfName.trim();
  return trimmedName.replace(/[^a-zA-Z0-9.]/g, "");
}

export const useStopHandle = () => {
  const setLectureState = useSetRecoilState(createLecturesAtom);

  return () => {
    SpeechRecognition.stopListening();
    setLectureState((prev: any) => ({ ...prev, isOpen: true }));
    setLectureState((prev: any) => ({ ...prev, isRecording: false }));
    setLectureState((prev: any) => ({ ...prev, elapsedTime: 0 }));
  };
};

// const socket: any = {};
// const socket = io(`${process.env.REACT_APP_ML_API_URL}/stream_audio`);
export const formatTime = (timeInSeconds: number): string => {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds % 60;
  return `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
};

type Props = {
  noContent?: boolean;
};
function Home({ noContent = false }: Props) {
  const navigate = useNavigate();

  const { user } = useRecoilValue(authAtom);
  const { isOpen, isRecording, elapsedTime, isUploading } =
    useRecoilValue(createLecturesAtom);
  const setLectureState = useSetRecoilState(createLecturesAtom);
  const setLecture = useSetRecoilState(lectureAtom);
  const { onboardingStatus } = useRecoilValue(onboardingAtom);
  const setOnboardingState = useSetRecoilState(onboardingAtom);
  const [active, setActive] = useState<string>("Record");
  const [createName, setCreateName] = useState<boolean>(false);
  const [openShare, setOpenShare] = useState(false);
  const [extractedId, setExtractedId] = useState("");
  const [lectureName, setLectureName] = useState<string>("");
  const [awsData, setAwsData] = useState<any>({});
  const [link, setLink] = useState<string>("");

  const [param, setParam] = useSearchParams();
  // const [isOpen, setIsOpen] = useState(false);
  const [isGenerate, setIsGenerate] = useState(
    isEqual(param.get("generate"), "true")
  );
  const [isNameOpen, setIsNameOpen] = useState(false);

  const isParamOpen = isEqual(param.get("create"), "true");
  const activeAction = param.get("action");
  const lectureId = param.get("lecture");
  const contentId = param.get("content");
  const courseId = param.get("course");

  const [upldFile, setUpldFile] = useState<any>({});
  const [audioBlob, setAudioBlob] = useState<Blob | null | undefined | Body>(
    null
  );

  const [timer, setTimer] = useState(false);

  const [isLoadOpen, setIsLoadOpen] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const onSuccessClose = () => {
    setIsSuccessOpen(false);
    setCreateName(false);
  };
  const onSuccessOpen = () => setIsSuccessOpen(true);
  const onLoadOpen = () =>
    setLectureState((prev: any) => ({ ...prev, isUploading: true }));

  const onLoadClose = () =>
    setLectureState((prev: any) => ({ ...prev, isUploading: false }));

  const onGenClose = () => setIsGenerate(false);
  const onGenOpen = () => setIsGenerate(true);
  const onOpen = () =>
    setLectureState((prev: any) => ({ ...prev, isOpen: true }));
  const onClose = () => {
    setLectureState((prev: any) => ({ ...prev, isOpen: false }));
    setCreateName(false);
  };
  const onNameClose = () => setIsNameOpen(false);
  const onNameOpen = () => setIsNameOpen(true);
  const handleUpldFileClr = () => setUpldFile({});

  const handleAction = (action: string, id: string) => {
    setParam({ action, id });
    onGenOpen();
  };

  const {
    data: getLectData,
    refetch: getLectFetch,
    isFetching: getLectLoad,
  } = useGetLectures();

  useGetLecture(lectureId!, (lecture: any) => setLecture({ lecture }));

  const handleSuccess = () => {
    onClose();
    onLoadClose();
    getLectFetch();
    onSuccessOpen();
    handleUpldFileClr();
    if (courseId) navigate(`/course?id=${courseId}`);
  };

  const { mutate: postCourseLectAction, isLoading: postCourseLectLoad } =
    usePostCourseLecture(courseId!, handleSuccess);

  const successAction = (res?: any) => {
    if (courseId) return postCourseLectAction(res?.data?._id);
    handleSuccess();
    // getAllQuizFetch();
    // getAllFlashcardFetch();
    // setModal({
    //   modalType: "Success",
    //   showModal: true,
    //   message: "Lecture generated successfully",
    //   action: "View",
    // });
  };

  const { mutate: postLectAction, isLoading: postLectLoad } =
    usePostLecture(successAction);

  const { mutate: deleteLectAction, isLoading: deleteLectLoad } =
    useDeleteLecture(getLectFetch);

  useMemo(() => {
    if (lectureId)
      setLecture({
        lecture: getLectData?.lectures?.find((d: any) =>
          isEqual(d?._id, lectureId)
        ),
      });
  }, [getLectData, lectureId]);
  // useMemo(() => {if(isParamOpen) onOpen()}, [isParamOpen])

  const handleView = (id: string) => setParam({ id });
  const id = param.get("id");

  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    directory: false,
    method: undefined,
    className: "!w-full",
    showUploadList: false,
    onChange({ file }: { file: Blob | any }) {
      setUpldFile({
        file: file?.originFileObj,
        fileobj: file,
      });

      if (file?.originFileObj) {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          // Create a Blob from the loaded data
          if (e.target?.result instanceof ArrayBuffer) {
            const audioBlob = new Blob([e.target.result], { type: file.type });
            setAudioBlob(audioBlob);
          }

          // Do something with the Blob, such as sending it to a server or processing it
          // console.log(audioBlob);
        };

        // Read the content of the file as a data URL
        reader.readAsArrayBuffer(file?.originFileObj);
      }
    },
  };

  const [selectedOption, setSelectedOption] = useState("");
  const handleSelectChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  const options = [];
  for (let i = 1; i <= 60; i++) {
    options.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  //  TODO:  Live recording section
  // const [isRecording, setIsRecording] = useState<boolean>(false);
  // const [elapsedTime, setElapsedTime] = useState<number>(0);
  const [totalElapsedTime, setTotalElapsedTime] = useState<number>(0);
  const [isPaused, setIsPaused] = useState(false);

  const { transcript, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();

  const chatBox = document.getElementById("transcription-box");
  setTimeout(
    () => chatBox?.scroll({ top: chatBox?.scrollHeight, behavior: "smooth" }),
    500
  );

  // socket transcription implementation >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  // socket?.on("audio_text", (res: any) => console.log(res));
  const [manLecture, setManLecture] = useState("");

  const handleTranscription = async (data: any) => {
    const textEncoder = new TextEncoder();
    const payload = await getBase64(data).then((res) => Promise.resolve(res));
    const paydata = await getBinary(data).then((res) => Promise.resolve(res));
    const uint8Array = textEncoder.encode(paydata as unknown as string);
    const paysplit = (payload as any)?.split(",")?.[1];
    // setManLecture((manLecture || payload) as any)
    // console.log(unescape(encodeURIComponent(paysplit as any)))
    // console.log(decodeURIComponent(escape(paysplit as any)))
    // console.log(payload, typeof payload)
    // console.log(atob(paydata as unknown as string))
    // console.log(uint8Array);
    // socket?.emit(
    //   "audio_stream",
    //   {
    //     audio_binary: paydata,
    //     // audio_base64: payload,
    //     audio_sample_rate: 16000,
    //   },
    //   (res: any) => console.log(res)
    // );
  };

  const { startRecord, stopRecord } = useHandleRecorder({
    onData: handleTranscription,
  });

  // const [isRunning, setIsRunning] = React.useState(false);
  // const [elapsedTime, setElapsedTime] = React.useState(0);
  // const [voice, setVoice] = React.useState(false);
  const [recordBlobLink, setRecordBlobLink] = React.useState<null | boolean>(
    null
  );

  const handleUploadAndLoader = (uploadParams: any) => {
    setLectureState((prev: any) => ({
      ...prev,
      progressBar: 0,
    }));
    const s3 = new AWS.S3();

    return new Promise((resolve, reject) => {
      const upload = s3.upload(uploadParams);

      // Track progress of the chunk upload
      upload.on("httpUploadProgress", (progress) => {
        const uploadedBytes = progress.loaded;
        const totalBytes = progress.total;
        const percentProgress = Math.round((uploadedBytes / totalBytes) * 100);

        // console.log(`Uploaded ${percentProgress}%`);
        setLectureState((prev: any) => ({
          ...prev,
          progressBar: percentProgress,
        }));
      });

      upload.send((err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
          setAwsData(data);
          onLoadClose();
          setCreateName(true);
        }
      });
    });
  };

  const onStop = async (recordedBlob: any) => {
    onLoadOpen();
    // console.log("recordedBlob is: ", recordedBlob);
    setRecordBlobLink(recordedBlob.blobURL);
    // Convert blob URL to blob data
    const blob = await fetch(recordedBlob.blobURL).then((res) => res.blob());
    // console.log("blob", blob);

    AWS.config.update({
      accessKeyId: process.env["REACT_APP_AWS_ACCESS_KEY_ID"],
      secretAccessKey: process.env["REACT_APP_AWS_SECRET_ACCESS_KEY"],
      region: process.env["REACT_APP_AWS_REGION"],
    });

    // Specify the bucket and key (object key) for the upload
    const uploadParams = {
      Bucket: process.env["REACT_APP_S3_BUCKET"]!,
      Key: `${new Date()
        .toLocaleTimeString([], { hour12: false })
        .split(":")
        .join("_")}--recording${blob.size}-${user?.info?._id}.wav`, // You can customize the key based on your requirement
      Body: blob,
      ContentType: blob.type,
    };

    handleUploadAndLoader(uploadParams);

    // // Upload the file
    // s3.upload(
    //   uploadParams,
    //   (err: Error | null, data: AWS.S3.ManagedUpload.SendData | undefined) => {
    //     if (err) {
    //       console.error("Error uploading file", err);
    //       onLoadClose();
    //     } else {
    //       console.log("File uploaded successfully", data);
    //       setAwsData(data);
    //       setLectureState((prev: any) => ({ ...prev, isUploading: false }));
    //       setCreateName(true);

    //       // Handle success, update UI, etc.
    //     }
    //   }
    // );
    // setIsRecording(false);
    setLectureState((prev: any) => ({ ...prev, isRecording: false }));
  };

  const startHandle = () => {
    SpeechRecognition.startListening({ continuous: true });
    // setIsRecording(true);
    setLectureState((prev: any) => ({ ...prev, isRecording: true }));
    // setElapsedTime(0);
    setLectureState((prev: any) => ({ ...prev, elapsedTime: 0 }));
    // setVoice(true);
    startRecord();
  };

  const stopHandle = useStopHandle();

  // const clearHandle = () => {
  //   setIsRunning(false);
  //   setIsRecording(false);
  //   // setVoice(false);
  //   setRecordBlobLink(false);
  //   setElapsedTime(0);
  // };

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined = undefined;

    if (isRecording && !isPaused) {
      timer = setInterval(() => {
        // setElapsedTime((prevTime) => prevTime + 1);
        setLectureState((prev: any) => ({
          ...prev,
          elapsedTime: prev.elapsedTime + 1,
        }));
        // setTotalElapsedTime((prevTotalTime) => prevTotalTime + 1);
      }, 1000);
    }

    if (isPaused && timer) {
      clearInterval(timer);
    }

    if (selectedOption.length && elapsedTime === Number(selectedOption) * 60) {
      // handleStopRecording();
      stopHandle();
    }

    return () => clearInterval(timer);
  }, [isRecording, isPaused, selectedOption, elapsedTime]);

  const handleUpload = () => {
    // console.log(upldFile);
    if (!upldFile?.fileobj?.name || !upldFile?.fileobj?.name?.length) {
      console.error("No file selected");
      notification.error({
        message: "Error",
        description: "No file selected",
      });
      return;
    }
    onLoadOpen();

    // Configure AWS with your credentials
    AWS.config.update({
      accessKeyId: process.env["REACT_APP_AWS_ACCESS_KEY_ID"],
      secretAccessKey: process.env["REACT_APP_AWS_SECRET_ACCESS_KEY"],
      region: process.env["REACT_APP_AWS_REGION"],
    });

    // Create an S3 service object
    const s3 = new AWS.S3();
    // var audioBlob: Blob | null | undefined = null || undefined;

    // if (upldFile.file) {
    //   const reader = new FileReader();

    //   reader.onload = (e: any) => {
    //     var blob = new Blob([e?.target?.result], {
    //       type: upldFile.file.type,
    //     });
    //     audioBlob = blob;
    //     // setBlob(blob);
    //   };

    //   reader.readAsArrayBuffer(upldFile.file);
    // }

    // console.log("pdfNameForS3", removeSpacesFromPdfName(upldFile.file.name));

    // Specify the bucket and key (object key) for the upload
    const uploadParams = {
      Bucket: process.env["REACT_APP_S3_BUCKET"]!,
      Key: `${new Date()
        .toLocaleTimeString([], { hour12: false })
        .split(":")
        .join("_")}-${user?.info?._id}--${removeSpacesFromPdfName(
        upldFile.file.name
      )}`, // You can customize the key based on your requirement
      Body: audioBlob as Body,
      ContentType: upldFile.file.type,
    };

    handleUploadAndLoader(uploadParams);
    // Upload the file
    // s3.upload(
    //   uploadParams,
    //   (err: Error | null, data: AWS.S3.ManagedUpload.SendData | undefined) => {
    //     if (err) {
    //       console.error("Error uploading file", err);
    //     } else {
    //       console.log("File uploaded successfully", data);
    //       setAwsData(data);
    //       onLoadClose();
    //       setCreateName(true);

    //       // Handle success, update UI, etc.
    //     }
    //   }
    // );
  };

  const handleIdExtract = (id: string) => {
    setExtractedId(id);
    setOpenShare(true);
  };

  const handleLectureCreation = () => {
    if (active === "Record") {
      postLectAction({
        file_url: awsData?.Location,
        file_type: "audio",
        file_name: `${user?.info?._id}-record-${moment().format("DD-MM-YYYY")}`,
        lecture_name: lectureName.length ? lectureName : awsData?.Key,
        upload_type: "record",
      });
    } else if (active === "Upload") {
      if (
        upldFile?.file?.type === "application/pdf" ||
        upldFile?.file?.type === "text/plain" ||
        upldFile?.file?.type ===
          "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
        upldFile?.file?.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        postLectAction({
          file_url: awsData?.Location,
          file_type: "doc",
          file_name: `${new Date()
            .toLocaleTimeString([], { hour12: false })
            .split(":")
            .join("_")}--${removeSpacesFromPdfName(upldFile.file.name)}`,
          lecture_name: lectureName.length ? lectureName : awsData?.Key,
          upload_type: "upload",
        });
      } else {
        postLectAction({
          file_url: awsData?.Location,
          file_type: "audio",
          file_name: `${user?.info?._id}-uploadAudio-${moment().format(
            "DD-MM-YYYY"
          )}`,
          lecture_name: lectureName.length ? lectureName : awsData?.Key,
          upload_type: "upload",
        });
      }
    } else {
      postLectAction({
        file_url: link,
        file_type: "yt_url",
        file_name: `${user?.info?._id}-link-${moment().format("DD-MM-YYYY")}`,
        lecture_name: lectureName,
        upload_type: "yt_url",
      });
    }
  };

  const handleDeleteModal = (lecture_id: string) => {
    Modal.confirm({
      // title: "Delete Lecture",
      footer: false,
      title: false,
      icon: <></>,
      content: (
        <div className="w-full pl-7 flex flex-col justify-between items-center gap-5 text-center">
          <p className="text-3xl font-bold text-dark">
            Are you sure you want to delete this lecture?
          </p>
          <p className="text-[14px] leading-[24px] font-medium text-[#646462]">
            Deleting this lecture would permanently remove it from your account.
          </p>
          <div className="w-full items-center justify-center !gap-3">
            <Button
              onClick={Modal.destroyAll}
              type="text"
              className="!border !rounded-md !border-[#C1C1C0] !text-[#C1C1C0] !font-medium !text-[14px] !leading-[20px] !mr-3"
            >
              No, keep
            </Button>
            <Button
              className="!font-medium !text-[14px] !leading-[20px]"
              onClick={() => {
                deleteLectAction(lecture_id);
                setLectureDeleteId(lecture_id);
                Modal.destroyAll();
              }}
              loading={deleteLectLoad}
              danger
            >
              Yes, Delete
            </Button>
          </div>
        </div>
      ),
    });
  };

  const [lectureDeleteId, setLectureDeleteId] = useState("");

  const [{ run, steps }, setState] = useState<StepStateProps>({
    run: true,
    steps: [
      {
        content: <h2>Let's begin our journey!</h2>,
        locale: { skip: <strong>SKIP</strong> },
        placement: "center",
        target: "body",
      },
      {
        content: (
          <span className="text-[14px] leading-[20px] font-normal">
            Share your expertise and teach others through custom lectures
            tailored to your audience.
          </span>
        ),
        placement: "bottom",
        target: "#step-1",
        title: (
          <h2 className="text-[16px] leading-[24px] font-medium ml-2">
            Create New Lecture
          </h2>
        ),
      },
      {
        content: (
          <span className="text-[14px] leading-[20px] font-normal">
            Embark on structured learning journeys curated by language experts
            to master new skills.
          </span>
        ),
        placement: "bottom",
        target: "#step-2",
        title: (
          <h2 className="text-[16px] leading-[24px] font-medium ml-2">
            Enroll in Interactive Courses
          </h2>
        ),
      },
      {
        content: (
          <span className="text-[14px] leading-[20px] font-normal">
            Memorize key words and phrases efficiently with customizable
            flashcards designed for effective learning.
          </span>
        ),
        placement: "bottom",
        target: "#step-3",
        title: (
          <h2 className="text-[16px] leading-[24px] font-medium ml-2">
            Master Vocabulary
          </h2>
        ),
      },
      {
        content: (
          <span className="text-[14px] leading-[20px] font-normal">
            Challenge yourself with fun quizzes covering grammar, idioms, and
            cultural insights to gauge your proficiency.
          </span>
        ),
        placement: "bottom",
        target: "#step-4",
        title: (
          <h2 className="text-[16px] leading-[24px] font-medium ml-2">
            Test Your Knowledge
          </h2>
        ),
      },
      {
        content: (
          <span className="text-[14px] leading-[20px] font-normal">
            Recap previous lessons and solidify your understanding with
            interactive summaries of key concepts.
          </span>
        ),
        placement: "bottom",
        target: "#step-5",
        title: (
          <h2 className="text-[16px] leading-[24px] font-medium ml-2">
            Review and Consolidate Learning
          </h2>
        ),
      },
      {
        content: (
          <span className="text-[14px] leading-[20px] font-normal">
            Connect with fellow learners, ask questions, and share insights in
            our vibrant community forums.
          </span>
        ),
        placement: "bottom",
        target: "#step-6",
        title: (
          <h2 className="text-[16px] leading-[24px] font-medium ml-2">
            Join Community
          </h2>
        ),
      },
      {
        content: (
          <span className="text-[14px] leading-[20px] font-normal">
            Explore curated resources and study tips to enhance your language
            learning journey.
          </span>
        ),
        placement: "bottom",
        target: "#step-7",
        title: (
          <h2 className="text-[16px] leading-[24px] font-medium ml-2">
            Access Comprehensive Study Guides
          </h2>
        ),
      },
      {
        content: (
          <span className="text-[14px] leading-[20px] font-normal">
            Immerse yourself in real-life conversations for authentic language
            practice.
          </span>
        ),
        placement: "bottom",
        target: "#step-8",
        title: (
          <h2 className="text-[16px] leading-[24px] font-medium ml-2">
            Practice Speaking
          </h2>
        ),
      },
      {
        content: (
          <span className="text-[14px] leading-[20px] font-normal">
            Track your progress, earn rewards, and compete with others as you
            advance in your language learning goals.
          </span>
        ),
        placement: "bottom",
        target: "#step-9",
        title: (
          <h2 className="text-[16px] leading-[24px] font-medium ml-2">
            Climb the Leaderboard
          </h2>
        ),
      },
      {
        content: (
          <span className="text-[14px] leading-[20px] font-normal">
            Create Study sessions with your friends, and learn together in a
            collaborative environment.
          </span>
        ),
        placement: "bottom",
        target: "#step-10",
        title: (
          <h2 className="text-[16px] leading-[24px] font-medium ml-2">
            Study with your peers
          </h2>
        ),
      },
      {
        content: (
          <span className="text-[14px] leading-[20px] font-normal">
            Translate text, audio, and conversations to practice new languages
            in real-world scenarios.
          </span>
        ),
        placement: "bottom",
        target: "#step-11",
        title: (
          <h2 className="text-[16px] leading-[24px] font-medium ml-2">
            Practice new languages with Translate
          </h2>
        ),
      },
    ],
  });

  const column: ColumnsType<any> = [
    {
      title: "Name",
      // dataIndex: "lecture_title",
      render: (d) => (
        <p
          className="text-primary font-medium cursor-pointer"
          onClick={() => {
            // const lecture = getLectData?.lectures?.find((g: any) =>
            //   isEqual(g?.lecture_title, d)
            // );
            setLecture({ lecture: d });
            handleAction("quiz", d?.quiz);
          }}
        >
          {d?.lecture_title || "NIL"}
        </p>
      ),
    },
    {
      title: "Date uploaded",
      dataIndex: "lecture_date",
      render: (d) => <p>{moment(d).format("L")}</p>,
    },
    {
      title: "Content Type",
      dataIndex: "contentType",
      render: (d) => <p>{d || "NIL"}</p>,
    },
    {
      title: "Actions",
      dataIndex: "",
      render: (d) => (
        <div className="flex items-center gap-3">
          <Tooltip title={d?.quiz && "Quiz already generated!"}>
            <Button
              onClick={() => {
                setLecture({ lecture: d });
                handleAction("quiz", d?.quiz);
              }}
              className="text-primary flex items-center"
              disabled={d?.quiz}
              type="text"
              icon={<BiTestTube />}
            >
              Quiz
            </Button>
          </Tooltip>

          <Tooltip title={d?.flashcards && "Flashcards already generated!"}>
            <Button
              onClick={() => {
                setLecture({ lecture: d });
                handleAction("flashcard", d?.flashcards);
              }}
              className="text-primary flex items-center"
              disabled={d?.flashcards}
              type="text"
              icon={<TbCards />}
            >
              Flashcards
            </Button>
          </Tooltip>

          <Tooltip title={d?.recaps && "Recaps already generated!"}>
            <Button
              onClick={() => {
                setLecture({ lecture: d });
                handleAction("recap", d?.recaps);
              }}
              className="text-primary flex items-center"
              disabled={d?.recaps}
              type="text"
              icon={<PiRepeatFill />}
            >
              Recaps
            </Button>
          </Tooltip>

          <Tooltip title={d?.discussions && "Discussion already generated!"}>
            <Button
              onClick={() => {
                setLecture({ lecture: d });
                handleAction("discussions", d?.discussions);
              }}
              className="text-primary flex items-center"
              disabled={d?.discussions}
              type="text"
              icon={<AiOutlineMessage />}
            >
              Discuss
            </Button>
          </Tooltip>
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "",
      render: (d) => (
        <Button
          onClick={() => handleIdExtract(d?._id)}
          icon={<FaShare className="text-lg" />}
          // loading={deleteLectLoad}
          type="text"
        />
      ),
    },
    {
      title: "",
      dataIndex: "",
      render: (d) => (
        <Button
          onClick={() => {
            handleDeleteModal(d?._id);
          }}
          icon={<GoTrash className="text-lg" />}
          loading={lectureDeleteId === d?._id ? deleteLectLoad : false}
          type="text"
        />
      ),
    },
  ];

  const actionLoad = postLectLoad || postCourseLectLoad;

  if (noContent ? contentId : id || activeAction)
    return <LectureDetailsSection />;
  return (
    <Spin spinning={getLectLoad}>
      {isUploading === false ? (
        <div className="!w-full py-4 lg:py-[32px] h-full">
          <Joyride
            continuous
            callback={({ status, action }) => {
              if (
                action === ACTIONS.CLOSE ||
                (status === STATUS.SKIPPED && onboardingStatus) ||
                status === STATUS.FINISHED
              ) {
                setOnboardingState((prev: any) => ({
                  ...prev,
                  onboardingStatus: false,
                }));
              }
            }}
            run={onboardingStatus}
            steps={steps}
            hideCloseButton
            scrollToFirstStep
            showSkipButton
            showProgress
            styles={{
              buttonNext: {
                color: "#fff",
                backgroundColor: "#4970fc",
              },
              buttonBack: {
                color: "#4970fc",
              },

              tooltipContainer: {
                textAlign: "left",
              },
              options: {},
            }}
          />
          <div hidden={noContent}>
            <div className="w-full px-4 lg:px-[32px] items-start justify-between hidden md:flex static top-0 right-0">
              <div className="flex flex-col items-start gap-1">
                <h1 className="text-[#101828] text-[30px] leading-[38px] font-bold">
                  Hello {user?.info?.name}
                </h1>
                <p className="text-base text-[#667085]">
                  Here are your lectures
                </p>
              </div>
              <div className="flex items-center gap-4">
                <Search />

                <CustomButton
                  text="Create a lecture"
                  onClick={() => {
                    onOpen();
                  }}
                  width="md:w-[169px] w-[120px]"
                  height="h-[44px]"
                  fontSize="text-[14px] leading-[20px] md:text-[16px]  whitespace-nowrap"
                  fontWeight="font-medium"
                />
              </div>
            </div>
          </div>

          <div hidden={noContent}>
            <div
              className={`flex w-full items-center gap-4 px-4 lg:px-[32px] ${
                !getLectData?.lectures?.length ? "hidden" : "md:hidden"
              }`}
            >
              <Search mobile />

              <CustomButton
                text="Create a lecture"
                onClick={() => {
                  onOpen();
                }}
                width="md:w-[169px] w-[140px]"
                height="h-[44px]"
                fontSize="text-[14px] leading-[20px] md:text-[16px]  whitespace-nowrap"
                fontWeight="font-medium"
              />
            </div>
          </div>

          <div
            className={`h-full w-full flex items-center flex-col ${
              !getLectData?.lectures?.length ? "justify-center" : "mt-4"
            }`}
          >
            <div
              hidden={noContent || !getLectData?.lectures?.length}
              className="w-full space-y-5"
            >
              <CustomTable
                data={getLectData?.lectures}
                column={column}
                key={getLectData?.lectures}
                pagination={false}
              />
            </div>

            <div
              hidden={Boolean(noContent || getLectData?.lectures?.length)}
              className=""
            >
              <div className="w-full h-full flex flex-col justify-center items-center">
                <img src={BookIcon} alt="book" />
                <p className="text-[28px] leading-[40px] tracking-[-0.52px] font-bold text-secondary">
                  Lectures
                </p>
                <span className="text-[16px] leading-[20px] text-[#646462] mb-4">
                  No lecture created yet
                </span>
                <Button
                  onClick={onOpen}
                  className="bg-primary !px-20 !rounded-[24px] font-bold"
                  type="primary"
                  size="large"
                >
                  Create a lecture
                </Button>
              </div>
            </div>

            {/* upload document modal >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
            <Modal
              styles={{
                content: {
                  background: "none",
                  padding: 0,
                },
              }}
              onCancel={onClose}
              closeIcon={
                <AiOutlineClose className="text-white text-5xl mx-auto" />
              }
              footer={false}
              open={isOpen}
            >
              <div className="w-full h-full">
                <div className="w-full h-full flex flex-col justify-center gap-5 items-center rounded-t-2xl bg-primary text-white p-5 md:px-10">
                  <p
                    className="text-[26px] font-extrabold"
                    // onClick={() => setCreateName(!createName)}
                  >
                    Create Lecture
                  </p>
                  {createName === true && (
                    <div className="w-full flex flex-col items-start gap-2">
                      <label className="text-[22px] font-semibold text-white">
                        Title
                      </label>
                      <div className="!relative w-full">
                        <Form.Item name="email" className="!relative w-full">
                          <Input
                            className="w-full px-5 py-3 !text-white rounded-xl !bg-[#6788FC] focus:bg-[#6788FC] hover:bg-[#6788FC] !border-none placeholder:!text-[rgba(255,255,255,0.5)]"
                            placeholder="Name here"
                            onChange={({ target: { value } }) =>
                              setLectureName(value)
                            }
                            size="large"
                            type="email"
                            id="email"
                            required
                          />
                        </Form.Item>
                      </div>
                    </div>
                  )}
                  {createName === false && (
                    <div className="w-full p-2 rounded-[24px] bg-[#dbe2fe] flex flex-row gap-2">
                      {["Record", "Upload", "Link"].map((item, index) => (
                        <div
                          key={index}
                          className={`py-2 flex-1 flex items-center justify-center rounded-[20px] cursor-pointer text-[14px] leading-[18px] ${
                            item === active
                              ? "bg-white text-[#1b1b1b] font-semibold"
                              : "text-[#7b7e8c]"
                          }`}
                          onClick={() => {
                            setActive(item);
                          }}
                        >
                          <div className="flex items-center justify-center mr-2">
                            {item === "Record" ? (
                              <BsSoundwave
                                size={20}
                                color={active === item ? "#ff0000" : "#7b7e8c"}
                              />
                            ) : item === "Upload" ? (
                              <FaCloudUploadAlt
                                size={20}
                                color={active === item ? "#4970fc" : "#7b7e8c"}
                              />
                            ) : item === "Link" ? (
                              <FaLink
                                color={active === item ? "#64dfdf" : "#7b7e8c"}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          {item}
                        </div>
                      ))}
                    </div>
                  )}
                  {createName === false && (
                    <div className="w-full flex flex-col justify-center gap-5 items-center">
                      {active === "Record" && (
                        <div className="w-full">
                          <Input.TextArea
                            readOnly
                            value={transcript}
                            id="transcription-box"
                            hidden={!browserSupportsSpeechRecognition}
                            className="w-full h-[164px] bg-[#6788fc] p-6 rounded-[12px] text-white text-[16px] leading-[27px] placeholder:text-[#a4b7fd]"
                            placeholder="Transcriptions will go here"
                          />
                        </div>
                      )}
                      {active === "Upload" && (
                        <div className="w-full flex flex-col gap-5">
                          <Upload.Dragger {...uploadProps}>
                            <p className="ant-upload-drag-icon">
                              <LuInbox className="text-white text-5xl mx-auto" />
                            </p>
                            <p className="ant-upload-text !text-white uppercase">
                              maximum size: 25mb
                            </p>
                            {/* <p className="ant-upload-hint">
                          {upldFile?.file ? (
                            <Button
                              className="!bg-white !text-primary !font-bold !w-[60%] !h-[50px] !rounded-3xl"
                              type="primary"
                              size="large"
                            >
                              Upload PDF
                            </Button>
                          ) : (
                            "MP3, M4A, WAV, PDF"
                          )}
                        </p> */}
                            <p className="ant-upload-text">
                              {upldFile?.file ? (
                                "Your file has been uploaded"
                              ) : (
                                <>
                                  <b className="text-white">Click to upload</b>{" "}
                                  or drag and drop
                                </>
                              )}
                            </p>
                            <p className="ant-upload-hint !text-white/50">
                              {upldFile?.file ? (
                                <Button
                                  onClick={handleUpldFileClr}
                                  type="text"
                                  className="!bg-white !font-bold !w-[60%] !h-[50px] !rounded-3xl"
                                  danger
                                  size="large"
                                >
                                  Delete
                                </Button>
                              ) : (
                                "MP3, M4A, WAV, PDF, PPTX, TXT, DOCX"
                              )}
                            </p>
                          </Upload.Dragger>
                        </div>
                      )}
                      {active === "Link" && (
                        <div className="w-full h-full pt-4">
                          <div className="relative">
                            <label
                              htmlFor="link"
                              className="absolute z-10 -top-5 left-3 bg-[#86A0FD] !text-xs font-semibold text-white px-2 py-1 border-2 border-primary rounded-3xl"
                            >
                              Youtube Link
                            </label>
                            <Form.Item name="link" className="!relative">
                              <Input
                                className="px-5 py-3 !text-white rounded-xl !bg-[#6788FC] focus:bg-[#6788FC] hover:bg-[#6788FC] !border-none placeholder:!text-[rgba(255,255,255,0.5)]"
                                placeholder="Content goes here"
                                onChange={({ target: { value } }) =>
                                  setLink(value)
                                }
                                size="large"
                                type="email"
                                id="email"
                                required
                              />
                            </Form.Item>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {createName === false && (
                  <div className="w-full h-full bg-white rounded-b-2xl p-5 md:px-10">
                    {active !== "Record" && (
                      <div>
                        <p className="text-base font-medium text-[#646462]">
                          How does it work?
                        </p>
                        <List
                          dataSource={[
                            "Easily Upload your educational material.",
                            "Effortlessly create Flashcards and Quizzes from your document to Reinforce key Concepts.",
                            "Engage in Insightful Discussions with our AI to deepen your understanding and enhance your overall learning experience.",
                          ]}
                          className="!space-y-10"
                          renderItem={(d, index) => {
                            // Function to bolden specific words in the text
                            const boldenText = (
                              text: string,
                              ...wordsToBold: any
                            ) => {
                              return text
                                .split(" ")
                                .map((word: string, index: number) => {
                                  if (wordsToBold.includes(word)) {
                                    return <strong key={index}>{word} </strong>;
                                  }
                                  return word + " ";
                                });
                            };

                            return (
                              <List.Item key={d}>
                                <List.Item.Meta
                                  avatar={<Avatar src={dotIcon} size="small" />}
                                  title={
                                    <p className="text-base font-medium">
                                      {index === 0
                                        ? boldenText(d, "Upload")
                                        : index === 1
                                        ? boldenText(d, "Flashcards", "Quizzes")
                                        : boldenText(
                                            d,
                                            "Insightful",
                                            "Discussions"
                                          )}
                                    </p>
                                  }
                                />
                              </List.Item>
                            );
                          }}
                        />
                      </div>
                    )}
                    {active === "Record" && timer && isRecording === false && (
                      <div className="w-full flex flex-col py-4 items-center justify-center gap-4">
                        <p className="text-[#1b1b1b] font-semibold text-[20px] leading-[18px]">
                          Select a timer
                        </p>
                        <div className="py-3 px-4 rounded-[12px] border border-primary">
                          <select
                            value={selectedOption}
                            onChange={handleSelectChange}
                            className="outline-none border-none focus:ring-0 w-[60px]"
                          >
                            <option value="" disabled>
                              Select an option
                            </option>
                            {options}
                          </select>

                          <span className="text-[14px] leading-[20px] text-primary">
                            Mins
                          </span>
                        </div>
                      </div>
                    )}
                    {active !== "Record" ? (
                      <div className="w-full">
                        {active === "Upload" && (
                          <Button
                            className="bg-primary !h-[50px]"
                            disabled={!upldFile?.file}
                            onClick={() => {
                              handleUpload();
                            }}
                            type="primary"
                            size="large"
                            shape="round"
                            block
                          >
                            Create Lecture
                          </Button>
                        )}

                        {active === "Link" && (
                          <Button
                            className="bg-primary !h-[50px]"
                            disabled={link.length === 0}
                            onClick={() => {
                              setCreateName(true);
                            }}
                            type="primary"
                            size="large"
                            shape="round"
                            block
                          >
                            Create Lecture
                          </Button>
                        )}
                      </div>
                    ) : (
                      <div className="w-full">
                        {/* <audio hidden={!manLecture.length} controls src={manLecture} /> */}
                        <ReactMic
                          onStop={onStop}
                          channelCount={1}
                          mimeType="audio/wav"
                          record={isRecording}
                          strokeColor="#000000"
                          // backgroundColor="#FF4081"
                          // onData={handleTranscription}
                          className="sound-wave w-full "
                        />
                        {isRecording === true ? (
                          <div className="w-full flex flex-col gap-4 md:gap-[32px] items-center justify-center">
                            <div className="flex items-center justify-center flex-col gap-3">
                              <span className="text-[50px] leading-[50px] font-bold">
                                {formatTime(elapsedTime)}
                              </span>
                              {selectedOption.length > 0 && (
                                <div className="flex items-center gap-1 font-medium text-[12px] leading-[18px]">
                                  <span className="text-[#b3b3b3] flex items-center gap-1">
                                    <LuAlarmClock size={20} color="#4970FC" />
                                    <span>Timer:</span>
                                  </span>
                                  <span className="text-[#7b7e8c]">
                                    {selectedOption} mins
                                  </span>
                                </div>
                              )}
                            </div>
                            {/* Buttons */}
                            <div className="flex items-center justify-center gap-4 w-full">
                              {/* <div
                              className="h-[60px] w-full md:w-[180px] rounded-[36px] border-[3px] border-[#f00] p-2 flex items-center justify-center gap-2 text-[#f00] cursor-pointer"
                              onClick={() => {
                                // handlePauseResume();
                                clearHandle();
                              }}
                            >
                              <MdPause size={20} color="#f00" />
                              <span className="text-[14px] leading-[20px] font-medium">
                                clear
                              </span>
                            </div> */}

                              <div
                                className="h-[60px] w-full rounded-[36px] bg-primary border-[3px] border-primary p-2 flex items-center justify-center gap-2 text-[#fff] cursor-pointer"
                                onClick={() => {
                                  // handleStopRecording();
                                  stopHandle();
                                  stopRecord();
                                }}
                              >
                                <LuDownloadCloud size={20} color="#fff" />
                                <span className="text-[14px] leading-[20px] font-medium">
                                  Save
                                </span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="w-full flex flex-col gap-3 items-center justify-center">
                            <CustomButton
                              text="Start Recording"
                              onClick={() => {
                                // handleStartRecording();
                                startHandle();
                              }}
                            />

                            {timer === false && (
                              <div
                                className="flex items-center gap-2 cursor-pointer"
                                onClick={() => setTimer(true)}
                              >
                                <LuAlarmClock size={20} color="#4970FC" />
                                <span className="text-[16px] leading-[18px] text-primary font-medium">
                                  Add a Timer
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
                {createName === true && (
                  <div className="w-full h-full bg-white rounded-b-2xl p-5 md:px-10">
                    <Button
                      className="bg-primary !h-[50px]"
                      disabled={lectureName.length === 0}
                      onClick={handleLectureCreation}
                      loading={actionLoad}
                      type="primary"
                      size="large"
                      shape="round"
                      block
                    >
                      Save
                    </Button>
                  </div>
                )}
              </div>
            </Modal>

            {/* loading modal >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
            <Modal
              styles={{
                content: {
                  background: "none",
                  padding: 0,
                },
              }}
              onCancel={onLoadClose}
              open={isLoadOpen}
              closeIcon={false}
              footer={false}
            >
              <div className="w-full h-full text-center p-5 md:p-10 bg-white rounded-2xl flex flex-col justify-center gap-5 items-center">
                <ImSpinner className="text-[100px] animate-spin text-primary" />
                <p className="text-[30px] font-extrabold text-dark">
                  Analyzing your <br /> content
                </p>
                <p className="text-sm font-medium text-[#646462]">
                  Please hold on while we analyze your content{" "}
                </p>
                {/* <Button
              className="text-primary text-lg font-extrabold"
              onClick={() => {
                onSuccessOpen();
                onLoadClose();
              }}
              size="large"
              type="text"
              block
            >
              Done
            </Button> */}
              </div>
            </Modal>

            {/* success modal >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
            <Modal
              styles={{
                content: {
                  background: "none",
                  padding: 0,
                },
              }}
              onCancel={onSuccessClose}
              open={isSuccessOpen}
              closeIcon={false}
              footer={false}
            >
              <div
                className="w-full h-full cursor-pointer"
                onClick={() => {
                  onSuccessClose();
                  // setGetLectData({
                  //   lectures: [{}, {}, {}],
                  // });
                }}
              >
                <Image
                  alt="success"
                  src={successImg}
                  preview={false}
                  className="!w-full"
                />
              </div>
            </Modal>

            {/* share to other users modal >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
            <ShareToUsersModal
              open={openShare}
              setOpenShare={setOpenShare}
              type="lectures"
              extractedId={extractedId}
            />

            {/* walkthrough popup
            >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
            {onboardingStatus === false && (
              <div className="fixed left-1/2 transform -translate-x-1/2 bottom-5 py-2 lg:py-4 px-4 lg:px-6 rounded-[16px] bg-[#1B1B1B] flex items-center gap-3 w-[90%] lg:w-auto">
                <div className="flex items-center gap-2">
                  <MdOutlineClose className="text-[#fff] text-[16px] flex-shrink-0" />
                  <span className="text-[12px] leading-[16px] lg:text-[14px] lg:leading-[22px] text-white">
                    Take a quick tour to dive into our features
                  </span>
                </div>
                {/*Divider*/}

                <div className="px-2 lg:px-4 flex items-center border-l-[1px] border-l-[#fff]">
                  <span
                    className="text-primary font-bold text-[12px] leading-[16px] lg:text-[14px] lg:leading-[22px] cursor-pointer"
                    onClick={() => {
                      setOnboardingState((prev: any) => ({
                        ...prev,
                        onboardingStatus: true,
                      }));
                    }}
                  >
                    Take tour
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <LoaderComponent />
      )}
    </Spin>
  );
}

export default Home;
