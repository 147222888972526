import React, { useEffect, useMemo, useState } from "react";
import Logo from "./Logo";
import { LuBookOpen, LuDownloadCloud, LuLogOut } from "react-icons/lu";
import { LiaGraduationCapSolid } from "react-icons/lia";
import { IoPlayBackOutline, IoPlayForwardOutline } from "react-icons/io5";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TbCards, TbMessageCircle } from "react-icons/tb";
import { BsGear, BsPostcard, BsSignpostSplit } from "react-icons/bs";
import {
  Avatar,
  Button,
  Divider,
  Drawer,
  Form,
  Input,
  Menu,
  Modal,
} from "antd";
import { isEqual } from "../context/utils";
import authAtom from "../atoms/auth/auth.atom";
import { LiaFolderOpen } from "react-icons/lia";
import { extractAvatar } from "../context/utils";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { FaPlus } from "react-icons/fa";
import { BiConversation, BiSupport, BiTime } from "react-icons/bi";
import { MdOutlineEmail, MdOutlineLeaderboard, MdPause } from "react-icons/md";
import { TbWindowMinimize } from "react-icons/tb";
import createLecturesAtom from "../atoms/modal/createLectures.atom";
import { formatTime, useStopHandle } from "../pages/main/home";
import { GoStack } from "react-icons/go";
import { RiTranslate } from "react-icons/ri";
import { FaCode } from "react-icons/fa";
import { ImBooks } from "react-icons/im";

type Props = {
  isMenuOpen: boolean;
  onMenuClose: any;
  isOpen: boolean;
  onClose: any;
  onOpen: any;
};

function SideBar({ isOpen, onOpen, onClose, isMenuOpen, onMenuClose }: Props) {
  const [isSupOpen, setIsSupOpen] = useState(false);
  const onSupClose = () => setIsSupOpen(false);
  const onSupOpen = () => setIsSupOpen(true);
  const { user } = useRecoilValue(authAtom);
  const { pathname } = useLocation();
  const [conPayload, setConPayload] = useState({
    subject: `Feedback from ${user?.info?.name}, ID: ${user?.info?._id}`,
    body: "",
  });
  const {
    isOpen: isOpenState,
    isRecording,
    elapsedTime,
  } = useRecoilValue(createLecturesAtom);
  const stopHandle = useStopHandle();
  const setLectureState = useSetRecoilState(createLecturesAtom);

  const query = new URLSearchParams(conPayload).toString();
  useEffect(() => document.getElementById(pathname)?.scrollIntoView(), [pathname])

  const navigate = useNavigate();
  const items = [
    {
      key: "/",
      label: (
        <span id="step-1" className="w-full h-full">
          My Lectures
        </span>
      ),
      icon: <LiaGraduationCapSolid id="/" className="!text-lg !font-bold" />,
    },
    {
      key: "/course",
      label: (
        <span id="step-2" className="w-full h-full">
          Courses
        </span>
      ),
      icon: <GoStack id="/course" className="!text-lg !font-bold" />,
    },
    {
      key: "/flashcard",
      label: (
        <span id="step-3" className="w-full h-full">
          Flashcards
        </span>
      ),
      icon: <TbCards id="/flashcard" className="!text-lg !font-bold" />,
    },
    {
      key: "/quiz",
      label: (
        <span id="step-4" className="w-full h-full">
          Quiz
        </span>
      ),
      icon: <BsPostcard id="/quiz" className="!text-lg !font-bold" />,
    },
    {
      key: "/recap",
      label: (
        <span id="step-5" className="w-full h-full">
          Recaps
        </span>
      ),
      icon: <LuBookOpen id="/recap" className="!text-lg !font-bold" />,
    },
    {
      key: "/discuss",
      label: (
        <span id="step-6" className="w-full h-full">
          Discuss
        </span>
      ),
      icon: <TbMessageCircle id="/discuss" className="!text-lg !font-bold" />,
    },
    // {
    //   key: "/study-guide",
    //   label: (
    //     <span id="step-7" className="w-full h-full">
    //       Study Guide
    //     </span>
    //   ),
    //   icon: <BsSignpostSplit id="/study-guide" className="!text-lg !font-bold" />,
    // },
    // {
    //   key: "/conversations",
    //   label: (
    //     <span id="step-8" className="w-full h-full">
    //       Conversations
    //     </span>
    //   ),
    //   icon: <BiConversation id="/conversations" className="!text-lg !font-bold" />,
    // },
    // {
    //   key: "/leader-board",
    //   label: (
    //     <span id="step-9" className="w-full h-full">
    //       Leaderboard
    //     </span>
    //   ),
    //   icon: <MdOutlineLeaderboard id="/leader-board" className="!text-lg !font-bold" />,
    // },
    {
      key: "/study-session",
      label: (
        <span id="step-10" className="w-full h-full">
          Study Session
        </span>
      ),
      icon: <BiTime id="/study-session" className="!text-lg !font-bold" />,
    },
    // {
    //   key: "/translate",
    //   label: (
    //     <span id="step-11" className="w-full h-full">
    //       Translate
    //     </span>
    //   ),
    //   icon: <RiTranslate id="/translate" className="!text-lg !font-bold" />,
    // },
    // {
    //   key: "/citation",
    //   label: (
    //     <span id="step-12" className="w-full h-full">
    //       Citation
    //     </span>
    //   ),
    //   icon: <LiaFolderOpen id="/citation" className="!text-lg !font-bold" />,
    // },
    // {
    //   key: "/coding-tutor",
    //   label: (
    //     <span id="step-12" className="w-full h-full">
    //       Coding Tutor
    //     </span>
    //   ),
    //   icon: <FaCode id="/coding-tutor" className="!text-lg !font-bold" />,
    // },
    {
      key: "/als",
      label: (
        <span id="step-13" className="w-full h-full">
          Adaptive Learning
        </span>
      ),
      icon: <ImBooks id="/als" className="!text-lg !font-bold" />,
    },
  ];

  const extras = useMemo(
    () => [
      {
        key: "hide",
        label: <p className="hidden md:block">{isOpen ? "Open" : "Hide"}</p>,
        icon: (
          <p className="!hidden md:!block">
            {!isOpen ? (
              <IoPlayBackOutline
                className="!text-lg !font-bold"
                onClick={onOpen}
              />
            ) : (
              <IoPlayForwardOutline
                className="!text-lg !font-bold"
                onClick={onClose}
              />
            )}
          </p>
        ),
      },
      {
        key: "support",
        label: "Contact Support",
        icon: <BiSupport className="!text-lg !font-bold" />,
      },
      {
        key: "/settings",
        label: "Settings",
        icon: <BsGear className="!text-lg !font-bold" />,
      },
    ],
    [isOpen, onClose, onOpen]
  );

  const handleMenu = ({ key }: any) => {
    if (isEqual(key, "support")) return onSupOpen();
    if (!isEqual(key, "hide")) return navigate(key);
    if (isOpen) return onClose();
    return onOpen();
  };
  const handleLogout = () => navigate("/auth/logout");

  const MenuContent = () => (
    <div
      className={`"w-full h-full flex flex-col justify-between items-center ${
        !isOpen && "md:px-3"
      } md:py-5 ${pathname === "/info" && "hidden"} relative space-y-`}
    >
      <Logo isIcon={isOpen} />

      <div className="w-full h-[80%] flex flex-col justify-between items-center gap-">
        <Menu
          style={{
            border: 0,
            background: "transparent",
            overflowY: "auto",
            height: "100%",
            color: "#646462",
            fontSize: "14px",
            fontWeight: "600",
          }}
          defaultSelectedKeys={[pathname]}
          defaultOpenKeys={[pathname]}
          inlineCollapsed={isOpen}
          onClick={handleMenu}
          key="main-menu"
          mode="inline"
          items={items}
        />
        <div className="w-full flex flex-col justify-center items-center">
          {(isOpenState === false && isRecording === true) && (
            <div className="bg-primary h-[56px] rounded-[49px] w-full px-4 flex items-center justify-center text-white overflow-hidden">
              <div className="w-full flex items-center justify-between">
                <div className="bg-white h-[36px] w-[36px] rounded-full flex items-center justify-center">
                  <MdPause size={20} color="#f00" />
                </div>
                <span className="text-[16px] leading-[20px] font-bold text-white">
                  {formatTime(elapsedTime)}
                </span>
                <div
                  className="h-[56px] border-x border-[#869eff] flex items-center justify-center px-4 md:gap-4 flex-col md:flex-row cursor-pointer"
                  onClick={() => {
                    stopHandle();
                  }}
                >
                  <LuDownloadCloud size={20} color="#fff" />
                  <span className="text-[14px] leading-[20px] font-medium">
                    Save
                  </span>
                </div>
                <div
                  className="transform rotate-180 cursor-pointer"
                  onClick={() => {
                    setLectureState((prev) => ({ ...prev, isOpen: true }));
                  }}
                >
                  <TbWindowMinimize size={22} color="#fff" />
                </div>
              </div>
              {/* <span className="text-[14px] leading-[24px] font-semibold">
              Processing
            </span> */}
            </div>
          )}
          <Menu
            style={{
              border: 0,
              background: "transparent",
              overflowY: "auto",
              height: "100%",
              color: "#646462",
              fontSize: "14px",
              fontWeight: "600",
            }}
            defaultSelectedKeys={[pathname]}
            defaultOpenKeys={[pathname]}
            inlineCollapsed={isOpen}
            onClick={handleMenu}
            key="extra-menu"
            items={extras}
            mode="inline"
          />
        </div>
      </div>

      <div
        className={`w-full flex ${
          isOpen ? "justify-center" : "justify-between"
        } items-center border-t border-silver pt-3`}
      >
        <div className="flex items-center gap-3">
          <Avatar size="large" src={user?.info?.profile_img}>
            {extractAvatar(user?.info?.name || user?.info?.email || "USER")}
          </Avatar>
          <p
            hidden={isOpen}
            className="text-primary text-[12px] w-[100px] truncate"
          >
            {user?.info?.email}
          </p>
        </div>
        <Button
          hidden={isOpen}
          onClick={handleLogout}
          className="text-primary !p-0 !m-0"
          type="text"
          icon={<LuLogOut className="!text-lg !font-bold" />}
        />
      </div>
    </div>
  );
  return (
    <div className="w-full h-full">
      <div className="hidden md:block w-full h-full ">
        <MenuContent />
      </div>

      <Drawer
        onClose={onMenuClose}
        open={isMenuOpen}
        closeIcon={false}
        placement="left"
        footer={false}
        width={250}
      >
        <MenuContent />
      </Drawer>

      <Modal
        title="Get In Touch"
        onCancel={onSupClose}
        open={isSupOpen}
        footer={false}
      >
        <Form layout="vertical" initialValues={conPayload}>
          <Form.Item label="Subject" name="subject">
            <Input placeholder="Enter email" size="large" readOnly />
          </Form.Item>
          <Form.Item label="Message" name="body">
            <Input.TextArea
              onChange={({ target: { value: body } }) =>
                setConPayload({
                  ...conPayload,
                  body,
                })
              }
              placeholder="Enter message"
              size="large"
              rows={5}
            />
          </Form.Item>
        </Form>
        <div className="">
          <a
            className="w-full py-3 bg-primary text-white rounded-lg flex justify-center items-center gap-3 text-xl font-medium"
            href={`mailto:support@nurovant.com?${query?.replaceAll("+", " ")}`}
            onClick={onSupClose}
          >
            <MdOutlineEmail />
            <p>support@nurovant.com</p>
          </a>
        </div>
      </Modal>

      <div className="hidden bg-white fixed bottom-0 left-0 right-0 z-20">
        <Divider className="!m-0 !p-0">
          <Button
            icon={<FaPlus className="text-2xl" />}
            className="bg-primary"
            type="primary"
            shape="circle"
            size="large"
            hidden
          />
        </Divider>
        <div className="flex justify-between items-center gap-5 flex-nowrap overflow-x-auto p-5">
          {items?.map(({ key, icon, label }) => (
            <Link
              to={key}
              key={key}
              className={`${
                isEqual(key, pathname) ? "text-primary" : "text-silver"
              } flex flex-col justify-center items-center space-y-3 text-xs font-medium whitespace-nowrap`}
            >
              {icon}
              <p>{label}</p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SideBar;
