import { Image } from "antd";
import React from "react";
import Options from "../assets/options.svg";

const DropDownFeedComponent = ({
  dropDownOptions,
  showDropDown,
  setShowDropDown,
}: {
  dropDownOptions: {
    title: string;
    icon: string;
    action: () => void;
  }[];
  showDropDown: boolean;
  setShowDropDown: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <div className="flex items-center justify-center cursor-pointer relative">
      <Image
        src={Options}
        alt="options"
        preview={false}
        onClick={() => {
          setShowDropDown((prev) => !prev);
        }}
      />
      {showDropDown && (
        <div className="w-[156px] rounded-[8px] py-[12px] shadow-md absolute right-0 top-7 bg-white flex flex-col z-50">
          {dropDownOptions.map((option, index) => (
            <div
              className={`flex items-center justify-between px-2 py-[6px] hover:bg-gray/10 transition-all duration-300 ease-in-out ${
                dropDownOptions.length - 1 === index
                  ? ""
                  : "border-b border-[#E0E0E0]"
              }`}
              key={index}
              onClick={() => {
                option.action();
              }}
            >
              <div className="flex w-full items-center gap-[13px] justify-between">
                <span className="text-[14px] leading-[20px] text-[#1B1B1B]">
                  {option.title}
                </span>
                <Image alt="feedback icon" preview={false} src={option.icon} />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropDownFeedComponent;
