import { ReactComponentElement } from "react";

type Props = {
  children: ReactComponentElement<any>;
};
const PublicLayout = ({ children }: Props) => {
  return (
    <div className="w-full min-h-screen bg-light font-montserrat">
      {/* header component can come in here  */}
      <div className="w-full">{children}</div>
      {/* footer component can come in here  */}
    </div>
  );
};

export default PublicLayout;
