import { Button, Modal } from "antd";
import React from "react";
import ballonbird from "../../assets/ballonbird.svg";
import { onboardingState } from "../../atoms/conversations/onboardingAtom";
import { useRecoilState } from "recoil";
type Props = {
  openSuccess: boolean;
  setOpenSuccess: React.Dispatch<React.SetStateAction<boolean>>;
};

const WikipidiaSummaryOnboarding = ({ openSuccess, setOpenSuccess }: Props) => {
  return (
    <Modal
      open={openSuccess}
      onCancel={() => setOpenSuccess(false)}
      footer={false}
      closeIcon={false}
      className="w-full lg:max-w-[614px] max-h-[80vh] overflow-y-auto rounded-2xl"
      style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
    >
      <main>
        <section className=" pb-5">
          <figure>
            <img
              src={ballonbird}
              alt=""
              className="w-[215px] h-[214px] object-contain mx-auto"
            />
          </figure>
          <aside>
            <h1 className="text-center text-[20px] leading-5 font-semibold">
              Welcome to Recaps
            </h1>
            <p className="text-[16px] text-center mt-3">
              Simply select/Highlight a word to discover it's definition.
            </p>

            <p className="text-sm text-center mt-8 italic">
              Please note that this only works for a word and not a group of
              words. Enjoy
            </p>
          </aside>
        </section>
      </main>
    </Modal>
  );
};

export default WikipidiaSummaryOnboarding;
