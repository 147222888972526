import { useRef, useState, useEffect } from "react";
import WelcomeLogo from "../assets/WelcomeLogo.png";
import createLecturesAtom from "../atoms/modal/createLectures.atom";
import { useRecoilValue } from "recoil";

const Loader = () => {
  const { progressBar } = useRecoilValue(createLecturesAtom);

  return (
    <div className="h-[40px] w-full lg:w-[492px] flex items-center px-4 lg:px-0 gap-1">
      <div className="w-full flex items-center rounded-l-[18px] rounded-r-[18px] h-[14px] bg-white overflow-hidden">
        <div
          className="h-full bg-primary"
          style={{
            width: `${progressBar}%`,
            transition: "width 0.5s ease-in-out",
          }}
        ></div>
      </div>
      <span className="text-[14px] leading-[40px] text-primary font-medium">
        {progressBar}%
      </span>
    </div>
  );
};

export const LoaderComponent = () => {
  return (
    <div className="flex items-center justify-center !w-full h-full py-4 lg:py-[32px] px-4 lg:px-0">
      <div className="bg-[#f9f9f9] border border-[#e0e0e0] rounded-[16px] w-[80%] max-w-[916px] flex flex-col items-center justify-center h-[604px]">
        <div className="flex flex-col items-center justify-center gap-4">
          <img
            src={WelcomeLogo}
            alt="Logo"
            className="w-[150px] md:w-[228px]"
          />
          <div className="flex flex-col gap-2 items-center justify-center text-center">
            <h1 className="text-[24px] leading-[36px] md:text-[30px] md:leading-[40px] font-bold">
              Importing Materials
            </h1>
            <p className="text-[14px] leading-[30px]">
              Your materials are being imported. This may take a few minutes
            </p>
          </div>
        </div>
        {/*Loader*/}
        <Loader />
      </div>
    </div>
  );
};
