import React, { ReactComponentElement } from "react";
import ImageCardContainer from "../../components/ImageCardContainer";

type Props = {
  children: ReactComponentElement<any>;
};
const AuthLayout = ({ children }: Props) => {
  return (
    <ImageCardContainer
      title='Engaging Quiz'
      className="flex justify-center items-center"
      description='Test your understanding, reinforce concepts, and stay on top of your game.'
    >{children}</ImageCardContainer>
  )
  // return (
  //   <div className="w-full min-h-screen auth-layout flex justify-center items-center">
  //     <div className="w-full md:w-[50%] lg:w-[35%] mx-auto">
  //       {children}
  //     </div>
  //   </div>
  // );
};

export default AuthLayout;
