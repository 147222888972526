import { Button, Modal } from "antd";
import React, { useState } from "react";
import { useStartConversation } from "../../hooks/conversations/conversations";
import { useRecoilState, useRecoilValue } from "recoil";
import { slackAuthAtom } from "../../atoms/auth/slack.atom";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  members: any[];
};

const SlackGroupMembers = ({ open, setOpen, members }: Props) => {
  return (
    <main>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        footer={false}
        closeIcon={false}
        className="w-full lg:max-w-[614px]"
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
      >
        <div className="space-y-3 max-w-full overflow-x-auto">
          <article className="font-bold text-[18px] flex justify-between gap-3 items-center">
            <h1>Name</h1>
            <h1>Email</h1>
          </article>
          {members.map((member) => (
            <div
              className="flex gap-5 w-full whitespace-pre !break-words"
              // style={{ wordWrap: "break-word" }}
            >
              <img
                src={member?.user?.profile?.image_24}
                alt=""
                className="w-7 h-7 object-contain rounded-full"
              />
              <p className="!w-[200px]">{member?.user?.name}</p>
              <p className=" !break-words !whitespace-normal">
                {member?.user?.profile?.email}
              </p>
            </div>
          ))}
        </div>
      </Modal>
    </main>
  );
};

export default SlackGroupMembers;
