import { Modal } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { IoClose, IoCheckmarkSharp } from "react-icons/io5";
import { useSearchParams } from "react-router-dom";
import { VscLoading } from "react-icons/vsc";
import MatchingFlashcardsSuccess from "./MatchingFlashcardsSuccess";

type Props = {
  canPractice: boolean;
  setCanPractice: React.Dispatch<React.SetStateAction<boolean>>;
  flashcard: { word: string; meaning: string }[];
  is_matched: boolean;
};

const MatchingFlashcards = ({
  canPractice,
  setCanPractice,
  flashcard,
  is_matched,
}: Props) => {
  const [params] = useSearchParams();
  const id = params.get("id");
  const [selectedWords, setSelectedWords] = useState<any[]>([]);
  const [matchedWords, setMatchedWords] = useState<any[]>([]);
  const [unmatchedWords, setUnmatchedWords] = useState<any[]>([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [word, setWord] = useState<any>(null);

  const handleMatch = (word: any) => {
    setSelectedWords((prevSelectedWords) => [...prevSelectedWords, word]);

    if (selectedWords.length === 1) {
      if (selectedWords[0].id === word.id) {
        setMatchedWords((prevMatchedWords) => [
          ...prevMatchedWords,
          ...selectedWords,
          word,
        ]);
        setSelectedWords([]);
      } else {
        setUnmatchedWords((prevUnmatchedWords) => [
          ...prevUnmatchedWords,
          ...selectedWords,
          word,
        ]);
        setSelectedWords([]);
      }
    } else if (selectedWords.length === 2) {
      setSelectedWords([word]);
    }
  };

  useEffect(() => {
    if (word) {
      handleMatch(word);
    }
  }, [word]);

  useEffect(() => {
    if (
      (matchedWords.length + unmatchedWords.length) / 2 ===
      flashcard.length
    ) {
      setOpenSuccess(true);
    }
  }, [matchedWords, unmatchedWords]);

  const shuffleArray = (array: any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const unShuffledWords = flashcard.map((card, index) => ({
    id: index,
    word: card.word,
  }));

  const unShuffledMeanings = flashcard.map((card, index) => ({
    id: index,
    meaning: card.meaning,
  }));

  const combinedArray = [...unShuffledWords, ...unShuffledMeanings];

  const cards = useMemo(() => shuffleArray(combinedArray), [flashcard]);

  return (
    <section className="matchModal">
      <Modal
        open={canPractice}
        onCancel={() => setCanPractice(false)}
        footer={false}
        closeIcon={false}
        width={700}
        className="w-full lg:w-[700px] max-h-[80vh] overflow-y-auto rounded-2xl "
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
      >
        <section className="py-6 w-full">
          <aside className="grid grid-cols-2 md:grid-cols-3 gap-6 justify-items-center">
            {cards.map((word, index) => (
              <div
                className={`relative w-[150px] md:w-[200px] min-h-[200px] hover:bg-[#DEF2E6] p-4 rounded-xl cursor-pointer ${
                  selectedWords &&
                  selectedWords.some(
                    (matchedWord) =>
                      matchedWord.id === word.id &&
                      matchedWord.word === word.word
                  )
                    ? "bg-[#DEF2E6]"
                    : "bg-[#F5F5F5]"
                } ${
                  matchedWords.some(
                    (matchedWords) =>
                      (matchedWords.word && matchedWords.word === word.word) ||
                      (matchedWords.meaning &&
                        matchedWords.meaning === word.meaning)
                  ) && "disabled pointer-events-none"
                } ${
                  unmatchedWords.some(
                    (unmatchedWords) =>
                      (unmatchedWords.word &&
                        unmatchedWords.word === word.word) ||
                      (unmatchedWords.meaning &&
                        unmatchedWords.meaning === word.meaning)
                  ) && "disabled pointer-events-none"
                }`}
                onClick={() => setWord(word)}
              >
                {word.word && word.word}
                {word.meaning && word.meaning}
                {matchedWords.some(
                  (eachWord) =>
                    (eachWord.id === word.id && eachWord.word === word.word) ||
                    (eachWord.id === word.id &&
                      eachWord.meaning === word.meaning)
                ) && (
                  <div>
                    <IoCheckmarkSharp className="absolute top-2 right-2 text-green-500" />
                  </div>
                )}
                {unmatchedWords.some(
                  (eachWord) =>
                    (eachWord.id === word.id && eachWord.word === word.word) ||
                    (eachWord.id === word.id &&
                      eachWord.meaning === word.meaning)
                ) && (
                  <div>
                    <IoClose className="absolute top-2 right-2 text-red" />
                  </div>
                )}
              </div>
            ))}
            {/* {meanings.map((meaning, index) => (
              <div
                className={`relative  hover:bg-[#DEF2E6] w-[150px] md:w-[200px] min-h-[200px] p-4 rounded-xl cursor-pointer ${
                  selectedWords &&
                  selectedWords.some(
                    (selected) => selected.meaning === meaning.meaning
                  )
                    ? "bg-[#DEF2E6] disabled pointer-events-none"
                    : "bg-[#F5F5F5]"
                } ${
                  matchedWords.some(
                    (matchedWords) => matchedWords.meaning === meaning.meaning
                  ) && "disabled pointer-events-none"
                } ${
                  unmatchedWords.some(
                    (unmatchedWords) =>
                      unmatchedWords.meaning === meaning.meaning
                  ) && "disabled pointer-events-none"
                }`}
                onClick={() => setWord(meaning)}
              >
                {meaning.meaning}
                {matchedWords.some(
                  (word) =>
                    word.id === meaning.id && word.meaning === meaning.meaning
                ) && (
                  <div>
                    <IoCheckmarkSharp className="absolute top-2 right-2 text-green-500" />
                  </div>
                )}
                {unmatchedWords.some(
                  (word) =>
                    word.id === meaning.id && word.meaning === meaning.meaning
                ) && (
                  <div>
                    <IoClose className="absolute top-2 right-2 text-red" />
                  </div>
                )}
              </div>
            ))} */}
          </aside>
          {/* <aside className="flex flex-col gap-3"></aside> */}
        </section>
      </Modal>
      <MatchingFlashcardsSuccess
        right={matchedWords.length / 2}
        wrong={unmatchedWords.length / 2}
        flashcardLength={flashcard.length}
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        is_matched={is_matched}
      />
    </section>
  );
};

export default MatchingFlashcards;
