import React from 'react'

type Props = {
    color?: string,
    bg?: string,
}
function RecapIcon({
    color,
    bg,
}: Props) {
    return (
        <svg width="191" height="199" viewBox="0 0 191 199" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_2401_10206)">
                <path d="M93.3052 172.715V64.6397C96.3529 52.9657 103.434 44.1456 116.082 38.1029C129.012 31.925 147.811 28.6365 174.083 28.5186C174.558 28.5169 175.028 28.6092 175.467 28.79C175.906 28.9709 176.305 29.2369 176.641 29.5728L178.624 27.5892L176.641 29.5728C176.976 29.9086 177.242 30.3076 177.423 30.7467C177.604 31.1859 177.697 31.6565 177.695 32.1315V32.142V147.856C177.695 148.817 177.313 149.739 176.634 150.418C175.954 151.098 175.032 151.48 174.071 151.48C128.424 151.48 106.748 159.597 93.3052 172.715ZM87.6948 64.6397V172.716C74.2866 159.648 52.5541 151.48 6.92861 151.48C5.76977 151.48 4.94205 151.035 4.3616 150.353C3.74577 149.63 3.30524 148.503 3.30524 147.081V32.142H3.30526L3.30522 32.1314C3.30343 31.6565 3.39565 31.1859 3.57658 30.7467C3.75751 30.3076 4.02356 29.9086 4.3594 29.5728C4.69523 29.2369 5.09422 28.9709 5.53335 28.79C5.97218 28.6092 6.44243 28.5169 6.91702 28.5186C33.1895 28.6365 51.9875 31.925 64.9179 38.1029C77.5655 44.1456 84.6471 52.9657 87.6948 64.6397Z" fill={bg} stroke={color} stroke-width="5.61039" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <filter id="filter0_d_2401_10206" x="0.5" y="0" width="190.473" height="198.327" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dx="7.85455" dy="15.7091"/>
                    <feGaussianBlur stdDeviation="1.30909"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2401_10206"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2401_10206" result="shape"/>
                </filter>
            </defs>
        </svg>
    )
}

export default RecapIcon