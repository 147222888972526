import Logo from "../Logo";
import React, { useMemo } from "react";
// @ts-ignore
import Draggable from "react-draggable";
import { Avatar, Button, Modal, Popover, Tooltip } from "antd";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  joinChallengeAtom,
  viewChallengeAtom,
} from "../../atoms/modal/modal.atom";
import tagicon from "../../assets/icons/leaderboardicon.png";
import authAtom from "../../atoms/auth/auth.atom";
import { AiOutlineClose } from "react-icons/ai";
import { PiCrownFill } from "react-icons/pi";
import { isEqual } from "../../context/utils";
import { GiStarMedal } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import {
  useGetUserChallenge,
  useGetLeaderboard,
  useGetUserClaimBonus,
} from "../../hooks/gamification/gamification";
import { BsFillSignRailroadFill } from "react-icons/bs";

function Gamification() {
  const navigate = useNavigate();
  const { user } = useRecoilValue(authAtom);
  const [isViewOpen, setIsViewOpen] = useRecoilState(viewChallengeAtom);
  const [isOpen, setIsOpen] = useRecoilState(joinChallengeAtom);
  const goToRanking = () => navigate("/ranking");
  const onViewClose = () => setIsViewOpen(false);
  const onViewOpen = () => setIsViewOpen(true);
  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);

  const {
    data: getLeaderboardData,
    refetch: getLeaderboardFetch,
    isFetching: getLeaderboardLoad,
  } = useGetLeaderboard();

  const {
    data: getUserChallData,
    refetch: getUserChallFetch,
    isFetching: getUserChallLoad,
  } = useGetUserChallenge();

  const { mutate: getBonusAction, isLoading: getBonusLoad } =
    useGetUserClaimBonus(() => {
      getLeaderboardFetch();
      getUserChallFetch();
      goToRanking();
      onViewClose();
      onClose();
    });

  const userPosition = useMemo(
    () =>
      getLeaderboardData?.data?.findIndex((d: any) =>
        isEqual(d?.user?._id, user?.info?._id)
      ) + 1,
    [getLeaderboardData, user]
  );
  const boardData = useMemo(
    () => getLeaderboardData?.data?.slice(0, 3),
    [getLeaderboardData]
  );
  const challData = useMemo(
    () => getUserChallData?.data || {},
    [getUserChallData]
  );
  const keys = useMemo(() => ["flashcards", "quizzez", "recaps"], []);
  const percent = useMemo(
    () =>
      Math.round(
        Object.entries(challData)
          .filter(([key]) => keys.includes(key))
          .reduce((a, b) => a + Number(b[1]) * 16.65, 0) || 0
      ),
    [challData, keys]
  );
  const isChallComplete = isEqual(percent, 100);

  const DragContent = () => (
    <div className="max-w-[70vw] !z-0 md:w-full">
      <div
        hidden={!Boolean(user?.isBoard)}
        className="w-full bg-primary text-white p-5 space-y-3 rounded-2xl"
      >
        <p className="text-2xl font-semibold">Hey there</p>
        <p className="text-sm font-medium">
          Join this week’s streak challenge, compete and earn amazing prizes
        </p>
        <Button
          className="!bg-white !text-primary font-semibold"
          onClick={onOpen}
          type="primary"
          shape="round"
          size="large"
          id="action"
          block
        >
          Join Challenge
        </Button>
      </div>
      <div
        hidden={Boolean(user?.isBoard)}
        className="w-full bg-[#C2CFFE] text-[#010101] p-5 space-y-3 rounded-2xl !relative"
      >
        <img
          className="w-auto z-50 absolute top-0 right-5"
          src={tagicon}
          alt="tag"
        />
        <p className="text-sm font-medium pr-10">
          {isChallComplete
            ? "Challenge completed! don't forget to claim your reward."
            : "Complete this challenge and unlock incredible rewards"}
        </p>
        <div className="w-full bg-white rounded-xl">
          <div
            style={{ width: `${percent}%` }}
            className="bg-yellow p-1 rounded-xl"
          />
        </div>
        <div className="flex justify-between items-center gap-5" id="action">
          <Button
            className="!bg-transparent !text-primary font-semibold !m-0 !p-0"
            onClick={onViewOpen}
            type="text"
          >
            {isChallComplete ? "Claim reward" : "View progress"}
          </Button>
          <Tooltip title="View your level ranking">
            <Button
              icon={<BsFillSignRailroadFill className="text-xl text-primary" />}
              onClick={goToRanking}
              type="text"
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );

  const TaskContent = () => (
    <div className="w-full bg-pridark rounded-lg p-4 py-7 space-y-3 text-start">
      {[
        // "Upload a document twice in one day",
        "Generate 2 flashcards in one day",
        "Generate 2 quizzes in one day",
        "Generate 2 recaps in one day",
      ].map((d, idx) => {
        const perc = challData?.[keys?.[idx]] * 50;
        return (
          <div className="space-y-2">
            <p className="text-base font-medium">{d}</p>
            <div className="w-full bg-white rounded-xl">
              <div
                style={{ width: `${perc}%` }}
                className="bg-yellow p-1 rounded-xl"
              />
            </div>
          </div>
        );
      })}
    </div>
  );

  const handleView = () => {
    navigate("/leader-board");
    onViewClose();
    onClose();
  };

  const isLoading = getUserChallLoad || getLeaderboardLoad;
  return (
    <div hidden={isLoading}>
      <Popover
        color="transparent"
        overlayInnerStyle={{ boxShadow: "none" }}
        arrow={false}
        content={<DragContent />}
        trigger="click"
      >
        <Button
          className="absolute bottom-40 right-2 !bg-primary shadow-lg md:hidden"
          icon={<GiStarMedal className="text-xl" />}
          shape="circle"
          type="primary"
          size="large"
        />
      </Popover>
      <Draggable cancel="#action" bounds="body">
        <div className="w-[80vw] md:w-[250px] hidden md:block cursor-grabbing absolute right-5 bottom-20">
          <DragContent />
        </div>
      </Draggable>

      {/* join challenge modal >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
      <Modal
        styles={{
          content: {
            background: "#4970FC",
            color: "#fff",
          },
        }}
        closeIcon={<AiOutlineClose className="text-white text-5xl mx-auto" />}
        maskClosable={false}
        onCancel={onClose}
        footer={false}
        open={isOpen}
      >
        <div className="w-full md:w-[75%] mx-auto flex flex-col justify-center items-center gap-5 text-center">
          <Logo isWLogo className="!w-[100px]" />
          <div className="space-y-3">
            <p className="text-2xl font-bold">Hey there!</p>
            <p className="text-base font-medium">
              Participate in this week's challenge for a chance to win fantastic
              prizes!
            </p>
            <TaskContent />
          </div>
          <Button
            className="!bg-white !text-primary font-semibold !h-[50px]"
            onClick={onOpen}
            type="primary"
            shape="round"
            size="large"
            id="action"
            block
          >
            Join Challenge
          </Button>
        </div>
      </Modal>

      {/* view challenge progress modal >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
      <Modal
        styles={{
          content: {
            background: "#4970FC",
            color: "#fff",
          },
        }}
        closeIcon={<AiOutlineClose className="text-white text-5xl mx-auto" />}
        onCancel={onViewClose}
        maskClosable={false}
        open={isViewOpen}
        footer={false}
      >
        <div className="w-full md:w-[75%] mx-auto flex flex-col justify-center items-center gap-5 text-center">
          <p className="text-base font-medium">
            You’re in the top {userPosition} users to get this prize
          </p>
          <div className="w-full flex justify-center items-center gap-5">
            {[1, 0, 2].map((d) => {
              const data = boardData?.[d];
              const height = { 0: 100, 1: 75, 2: 50 }?.[d];
              return (
                <div className="flex flex-col justify-center items-center gap-3 font-semibold text-white">
                  {!d ? (
                    <PiCrownFill className="!text-3xl text-yellow" />
                  ) : (
                    <p>
                      {isEqual(data?.user?._id, user?.info?._id)
                        ? "You"
                        : d + 1}
                    </p>
                  )}
                  <Avatar size="large" src={data?.user?.profile_img}>
                    {data?.user?.name || "NIL"}
                  </Avatar>
                  <div
                    style={{ height: `${height}px` }}
                    className={`w-[70px] pt-5 rounded-t-lg text-dark font-medium ${
                      !d ? "bg-yellow" : "bg-[#A4B7FD]"
                    }`}
                  >
                    <span className="text-base">
                      {data?.leaderboard_points}
                    </span>
                    <span className="text-sm">pts</span>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="space-y-3">
            <Button
              onClick={handleView}
              type="text"
              className="!bg-transparent !text-base !text-white !font-medium !underline"
            >
              View Leaderboard{" "}
            </Button>
            <TaskContent />
          </div>
          <Button
            className="!bg-white !text-primary font-semibold !h-[50px]"
            onClick={() => getBonusAction()}
            hidden={!isChallComplete}
            loading={getBonusLoad}
            type="primary"
            shape="round"
            size="large"
            id="action"
            block
          >
            Claim Reward
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default Gamification;
