import { Button, Image, Modal } from "antd";
import Satisfied from "../../assets/Satisfied.svg";
import NotGood from "../../assets/NotGood.svg";
import feedbackImg from "../../assets/Feedback.png";
import { useEffect, useState } from "react";
import { usePostFeedback } from "../../hooks/otherhooks";

type Props = {
  showModal: boolean;
  featureId: string | null;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  feature: string;
};

type feedType = "main" | "success" | "reason" | "failure";

function RadioGroup({
  options,
  onChange,
}: {
  options: any[];
  onChange: (selectedOption: string) => void;
}) {
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event: any) => {
    const value = event.target.value;
    setSelectedOption(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div className="flex flex-col gap-4 items-start">
      {options.map((option) => (
        <label key={option} className="inline-flex items-center mr-4">
          <input
            type="radio"
            className="form-radio h-4 w-4 text-indigo-600"
            value={option}
            checked={selectedOption === option}
            onChange={handleOptionChange}
          />
          <span className="ml-2">{option}</span>
        </label>
      ))}
    </div>
  );
}

export const FeedbackModal = ({
  showModal,
  featureId,
  setShowModal,
  feature,
}: Props) => {
  const [feedType, setFeedType] = useState<feedType>("main");

  const [isLiked, setIsLiked] = useState(true);
  const [selectedFeedBack, setSelectedFeedBack] = useState("");
  const [feedBack, setFeedBack] = useState("");

  const handleOptionChange = (selectedOption: string) => {
    setSelectedFeedBack(selectedOption);
  };

  const options = [
    "It contains inaccurate information",
    "It is inappropriate",
    "It is being used to cheat",
    "It violates my intellectual property right",
    "Other type of problem",
  ];

  const successAction = () => {
    setFeedType("success");
  };

  const { mutate, isLoading } = usePostFeedback(successAction);

  return (
    <Modal
      onCancel={() => {
        setShowModal(false);
        setFeedType("main");
      }}
      open={showModal}
      closeIcon={false}
      footer={false}
      styles={{
        content: {
          background: feedType === "success" ? "none" : "#fff",
          padding: feedType === "success" ? 0 : "16px",
          boxShadow: "none",
        },
      }}
    >
      {feedType === "main" && (
        <div
          className="w-full h-full cursor-pointer"
          onClick={() => {
            //   onSuccessClose();
            // setGetLectData({
            //   lectures: [{}, {}, {}],
            // });
          }}
        >
          <h1 className="text-[26px] leading-[31.69px] font-bold text-center text-[#1b1b1b]">
            How was your experience?
          </h1>

          <div className="mt-5 flex items-center justify-center gap-4">
            <div
              className="flex flex-col items-center gap-1 cursor-pointer"
              onClick={() => {
                setIsLiked(true);
                mutate({
                  feature,
                  feature_id: featureId,
                  is_liked: "true",
                  selected_feedback:
                    selectedFeedBack.length > 0
                      ? selectedFeedBack
                      : "Good content",
                  feedback:
                    feedBack.length > 0 ? feedBack : `The ${feature} was good`,
                });
              }}
            >
              <div className="w-[80px] h-[80px] rounded-full bg-[#f9f9f9] flex items-center justify-center">
                <Image alt="Satisfied emoji" src={Satisfied} preview={false} />
              </div>
              <span className="text-[12px] leading-[18px] text-center">
                Satisfied
              </span>
            </div>

            <div
              className="flex flex-col items-center gap-1 cursor-pointer"
              onClick={() => {
                setIsLiked(false);
                setFeedType("reason");
              }}
            >
              <div className="w-[80px] h-[80px] rounded-full bg-[#f9f9f9] flex items-center justify-center">
                <Image alt="NotGood emoji" src={NotGood} preview={false} />
              </div>
              <span className="text-[12px] leading-[18px] text-center">
                Not Good
              </span>
            </div>
          </div>
        </div>
      )}

      {feedType === "success" && (
        <div
          className="w-full h-full cursor-pointer"
          onClick={() => setShowModal(false)}
        >
          <Image
            alt="success"
            src={feedbackImg}
            preview={false}
            className="!w-full"
          />
        </div>
      )}

      {feedType === "reason" && (
        <div className="w-full h-full">
          <h1 className="text-[26px] leading-[31.69px] font-bold text-center text-[#1b1b1b]">
            What went wrong?
          </h1>

          <div className="mt-[32px] flex flex-col gap-4">
            <RadioGroup options={options} onChange={handleOptionChange} />
            <div className="w-full">
              <textarea
                value={feedBack}
                placeholder="Send us a message"
                onChange={(e: any) => setFeedBack(e.target.value)}
                className="h-[128px] px-[18px] py-[24px] rounded-[8px] bg-[#f5f5f5] w-full text-[#1b1b1b] focus:ring-0 border-none outline-none"
              />
            </div>
          </div>

          <Button
            className="!font-medium !text-[14px] !leading-[20px] bg-primary w-full !text-white rounded-full mt-4 md:mt-5 !border-primary !h-[56px]"
            onClick={() => {
              // // deleteLectAction(lecture_id);
              // Modal.destroyAll();

              mutate({
                feature,
                feature_id: featureId,
                is_liked: "false",
                selected_feedback: selectedFeedBack,
                feedback: feedBack,
              });
            }}
            loading={isLoading}
            danger
          >
            <span>Submit</span>
          </Button>
        </div>
      )}
    </Modal>
  );
};
