import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist({
  key: "slackUser",
  storage: localStorage,
});

export const slackUserInfo = atom({
  key: "slackUserInfoKey",
  default: {
    slackUserProfile: {},
  },
  effects_UNSTABLE: [persistAtom],
});
