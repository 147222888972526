export const AllNationsCode = [
  {
    label: "🇺🇸 United States",
    value: "+1",
    code: "US",
    emoji: "🇺🇸",
    new_field: "United States",
  },

  {
    label: "🇨🇦 Canada",
    value: "+1",
    code: "CA",
    emoji: "🇨🇦",
    new_field: "Canada",
  },

  {
    label: "🇳🇬 Nigeria",
    value: "+234",
    code: "NG",
    emoji: "🇳🇬",
    new_field: "Nigeria",
  },
];

export type MimeType =
  | "audio/wav"
  | "audio/webm"
  | "audio/webm;codecs=pcm"
  | "video/mp4"
  | "video/webm"
  | "video/webm;codecs=vp9"
  | "video/webm;codecs=vp8"
  | "video/webm;codecs=h264"
  | "video/x-matroska;codecs=avc1"
  | "video/mpeg"
  | "audio/ogg"
  | undefined;


  
  export const languages = [
    {
      key: "eng",
      country: "US",
      name: "English",
      prompts: [
        "Hi, how are you today?",
        "What are your plans for the weekend?",
        "Have you seen any good movies recently?",
      ],
    },
    {
      key: "fra",
      country: "FR",
      name: "French",
      prompts: [
        "Salut, comment vas-tu aujourd'hui?",
        "Quels sont vos plans pour le week-end?",
        "Avez-vous vu de bons films récemment ?",
      ],
    },
    {
      key: "deu",
      country: "DE",
      name: "German",
      prompts: [
        "Hi wie geht es Dir heute?",
        "Was hast Du am Wochenende vor?",
        "Hast du in letzter Zeit gute Filme gesehen?",
      ],
    },
    {
      key: "ita",
      country: "IT",
      name: "Italian",
      prompts: [
        "Ciao, come stai oggi?",
        "Quali sono i tuoi progetti per il fine settimana?",
        "Hai visto qualche bel film di recente?",
      ],
    },
    {
      key: "spa",
      country: "ES",
      name: "Spanish",
      prompts: [
        "Hola, ¿cómo estás hoy?",
        "¿Qué planes tienes para el fin de semana?",
        "¿Has visto alguna buena película recientemente?",
      ],
    },
  ]
