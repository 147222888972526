import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist({
  key: "NurovantSlackChannel",
  storage: localStorage,
});

export const channelAtom = atom({
  key: "slackChannel",
  default: {
    slackChannel: {},
  },
  effects_UNSTABLE: [persistAtom],
});
