import React, { useMemo, useState } from 'react'
import { Button, Divider, Modal, Radio, Spin } from 'antd';
import { useRecoilValue } from 'recoil';
import authAtom from '../../atoms/auth/auth.atom';
import SubscribeContent from '../../pages/main/settings/contents/subscribe';
import { isEqual } from '../../context/utils';
import { FaCheckCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useGetSubs, usePostSub, usePostVerifySub } from '../../hooks/profile/profile';
import { useStripePay } from '../../hooks/otherhooks';

export function SubscriptionModal() {
  const { user } = useRecoilValue(authAtom);
  const actPlan = (`${user?.info?.current_tier}_${user?.info?.subscription_duration || user?.info?.duration}`);
  const [subPlan, setSubPlan] = useState(actPlan || "");
  const [actSub, setActSub] = useState("Monthly");
  const subTier = subPlan?.split("_")?.[0];
  const actSubLow = actSub?.toLowerCase();
  const actSubLowRep = actSubLow?.replaceAll("ly", "");
  const [isPayOpen, setIsPayOpen] = useState(false);
  const [isSubOpen, setIsSubOpen] = useState(false);
  const onSubClose = () => setIsSubOpen(false);
  const onPayClose = () => setIsPayOpen(false);
  const onPayOpen = () => setIsPayOpen(true);

  const {
    data: getSubsData,
    isFetching: getSubsLoad,
  } = useGetSubs();

  const {
    isLoading: postSubLoad,
    mutateAsync: postSubAction,
  } = usePostSub();

  const {
    mutate: postVerifySubAction,
    isLoading: postVerifySubLoad,
  } = usePostVerifySub(onSubClose);

  const {
    data: postPayData,
    mutate: postPayAction,
    isLoading: postPayLoad,
  } = useStripePay(onPayOpen);

  const handleSubResponse = async ({
    paymentIntent,
  }: {
    paymentIntent: any;
  }) => {
    const payload = {
      amount: paymentIntent?.amount / 100,
      duration: actSubLowRep,
      tier: subTier,
    };
    await postSubAction(payload).then((res) =>
      postVerifySubAction({
        reference: paymentIntent?.id,
        token: res?.data,
      })
    );
    onPayClose();
  };

  const handleSubscribe = () => {
    const subscription = subscriptions?.find(({ key }) =>
      isEqual(key, subTier)
    );
    const payload = subscription?.[actSubLow as keyof typeof subscription];
    postPayAction(payload);
  };

  const subscriptions = useMemo(
    () => [
      {
        key: "premium",
        title: "Premium",
        yearly: getSubsData?.data?.premium_year?.price,
        monthly: getSubsData?.data?.premium_month?.price,
        features: [
          "Get access to all features",
          `${
            getSubsData?.data?.[`premium_${actSubLowRep}`]?.credits
          } credits per ${actSubLowRep}`,
        ],
      },
      {
        key: "pro",
        title: "Pro",
        yearly: getSubsData?.data?.pro_year?.price,
        monthly: getSubsData?.data?.pro_month?.price,
        features: ["Unlimited transcription time", "Unlimited credits"],
      },
    ],
    [actSubLowRep, getSubsData]
  );

  const subscriptionLoad = postPayLoad || postSubLoad || postVerifySubLoad;
  return (
    <Modal
      footer={false}
      open={isSubOpen}
      closeIcon={false}
      onCancel={onSubClose}
    >
      <Spin spinning={getSubsLoad}>
        {isPayOpen && postPayData ? (
          <SubscribeContent
            clientSecret={postPayData?.client_secret!}
            action={handleSubResponse}
          />
        ) : (
          <div className="w-full md:p-5 flex flex-col justify-center items-center text-center gap-5">
            <p className="text-2xl font-bold text-secondary">Subscription</p>
            <div className="w-full md:w-[50%] mx-auto rounded-3xl p-1 grid grid-cols-2 bg-[#F5F5F5]">
              {["Monthly", "Yearly"].map((d) => {
                const handleSub = () => setActSub(d);
                const act = isEqual(actSub, d);
                return (
                  <Button
                    className={`text-sm font-medium ${
                      act ? "bg-white text-primary" : "text-[#838382]"
                    }`}
                    type={act ? "primary" : "text"}
                    onClick={handleSub}
                    shape="round"
                    size="large"
                    block
                  >
                    {d}
                  </Button>
                );
              })}
            </div>
            <Radio.Group
              onChange={({ target: { value } }) => setSubPlan(value)}
              className="!w-full space-y-5"
              value={subPlan}
              size="large"
            >
              {subscriptions.map((d) => {
                const subLow = actSub?.toLowerCase();
                const value = `${d?.key}_${actSubLowRep}`;
                return (
                  <div className="w-full border border-[#E0E0E0] rounded-2xl py-3">
                    <div className="flex justify-between items-center px-5">
                      <p className="text-lg font-semibold text-[#1A2651]">
                        {d?.title}
                      </p>
                      <Radio value={value} />
                    </div>
                    <Divider className="!my-2" />
                    <div className="text-start space-y-3 px-5">
                      <p className="text-[32px] font-semibold text-primary">
                        ${d?.[subLow as keyof typeof d]}
                        <sub className="text-base font-medium text-[#646462]">
                          /{subLow}
                        </sub>
                      </p>
                      {d?.features?.map((f) => (
                        <Button
                          className="!w-full !truncate !p-0 !m-0 !bg-transparent !text-start"
                          icon={
                            <FaCheckCircle className="text-primary text-xl !bg-transparent" />
                          }
                          block
                          type="text"
                        >
                          {f}
                        </Button>
                      ))}
                    </div>
                  </div>
                );
              })}
            </Radio.Group>
            <Button
              className="w-[80%] !h-[50px] bg-primary"
              disabled={isEqual(subPlan, actPlan)}
              loading={subscriptionLoad}
              onClick={handleSubscribe}
              type="primary"
              shape="round"
              size="large"
            >
              Continue
            </Button>
            <p className="text-sm font-normal text-[#646462]">
              Upgrade your plan today and enjoy the best our platform has to
              offer.{" "}
              <Link
                to="https://www.nurovant.com/termofservice.html"
                target="_blank"
                className="text-primary"
              >
                Our terms of service
              </Link>
            </p>
          </div>
        )}
      </Spin>
    </Modal>
  )
}
