import { atom } from "recoil";

export const audRecAtom = atom<any>({
  key: "audio_record",
  default: {},
});

export const promptAtom = atom<any>({
  key: "prompt_atom",
  default: true,
});
