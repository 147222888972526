import { RecoilRoot } from "recoil";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import AxiosContextProvider from "./context/AxiosContext";
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from "./msalConfig";
import { ConfigProvider } from "antd";
import CustomRoutes from "./router";
import "./styles/input.css";

const msalInstance = new PublicClientApplication(msalConfig);

const queryClient = new QueryClient();
function App() {
  return (
    <RecoilRoot>
      <Router>
        <AxiosContextProvider>
          <QueryClientProvider client={queryClient}>
            <MsalProvider instance={msalInstance}>
              <ConfigProvider
                theme={{components: {
                  Button: { colorPrimary: "#4970fc" },
                  DatePicker: { colorPrimary: "#4970fc" }
                }}}
              >
                <CustomRoutes />
              </ConfigProvider>
            </MsalProvider>
          </QueryClientProvider>
        </AxiosContextProvider>
      </Router>
    </RecoilRoot>
  );
}

export default App;
