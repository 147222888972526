import { notification } from "antd";
import { useMutation, useQuery } from "react-query";
import {
  deleteRequest,
  getRequest,
  postRequest,
} from "../../context/requestTypes";
import { useContext } from "react";
import { AxiosContext } from "../../context/AxiosContext";
import { AxiosInstance } from "axios";
import { useSearchParams } from "react-router-dom";
import { analytics } from "../../firebaseAuth/config";
import { logEvent } from "firebase/analytics";
import { checkOddValue } from "../../context/utils";

export function useGetAllDiscuss(params?: any) {
  const url = "/api_backend/discussion/";
  const axios = useContext(AxiosContext);
  return useQuery(
    ["get:all_discussion"],
    () => getRequest(axios as unknown as AxiosInstance, url, params),
    {
      onError: (error: any) =>
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        }),
    }
  );
}

export function useGetDiscuss(
  id: string,
  successAction?: any,
  errorAction?: any
) {
  const url = "/api_backend/discussion/";
  const axios = useContext(AxiosContext);
  return useQuery(
    ["get:single_discussion", id],
    () => getRequest(axios as unknown as AxiosInstance, url + id),
    {
      onSuccess: () => successAction?.(),
      onError: (error: any) => {
        errorAction?.();
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        });
      },
    }
  );
}

export function useFetchSingleDiscuss(id: string, enabled?: boolean) {
  const url = "/api_backend/discussion/";
  const axios = useContext(AxiosContext);
  return useQuery(
    ["get:single_lecture_discussion", id],
    () => getRequest(axios as unknown as AxiosInstance, url + id),
    {
      enabled: Boolean(enabled && !checkOddValue(id)),
      onError: (error: any) => {
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        });
      },
    }
  );
}

export function usePostDiscuss(successAction?: any, errorAction?: any) {
  const url = "/api_backend/discussion/create";
  const axios = useContext(AxiosContext);
  return useMutation(
    async (payload: any) =>
      postRequest(axios as unknown as AxiosInstance, url, payload),
    {
      onSuccess: (response: any) => {
        successAction?.();
        notification.success({
          message: "Success!",
          description: response?.message || "action successful.",
        });
        logEvent(analytics, "create_discussion");
      },
      onError: (error: any) => {
        errorAction?.();
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        });
      },
    }
  );
}

export function usePostLectureDiscuss(successAction?: any, errorAction?: any) {
  const [params, setParams] = useSearchParams();
  const activeAction = params.get("action");

  const url = "/api_backend/discussion/create";
  const axios = useContext(AxiosContext);
  return useMutation(
    async (payload: any) =>
      postRequest(axios as unknown as AxiosInstance, url, payload),
    {
      onSuccess: (response: any) => {
        successAction?.(response);
        notification.success({
          message: "Success!",
          description: response?.message || "action successful.",
        });
        logEvent(analytics, "create_lecture_discussion");

        // setParams({ action: activeAction, id: response?.data?._id } as {
        //   action: string;
        //   id: string;
        // });
      },
      onError: (error: any) => {
        errorAction?.();
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        });
      },
    }
  );
}

export function usePostDiscussChat(successAction?: any, id?: string) {
  const url = "/api_backend/discussion/chat/";
  const axios = useContext(AxiosContext);
  return useMutation(
    async (payload: any) =>
      postRequest(
        axios as unknown as AxiosInstance,
        url + (id || payload?.id),
        payload
      ),
    {
      onSuccess: (response: any) => successAction?.(),
      onError: (error: any) =>
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        }),
    }
  );
}

export function usePostDiscussReact(
  id: string,
  successAction?: any,
  errorAction?: any
) {
  const url = "/api_backend/discussion/react/";
  const axios = useContext(AxiosContext);
  return useMutation(
    async (payload: any) =>
      postRequest(axios as unknown as AxiosInstance, url + id, payload),
    {
      onSuccess: (response: any) => {
        successAction?.();
        notification.success({
          message: "Success!",
          description: response?.message || "action successful.",
        });
      },
      onError: (error: any) => {
        errorAction?.();
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        });
      },
    }
  );
}

export function useDeleteDiscuss(successAction?: any) {
  const url = "/api_backend/discussion/";
  const axios = useContext(AxiosContext);
  return useMutation(
    async (id: any) =>
      deleteRequest(axios as unknown as AxiosInstance, url + id),
    {
      onSuccess: (response: any) => {
        successAction?.(response);
        notification.success({
          message: "Success!",
          description: response?.message || "action successful.",
        });
      },
      onError: (error: any) =>
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        }),
    }
  );
}
