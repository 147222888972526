import { Button, Modal } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { IoClose, IoCheckmarkSharp } from "react-icons/io5";
import { useSearchParams } from "react-router-dom";
import { useMatchFlashcards } from "../../hooks/flashcards/flashcards";
import ballonbird from "../../assets/ballonbird.svg";
type Props = {
  openSuccess: boolean;
  setOpenSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  right: number;
  wrong: number;
  is_matched: boolean;
  flashcardLength: number;
};

const MatchingFlashcardsSuccess = ({
  openSuccess,
  setOpenSuccess,
  right,
  wrong,
  is_matched,
  flashcardLength,
}: Props) => {
  const [params] = useSearchParams();
  const id = params.get("id");
  const totalPercentage = `${(right / flashcardLength) * 100}%`;

  const { mutate, isLoading } = useMatchFlashcards();
  const handleSubmit = () => {
    if (is_matched) {
      setOpenSuccess(false);
      return;
    }
    const payload = {
      flashcards_id: id,
      failed: wrong ? "true" : "false",
    };
    mutate(payload, {
      onSuccess: () => {
        setOpenSuccess(false);
      },
    });
  };
  return (
    <Modal
      open={openSuccess}
      onCancel={() => setOpenSuccess(false)}
      footer={false}
      closeIcon={false}
      className="w-full lg:max-w-[614px] max-h-[80vh] overflow-y-auto rounded-2xl"
      style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
    >
      <main>
        <section className="flex flex-col-reverse md:flex-row items-center gap-3">
          <aside className="">
            <h1 className="font-semibold text-[26px]">
              You did great! keep focusing and aim higher!{" "}
            </h1>
            <aside className="flex gap-4 items-center w-full">
              <p
                className={`p-4 rounded-full font-bold ${
                  totalPercentage > "50%"
                    ? "bg-[#58BE80]/40 text-[#58BE80]"
                    : "bg-[#FA6407]/40 text-[#FA6407]"
                }`}
              >
                {totalPercentage}
              </p>
              <div className="space-y-4 w-full">
                <div className="flex w-full justify-between">
                  <p className="text-[#58BE80]">Correct</p>
                  <p className="text-[#58BE80] bg-[#58BE80]/40 border border-[#58BE80] py-[2px] px-[9px] rounded-lg">
                    {right}
                  </p>
                </div>
                <div className="flex w-full justify-between">
                  <p className="text-[#FA6407]">Missed</p>
                  <p className="text-[#FA6407] bg-[#FA6407]/40 border border-[#FA6407] py-[2px] px-[9px] rounded-lg">
                    {wrong}
                  </p>
                </div>
              </div>
            </aside>
          </aside>
          <figure>
            <img
              src={ballonbird}
              alt=""
              className="w-[215px] h-[214px] object-contain"
            />
          </figure>
        </section>
        <Button
          onClick={handleSubmit}
          loading={isLoading}
          className="bg-primary py-4 flex !hover:text-white items-center justify-center text-white rounded-full w-full"
        >
          {is_matched ? "Close" : "Get reward"}
        </Button>
      </main>
    </Modal>
  );
};

export default MatchingFlashcardsSuccess;
