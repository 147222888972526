import { notification } from "antd";
import { useMutation, useQuery } from "react-query";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../context/requestTypes";
import { useContext } from "react";
import { AxiosContext } from "../../context/AxiosContext";
import { AxiosInstance } from "axios";
import { useSearchParams } from "react-router-dom";
import { analytics } from "../../firebaseAuth/config";
import { logEvent } from "firebase/analytics";
import { checkOddValue } from "../../context/utils";

export function useGetAllFlashcards(params?: any) {
  const url = "/api_backend/flashcards/";
  const axios = useContext(AxiosContext);
  return useQuery(
    ["get:all_flashcards"],
    () => getRequest(axios as unknown as AxiosInstance, url, params),
    {
      onError: (error: any) =>
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        }),
    }
  );
}

export function useGetFlashcard(id: string) {
  const url = "/api_backend/flashcards/";
  const axios = useContext(AxiosContext);

  return useQuery(
    ["get:single_flashcard", id],
    () => getRequest(axios as unknown as AxiosInstance, url + id),
    {
      onError: (error: any) => {
        if (error?.message?.includes("not exist")) {
        } else {
          notification.error({
            message: "Error!",
            description: error?.message
              ? Object.entries(error?.errors || { key: [error?.message] })
                  ?.map(([, value]) => (value as any)?.join(", "))
                  ?.join(", ")
              : "something went wrong please check internet connection.",
          });
        }
      },
      enabled: Boolean(id),
    }
  );
}

export function useFetchLectureFlashcard(id: string, enabled?: boolean) {
  const url = "/api_backend/flashcards/";
  const axios = useContext(AxiosContext);

  return useQuery(
    ["get:single_flashcard", id],
    () => getRequest(axios as unknown as AxiosInstance, url + id),
    {
      enabled: Boolean(enabled && !checkOddValue(id)),
      onError: (error: any) => {
        if (error?.message?.includes("not exist")) {
        } else {
          notification.error({
            message: "Error!",
            description: error?.message
              ? Object.entries(error?.errors || { key: [error?.message] })
                  ?.map(([, value]) => (value as any)?.join(", "))
                  ?.join(", ")
              : "something went wrong please check internet connection.",
          });
        }
      },
    }
  );
}

export function usePostFlashcards(successAction?: any, errorAction?: any) {
  const [params, setParams] = useSearchParams();
  const activeAction = params.get("action");

  const url = "/api_backend/flashcards/create";
  const axios = useContext(AxiosContext);
  return useMutation(
    async (payload: any) =>
      postRequest(axios as unknown as AxiosInstance, url, payload),
    {
      onSuccess: (response: any) => {
        successAction?.(response);
        notification.success({
          message: "Success!",
          description: response?.message || "action successful.",
        });
        logEvent(analytics, "create_flashcard");
        // setParams({ action: activeAction, id: response?.data?._id } as {
        //   action: string;
        //   id: string;
        // });
      },
      onError: (error: any) => {
        errorAction?.();
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        });
      },
    }
  );
}

export function useEditFlashcards(id: string) {
  const url = `/api_backend/flashcards/${id}`;
  const axios = useContext(AxiosContext);

  return useMutation(
    async (payload: any) => putRequest(axios as unknown as AxiosInstance, url, payload),
    {
      onSuccess: (response: any) => {
        notification.success({
          message: "Success!",
          description: response?.message || "action successful.",
        });
        logEvent(analytics, "flashcard_edited");
      },
      onError: (error: any) => {
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        });
      },
    }
  );
}

export function usePostFlashcardImage(id: string, successAction?: any) {
  const url = `/api_backend/flashcards/flash_images/${id}`;
  const axios = useContext(AxiosContext);

  return useMutation(
    async (payload: any) => postRequest(axios as unknown as AxiosInstance, url, payload),
    {
      onSuccess: (response: any) => {
        notification.success({
          message: "Success!",
          description: response?.message || "action successful.",
        });
        logEvent(analytics, "flashcard_visual_aid");
        successAction?.(response)
      },
      onError: (error: any) => {
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        });
      },
    }
  );
}

export function useMemorizeFlashcards(id: string, successAction?: any) {
  const url = `/api_backend/flashcards/${id}`;
  const axios = useContext(AxiosContext);

  return useMutation(
    async (payload: any) => {
      putRequest(axios as unknown as AxiosInstance, url, payload);
    },
    {
      onSuccess: (response: any) => {
        notification.success({
          message: "Success!",
          description: response?.message || "action successful.",
        });
        logEvent(analytics, "flashcard_memorized");
        successAction?.(response)
      },
      onError: (error: any) => {
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        });
      },
    }
  );
}

export function useAddFlashcards(id: string) {
  const url = `/api_backend/flashcards/add_card/${id}`;
  const axios = useContext(AxiosContext);

  return useMutation(
    async (payload: any) => {
      putRequest(axios as unknown as AxiosInstance, url, payload);
    },
    {
      onSuccess: (response: any) => {
        notification.success({
          message: "Success!",
          description: response?.message || "action successful.",
        });
        logEvent(analytics, "flashcard_memorized");
      },
      onError: (error: any) => {
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        });
      },
    }
  );
}
export function useShare(id: string, type: string) {
  const url = `/api_backend/${type}/share/${id}`;
  const axios = useContext(AxiosContext);

  return useMutation(
    async (payload: any) => {
      postRequest(axios as unknown as AxiosInstance, url, payload);
    },
    {
      onSuccess: (response: any) => {
        // console.log("response", response);
        notification.success({
          message: "Success!",
          description: response?.message || "action successful.",
        });
      },
      onError: (error: any) => {
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        });
      },
    }
  );
}

export function useMatchFlashcards() {
  const url = "/api_backend/user/add_points";
  const axios = useContext(AxiosContext);
  return useMutation(
    async (payload: any) =>
      postRequest(axios as unknown as AxiosInstance, url, payload),
    {
      onSuccess: (response: any) => {
        // successAction?.(response);
        // logEvent(analytics, "feedback");
        notification.success({
          message: "Success!",
          description: response?.message || "action successful.",
        });
      },
      onError: (error: any) =>
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        }),
    }
  );
}

export function useDeleteFlashcard(successAction?: any, errorAction?: any) {
  const axios = useContext(AxiosContext);
  const url = "/api_backend/flashcards/";
  return useMutation(
    async (id: any) =>
      deleteRequest(axios as unknown as AxiosInstance, url + id),
    {
      onSuccess: (response: any) => {
        successAction?.(response);
        notification.success({
          message: "Success!",
          description: response?.message || "action successful.",
        });
      },
      onError: (error: any) => {
        errorAction?.();
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        });
      },
    }
  );
}
