import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import { SlackUserToken } from "../../types";

const { persistAtom } = recoilPersist({
  key: "slackAuthentication",
  storage: localStorage,
});

export const slackAuthAtom = atom({
  key: "slackAuthKey",
  default: {
    slackUser: {},
  },
  effects_UNSTABLE: [persistAtom],
});
