import { Button, Image, Modal, Form, Input } from "antd";
import { useState } from "react";
import { usePostLecturerRating } from "../../hooks/otherhooks";
import LecturerRating from "../../assets/lecturerRating.png";

type feedType = "main" | "success";

type Props = {
  showModal: boolean;
  featureId: string | null;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  feature: string;
};

export const RateLecturerModal = ({
  showModal,
  featureId,
  setShowModal,
  feature,
}: Props) => {
  const [feedType, setFeedType] = useState<feedType>("main");
  const [feedBack, setFeedBack] = useState("");

  const successAction = () => {
    setFeedType("success");
  };

  const [rating, setRating] = useState(0); // State to store the selected rating

  // Function to handle click on a star
  const handleStarClick = (index: number) => {
    setRating(index + 1); // Update the rating state to the index of the clicked star + 1
  };

  const { mutate, isLoading } = usePostLecturerRating(successAction);

  const onFinish = (values: any) => {
    mutate({
      ...values,
      star_rating: rating.toString(),
      feature_id: featureId,
      feature,
      message: feedBack,
    });
  };

  return (
    <Modal
      onCancel={() => {
        setShowModal(false);
      }}
      open={showModal}
      closeIcon={false}
      footer={false}
      styles={{
        content: {
          background: feedType === "success" ? "none" : "#fff",
          padding: feedType === "success" ? 0 : "16px",
          boxShadow: "none",
        },
      }}
    >
      {feedType === "main" && (
        <div
          className="w-full h-full flex flex-col gap-6 items-center"
          onClick={() => {}}
        >
          <div className="flex flex-col gap-2 w-full items-center">
            <h1 className="text-[26px] leading-[31.69px] font-bold text-center text-[#1b1b1b]">
              Rate Lecturer
            </h1>
            <p className="text-[18px] leading-[25.2px] font-semibold text-[#676767] text-center">
              We hope you leave a positive feedback
            </p>
          </div>

          <div className="flex items-center flex-col justify-center gap-4">
            {/* <div
              className="flex flex-col items-center gap-1 cursor-pointer"
              onClick={() => {
                setIsLiked(true);
                mutate({
                  feature,
                  feature_id: featureId,
                  is_liked: "true",
                  selected_feedback:
                    selectedFeedBack.length > 0
                      ? selectedFeedBack
                      : "Good content",
                  feedback:
                    feedBack.length > 0 ? feedBack : `The ${feature} was good`,
                });
              }}
            >
              <Image alt="Satisfied emoji" src={Satisfied} preview={false} />
              <span className="text-[12px] leading-[18px] text-center">
                Satisfied
              </span>
            </div> */}
            <div className="flex items-center justify-between gap-2">
              {new Array(5).fill(null).map((_, index) => (
                <div
                  key={index}
                  className="cursor-pointer"
                  onClick={() => {
                    handleStarClick(index);
                    console.log("clicked");
                  }}
                >
                  {/* Display a filled star if the index is less than the rating, otherwise, display an empty star */}
                  <svg
                    width="72"
                    height="73"
                    viewBox="0 0 72 73"
                    fill={index < rating ? "#4970fc" : "#D1D1D1"}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M35.999 52.1765L48.449 59.7065C50.729 61.0865 53.519 59.0465 52.919 56.4665L49.619 42.3065L60.629 32.7665C62.639 31.0265 61.559 27.7265 58.919 27.5165L44.429 26.2865L38.759 12.9065C37.739 10.4765 34.259 10.4765 33.239 12.9065L27.569 26.2565L13.079 27.4865C10.439 27.6965 9.359 30.9965 11.369 32.7365L22.379 42.2765L19.079 56.4365C18.479 59.0165 21.269 61.0565 23.549 59.6765L35.999 52.1765Z"
                      fill={index < rating ? "#4970fc" : "#D1D1D1"}
                    />
                  </svg>
                </div>
              ))}
            </div>

            <Form
              layout="vertical"
              onFinish={onFinish}
              className="py-5 w-full flex flex-col gap-4"
              initialValues={{ lecturer_name: "", school: "" }}
            >
              <div className="relative">
                <label
                  htmlFor="lecturer_name"
                  className="absolute z-10 -top-5 left-3 bg-dark !text-xs font-semibold text-white px-2 py-1 border-2 border-dark rounded-3xl"
                >
                  Lecturer Name
                </label>
                <Form.Item name="lecturer_name" className="!relative">
                  <Input
                    className="px-5 py-3 placeholder:text-dark"
                    placeholder="Enter your Lecturer name"
                    size="large"
                    type="text"
                    id="lecturer_name"
                    required
                  />
                </Form.Item>
              </div>

              <div className="relative">
                <label
                  htmlFor="school"
                  className="absolute z-10 -top-5 left-3 bg-dark !text-xs font-semibold text-white px-2 py-1 border-2 border-dark rounded-3xl"
                >
                  School
                </label>
                <Form.Item name="school" className="!relative">
                  <Input
                    className="px-5 py-3 placeholder:text-dark"
                    placeholder="Enter school name"
                    size="large"
                    type="text"
                    id="school"
                    required
                  />
                </Form.Item>
              </div>
              <div className="w-full">
                <textarea
                  value={feedBack}
                  placeholder="Send us a message"
                  onChange={(e: any) => setFeedBack(e.target.value)}
                  className="h-[128px] px-[18px] py-[24px] rounded-[8px] bg-[#f5f5f5] w-full text-[#1b1b1b] focus:ring-0 border-none outline-none"
                />
              </div>
              <Button
                className="bg-primary !h-[50px]"
                loading={isLoading}
                htmlType="submit"
                type="primary"
                shape="round"
                size="large"
                block
              >
                Submit
              </Button>
            </Form>
          </div>
        </div>
      )}

      {feedType === "success" && (
        <div
          className="w-full h-full cursor-pointer"
          onClick={() => setShowModal(false)}
        >
          <Image
            alt="success"
            src={LecturerRating}
            preview={false}
            className="!w-full"
          />
        </div>
      )}
    </Modal>
  );
};
