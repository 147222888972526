import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useSummaryWiki } from "../../hooks/otherhooks";
import { VscLoading } from "react-icons/vsc";

type Props = {
  openSuccess: boolean;
  setOpenSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  query: string;
};

const SummaryModal = ({ query, openSuccess, setOpenSuccess }: Props) => {
  const [showBadge, setShowBadge] = useState(true);
  const { isLoading, data, refetch } = useSummaryWiki(query);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowBadge(false);
    }, 5000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Modal
      open={openSuccess}
      onCancel={() => setOpenSuccess(false)}
      footer={false}
      closeIcon={false}
      className="w-full lg:max-w-[614px]"
      style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
    >
      <main className="pt-8">
        {showBadge && (
          <p className="text-center bg-primary p-3 text-white mb-5">
            You can only explain a word, not multiple words
          </p>
        )}
        <section className="w-full flex items-center gap-2 justify-between">
          <h1 className="font-semibold text-[24px]">{query}</h1>
          <Button
            className="bg-primary text-white rounded-full"
            onClick={() => refetch()}
            loading={isLoading}
          >
            Explain
          </Button>
        </section>

        {data && (
          <section>
            <aside>
              {data?.originalimage?.source && (
                <figure>
                  <img
                    src={data?.originalimage?.source}
                    alt=""
                    className="w-[300px] h-[300px] object-contain rounded-md"
                  />
                </figure>
              )}
              <p>{data?.extract}</p>
              <p className="mt-2">
                For more information, visit{" "}
                <a
                  target="__blank"
                  href={data?.content_urls?.desktop?.page}
                  className="text-primary"
                >
                  {data?.content_urls?.desktop?.page}
                </a>
              </p>
            </aside>
          </section>
        )}
      </main>
    </Modal>
  );
};

export default SummaryModal;
