// src/msalConfig.js

// const isTestEnv = !["app.nurovant.com"].includes(window.location.host)

const msalConfig: any = {
    auth: {
        clientId: process.env.REACT_APP_STUDY_SESSION_MS_CLIENT_ID, // Your app's client ID
        // clientId: "eaec8e5c-a7f1-4764-bc72-55faf4ccd995", // Your app's client ID
        clientSecret: process.env.REACT_APP_STUDY_SESSION_MS_CLIENT_SECRET,
        // clientSecret: 'h6x8Q~EkkTxFSxzGc2pDih5hLGfTo~~iy8UK6blY',
        authority: "https://login.microsoftonline.com/".concat(process.env.REACT_APP_STUDY_SESSION_MS_TENANT_ID!), // Your tenant ID
        // authority: `https://login.microsoftonline.com/consumers/oauth2/v2.0/token`, // Your tenant ID
        redirectUri: window.location.href, // Your redirect URI
        // redirectUri: "http://localhost:5173", // Your redirect URI
    },
    cache: {
        cacheLocation: "localStorage", // Choose where to store the token
        storeAuthStateInCookie: false, // Set to true for IE 11 or Edge
    },
};

const loginRequest = {
    scopes: ["User.Read", "Calendars.ReadWrite", "OnlineMeetings.ReadWrite"], // Define your scopes
};

export { msalConfig, loginRequest };
