/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Divider,
  Image,
  Input,
  Modal,
  Radio,
  Space,
  Spin,
  Tag,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { MdCancel } from "react-icons/md";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetQuiz, usePostQuizAnswer } from "../../../../hooks/quiz/quiz";
import { isEqual } from "../../../../context/utils";
import { useRecoilValue } from "recoil";
import authAtom from "../../../../atoms/auth/auth.atom";
import icon from "../../../../assets/icons/icon.png";
import { FeedbackModal } from "../../../../components/modals/FeedbackModal";
import DropDownFeedComponent from "../../../../components/DropDownFeedComponent";
import { useDropDownContent } from "../../../../hooks/otherhooks";
import { RateLecturerModal } from "../../../../components/modals/RateLecturerModal";
import ShareToUsersModal from "../../../../components/modals/ShareToUsersModal";

type Props = {};
function QuizQuestionsSection({}: Props) {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const [questions, setQuestions] = useState<any>([]);
  const [answeredQuestions, setAnsweredQuestions] = useState<any>([]);
  const [feedBack, setFeedBack] = useState<any>([]);
  const [activeQuest, setActiveQuest] = useState(0);
  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);
  const contentId = params.get("content");
  const materialId = params.get("id");
  const id = contentId || materialId;

  const [showFeedBack, setShowFeedBack] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [showRateLecturer, setShowRateLecturer] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const { dropDownOptions } = useDropDownContent({
    setShowFeedBack,
    setShowDropDown,
    canRateLecturer: true,
    setShowRateLecturer,
    shareToUser: () => shareToUser(),
    type: "quiz",
  });

  const { user } = useRecoilValue(authAtom);
  const resetQuest = () => setActiveQuest(0);
  const goBack = () => navigate("/");

  const successAction = () => {
    resetQuest();
    onOpen();
  };

  const { data: getQuizData, isFetching: getQuizLoad } = useGetQuiz(id!);

  const shareToUser = () => {
    setOpenShare(true);
  };
  const {
    data: postAnsData,
    mutate: postAnsAction,
    isLoading: postAnsLoad,
  } = usePostQuizAnswer(successAction);

  useEffect(() => {
    // Update state when getQuizData changes
    setQuestions([
      ...(getQuizData?.data?.questions || []).map((d: any) => ({
        ...d,
        mcq: true,
      })),
      ...(getQuizData?.data?.oeq || []).map((d: any) => ({ ...d, oeq: true })),
    ]);
  }, [getQuizData]);

  useMemo(() => {
    if (postAnsData === undefined) return;
    setAnsweredQuestions(
      [
        ...(postAnsData?.data?.result || [])?.map((d: any) => ({
          answered: true,
          ...d,
        })),
      ] || answeredQuestions
    );
    setQuestions([
      ...(getQuizData?.data?.questions || []).map((d: any) => ({
        ...d,
        mcq: true,
        answered: true,
      })),
      ...(getQuizData?.data?.oeq || []).map((d: any) => ({
        ...d,
        oeq: true,
        answered: true,
      })),
    ]);
    setFeedBack(postAnsData?.quiz_feedback || []);
  }, [postAnsData]);

  const CurrentQuest: any = useMemo(
    () => questions?.[activeQuest],
    [activeQuest, questions]
  );
  const CurrentAnsweredQuest: any = useMemo(
    () => answeredQuestions?.[activeQuest],
    [activeQuest, answeredQuestions]
  );

  const CurrentFeedback: any = useMemo(
    () => feedBack?.[activeQuest],
    [activeQuest, feedBack]
  );

  const handleAnswer = (res: any) => {
    setQuestions(
      questions?.map((d: any, idx: number) => ({
        ...d,
        response: isEqual(idx, activeQuest) ? res || "" : d?.response || "",
      }))
    );
  };

  const handleSubmit = () => {
    Modal.destroyAll();
    const payload = {
      mcqsubmission: questions
        ?.filter((d: any) => d?.mcq)
        ?.map((d: any) => ({ question: d?.question, answer: d?.response })),
      oeqsubmission: questions
        ?.filter((d: any) => d?.oeq)
        ?.map((d: any) => ({ question: d?.question, answer: d?.response })),
      quiz_id: getQuizData?.data?._id,
      user_name: user?.info?.name,
      user_id: user?.info?._id,
      duration_used: "120",
    };
    postAnsAction(payload);
  };

  const handleSessionEnd = () => {
    if (CurrentQuest?.answered) return goBack();
    Modal.confirm({
      footer: false,
      title: false,
      icon: <></>,
      content: (
        <div className="w-full pl-7 flex flex-col justify-between items-center gap-5 text-center">
          <Image preview={false} src={icon} />
          <p className="text-3xl font-semibold text-dark">End Session</p>
          <p className="text-base font-medium text-[#646462]">
            Are you sure you want to end this session
          </p>
          <Button
            className="!font-bold"
            onClick={handleSubmit}
            loading={postAnsLoad}
            danger
          >
            End Session
          </Button>
          <Button onClick={Modal.destroyAll} type="text">
            Cancel
          </Button>
        </div>
      ),
    });
  };

  const handleResult = (data?: any) => {
    const len = (data || answeredQuestions)?.length;
    const res = (data || answeredQuestions)?.filter(
      (d: any) => d?.status
    )?.length;
    const percentage = Number(((res / len) * 100).toFixed() || 0);
    const pass = percentage >= 50;
    const title = pass ? "Well Done!" : "More Studying Required!";
    const context = pass
      ? "Nuro is proud of you (´•‿•`)"
      : "Better Score Next Time (´•╭╮•`)";
    const score = `${res}/${len}`;
    return { score, percentage, context, pass, title };
  };
  return (
    <Spin spinning={getQuizLoad}>
      <div className="w-full min-h-full flex flex-col justify-between items-center md:py-5 space-y-5">
        <div className="w-full flex items-center gap-2 justify-between md:px-5">
          <div className="w-[90%] flex items-center md:px-10 gap-5">
            <MdCancel className="cursor-pointer text-3xl" onClick={goBack} />
            <div className="w-full pb-1 flex flex-nowrap items-center gap-3 overflow-x-auto">
              {questions?.map((d: any, idx: number) => (
                <Button
                  type="primary"
                  onClick={() => setActiveQuest(idx)}
                  className={`!w-[150px] !h-[13px] ${
                    isEqual(idx, activeQuest) ? "bg-primary" : "bg-fint"
                  }`}
                />
              ))}
            </div>
          </div>

          <div className="w-[24px]">
            <DropDownFeedComponent
              dropDownOptions={dropDownOptions}
              setShowDropDown={setShowDropDown}
              showDropDown={showDropDown}
            />
          </div>
        </div>

        <div className="w-full flex flex-col justify-center items-center gap-10">
          <div className="w-full md:w-[50%] grid grid-cols-1  gap-5 mx-auto border border-[#f7f6f8] rounded-lg shadow-xl px-5 py-10">
            <div className="space-y-5">
              <div className="flex items-center justify-center">
                <span className="px-4 py-2 border border-[#F2F2FA] rounded-3xl text-[12px] leading-[16px] text-center text-[#353333] mx-auto">
                  {activeQuest + 1}/{questions?.length}
                </span>
              </div>
              <p className="text-base text-dark font-medium text-center">
                {CurrentQuest?.question}
              </p>
            </div>
            {CurrentQuest?.answered ? (
              <div className="space-y-3">
                <p className={`${CurrentQuest?.mcq && "hidden"}`}>
                  Correct Answer: {CurrentAnsweredQuest?.answer}
                </p>
                <p className={`${CurrentQuest?.mcq && "hidden"}`}>
                  Your Answer: {CurrentAnsweredQuest?.user_answer}
                </p>
                <p className={`${CurrentQuest?.mcq && "hidden"}`}>
                  Status:{" "}
                  <Tag
                    color={CurrentAnsweredQuest?.status ? "success" : "error"}
                  >
                    {CurrentAnsweredQuest?.status ? "Correct" : "Incorrect"}
                  </Tag>
                </p>

                {CurrentQuest?.mcq && (
                  <Radio.Group
                    // onChange={(e) => handleAnswer(e?.target?.value)}
                    value={CurrentQuest?.response}
                  >
                    <Space direction="vertical" className="space-y-3">
                      {CurrentQuest?.options?.map((d: any) => (
                        <Radio
                          value={d}
                          className={`!text-sm !font-medium ${
                            CurrentAnsweredQuest?.status
                              ? CurrentAnsweredQuest.user_answer === d
                                ? "!text-green-500"
                                : ""
                              : CurrentAnsweredQuest?.answer === d &&
                                CurrentAnsweredQuest?.user_answer !== ""
                              ? "!text-green-500"
                              : CurrentAnsweredQuest?.answer === d
                              ? "!text-red"
                              : CurrentAnsweredQuest?.user_answer === d
                              ? "!text-red"
                              : ""
                          }`}
                        >
                          {d}
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                )}
              </div>
            ) : CurrentQuest?.mcq ? (
              <Radio.Group
                onChange={(e) => handleAnswer(e?.target?.value)}
                value={CurrentQuest?.response}
              >
                <Space direction="vertical">
                  {CurrentQuest?.options?.map((d: any) => (
                    <Radio value={d}>{d}</Radio>
                  ))}
                </Space>
              </Radio.Group>
            ) : (
              <Input.TextArea
                onChange={(e) => handleAnswer(e?.target?.value)}
                placeholder="Enter your answer"
                value={CurrentQuest?.response}
                rows={5}
              />
            )}
            <div
              className={`py-2 px-4 bg-[#DBE2FE] text-[14px] leading-[20px] font-medium text-[#1B1B1B] ${
                CurrentQuest?.answered ? "" : "hidden"
              }`}
            >
              {CurrentFeedback}
            </div>
          </div>
          <Button
            className="!font-bold"
            loading={postAnsLoad}
            type="text"
            danger
            onClick={handleSessionEnd}
          >
            End Session
          </Button>
        </div>

        <div className="w-full">
          <Divider />
          <div className="flex gap-10 justify-center items-center">
            <Button
              className="border-primary text-primary rounded-xl"
              onClick={() => setActiveQuest(activeQuest - 1)}
              disabled={!activeQuest}
              size="large"
            >
              Prev
            </Button>
            <Button
              className="border-primary text-primary rounded-xl"
              disabled={isEqual(activeQuest + 1, questions?.length)}
              onClick={() => setActiveQuest(activeQuest + 1)}
              size="large"
            >
              Next
            </Button>
          </div>
        </div>

        {/* Result display modal >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
        <Modal
          open={isOpen}
          footer={false}
          onCancel={onClose}
          maskClosable={false}
        >
          <div className="w-full flex flex-col justify-between items-center gap-5 text-center">
            <Image preview={false} src={icon} />
            <p className="text-3xl font-semibold text-dark">
              {handleResult()?.title}
            </p>
            <p className="text-xl font-semibold text-dark">
              Score: <b className="text-primary">{handleResult()?.score}</b>
            </p>
            <p className="text-xl font-semibold text-dark">
              Percentage:{" "}
              <b className="text-primary">{handleResult()?.percentage}%</b>
            </p>
            <p className="text-base font-medium text-[#646462]">
              {handleResult()?.context}
            </p>
            <Button className="!font-bold" onClick={onClose}>
              Review Answers.
            </Button>
          </div>
        </Modal>

        <FeedbackModal
          showModal={showFeedBack}
          setShowModal={setShowFeedBack}
          featureId={id}
          feature="quiz"
        />
        <RateLecturerModal
          showModal={showRateLecturer}
          setShowModal={setShowRateLecturer}
          featureId={id}
          feature="quiz"
        />
        <ShareToUsersModal
          open={openShare}
          setOpenShare={setOpenShare}
          type="quiz"
        />
      </div>
    </Spin>
  );
}

export default QuizQuestionsSection;
