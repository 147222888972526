import AWS from "aws-sdk";
import Stripe from "stripe";
import { message, notification } from "antd";
import { useMutation, useQuery } from "react-query";
import { removeSpacesFromPdfName } from "../pages/main/home/conflict-index";
import { useContext, useEffect, useState } from "react";
import { AxiosContext } from "../context/AxiosContext";
import { getRequest, otherRequest, postRequest } from "../context/requestTypes";
import axios, { AxiosInstance } from "axios";
import createLecturesAtom from "../atoms/modal/createLectures.atom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import ExportPDF from "../assets/export.svg";
import customizeIcon from "../assets/customizeIcon.svg";
import FeedbackIcon from "../assets/feedbackIcon.svg";
import { analytics } from "../firebaseAuth/config";
import { logEvent } from "firebase/analytics";
import { useLocation } from "react-router";
import authAtom from "../atoms/auth/auth.atom";
import { useEditRecap } from "./recap/recap";

export const fileTypes = (key: string, idx?: number) => {
  const ext = key?.split("/")?.[idx || 1] || key;
  const types = {
    pdf: "doc",
    text: "text",
    wav: "audio",
    mp3: "audio",
    mp4: "audio",
    png: "image",
    jpg: "image",
    svg: "image",
    plain: "doc",
    jpeg: "image",
    mpeg: "audio",
    webm: "audio",
    "vnd.openxmlformats-officedocument.wordprocessingml.document": "doc",
    "vnd.openxmlformats-officedocument.presentationml.presentation": "doc",
  };
  return types?.[ext as keyof typeof types] || ext || key;
};
export function useAWSUpload(
  successAction?: any,
  errorAction?: any,
  type?: "profile" | "content"
) {
  // Upload bucket type object
  const buckets = {
    content: process.env["REACT_APP_S3_BUCKET"]!,
    profile: process.env["REACT_APP_S3_BUCKET_PROFILE"]!,
  };

  const setLectureState = useSetRecoilState(createLecturesAtom);
  const { user } = useRecoilValue(authAtom);

  // Configure AWS with your credentials
  AWS.config.update({
    accessKeyId: process.env["REACT_APP_AWS_ACCESS_KEY_ID"],
    secretAccessKey: process.env["REACT_APP_AWS_SECRET_ACCESS_KEY"],
    region: process.env["REACT_APP_AWS_REGION"],
  });
  // Create an S3 service object
  const s3 = new AWS.S3();
  const reader = new FileReader();
  return useMutation(
    async (payload: any) => {
      reader.onload = async (e: any) => {
        // Create a Blob from the loaded data
        const fileBlob = new Blob([e.target.result], { type: payload?.type });
        // Do something with the Blob, such as sending it to a server or processing it
        // Specify the bucket and key (object key) for the upload
        const uploadParams = {
          Key: `${new Date()
            .toLocaleTimeString([], { hour12: false })
            .split(":")
            .join("_")}-${user?.info?._id}--${removeSpacesFromPdfName(
            payload.name
          )}`, // You can customize the key based on your requirement
          Bucket: buckets?.[(type || "content") as keyof typeof buckets],
          Body: fileBlob as unknown as Body,
          ContentType: payload?.type,
        };
        // Upload the file
        // await s3.upload(
        //   uploadParams,
        //   (
        //     err: Error | null,
        //     data: AWS.S3.ManagedUpload.SendData | undefined
        //   ) => {
        //     if (err)
        //       return () => {
        //         message.error("File upload failed!");
        //         errorAction?.();
        //       };
        //     successAction?.(data);
        //   }
        // );

        return new Promise((resolve, reject) => {
          const upload = s3.upload(uploadParams);

          // Track progress of the chunk upload
          upload.on("httpUploadProgress", (progress) => {
            const uploadedBytes = progress.loaded;
            const totalBytes = progress.total;
            const percentProgress = Math.round(
              (uploadedBytes / totalBytes) * 100
            );

            // console.log(`Uploaded ${percentProgress}%`);
            setLectureState((prev) => ({
              ...prev,
              progressBar: percentProgress,
            }));
          });

          upload.send((err, data) => {
            if (err) {
              reject(err);
              message.error("File upload failed!");
              console.error(err);
              errorAction?.();
            } else {
              resolve(data);
              successAction?.(data);
            }
          });
        });
      };

      // Read the content of the file as a data URL
      await reader.readAsArrayBuffer(payload);
    },
    {
      // onError: (err: any) => notification.error({
      //   description: err?.message || "File upload failed.",
      //   message: "Error!",
      // })
    }
  );
}

export function useStripePay(successAction?: any) {
  const stripe = new Stripe(process.env.REACT_APP_STRIPE_PRIVATE_KEY!);
  return useMutation(
    async (amount: any) =>
      stripe.paymentIntents.create({
        currency: "usd",
        amount: amount * 100,
        payment_method_types: ["card"],
      }),
    {
      onSuccess: (response: any) => {
        successAction?.(response);
        // notification.success({
        //   message: "Success!",
        //   description: response?.message || "action successful.",
        // });
      },
      onError: (error: any) =>
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        }),
    }
  );
}

export function usePostFeedback(successAction?: any) {
  const url = "/api_backend/user_response/create";
  const axios = useContext(AxiosContext);
  return useMutation(
    async (payload: any) =>
      postRequest(axios as unknown as AxiosInstance, url, payload),
    {
      onSuccess: (response: any) => {
        successAction?.(response);
        logEvent(analytics, "feedback");
        notification.success({
          message: "Success!",
          description: response?.message || "action successful.",
        });
      },
      onError: (error: any) =>
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        }),
    }
  );
}

export function usePostLecturerRating(successAction?: any) {
  const url = "/api_backend/rate_lecturer/create";
  const axios = useContext(AxiosContext);
  return useMutation(
    async (payload: any) =>
      postRequest(axios as unknown as AxiosInstance, url, payload),
    {
      onSuccess: (response: any) => {
        successAction?.(response);
        logEvent(analytics, "lecturer_rating");
        notification.success({
          message: "Success!",
          description: response?.message || "action successful.",
        });
      },
      onError: (error: any) =>
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        }),
    }
  );
}
export function useSummaryWiki(query: string) {
  const url = `https://en.wikipedia.org/api_backend/rest_v1/page/summary/${encodeURIComponent(
    query
  )}`;
  return useQuery(
    ["get:wiki_summary"],
    async () => {
      const response = await axios.get(url, {
        headers: {
          "Api-User-Agent":
            "NurovantAI/1.0 support@nurovant.com axios/React/18.2.0",
        },
      });

      return response.data;
    },
    {
      onError: (error: any) =>
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        }),

      enabled: false,
    }
  );
}

export function useDropDownContent({
  exportToPDF,
  exportToPDFFunc,
  setShowFeedBack,
  setShowDropDown,
  customizeFunc,
  canCustomize,
  canRateLecturer,
  setShowRateLecturer,
  shareToUser,
  type,
}: {
  exportToPDF?: boolean;
  canCustomize?: boolean;
  shareToUser: () => void;
  canRateLecturer?: boolean;
  customizeFunc?: () => void;
  exportToPDFFunc?: () => void;
  setShowFeedBack: React.Dispatch<React.SetStateAction<boolean>>;
  setShowDropDown: React.Dispatch<React.SetStateAction<boolean>>;
  setShowRateLecturer?: React.Dispatch<React.SetStateAction<boolean>>;
  type?: string;
}) {
  const [dropDownOptions, setDropDownOptions] = useState([
    {
      title: "Export PDF",
      icon: ExportPDF,
      action: () => {
        exportToPDFFunc && exportToPDFFunc();
      },
    },
    {
      title: `Share ${type}`,
      icon: ExportPDF,
      action: () => {
        shareToUser();
      },
    },
    {
      title: "Customize",
      icon: customizeIcon,
      action: () => {
        customizeFunc && customizeFunc();
      },
    },
    {
      title: "Give Feedback",
      icon: FeedbackIcon,
      action: () => {
        setShowFeedBack(true);
        setShowDropDown(false);
      },
    },
    {
      title: "Rate Lecturer",
      icon: FeedbackIcon,
      action: () => {
        setShowRateLecturer && setShowRateLecturer(true);
        setShowDropDown(false);
      },
    },
  ]);

  useEffect(() => {
    if (!exportToPDF) {
      setDropDownOptions((prev) =>
        prev.filter((option) => option.title !== "Export PDF")
      );
    }

    if (!canCustomize) {
      setDropDownOptions((prev) =>
        prev.filter((option) => option.title !== "Customize")
      );
    }

    if (!canRateLecturer) {
      setDropDownOptions((prev) =>
        prev.filter((option) => option.title !== "Rate Lecturer")
      );
    }
  }, [exportToPDF, canCustomize]);

  return {
    dropDownOptions,
  };
}

export function useShareNew(url: string) {
  // const url = "/login";
  const axios = useContext(AxiosContext);

  return useMutation(
    (payload: any) =>
      postRequest(axios as unknown as AxiosInstance, url, payload),
    {
      onSuccess: (response: any) => {
        notification.success({
          message: "Success!",
          description: response?.message || "welcome back.",
        });
      },
      onError: (error: any) =>
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        }),
    }
  );
}

const usePageViews = (): void => {
  const location = useLocation();

  useEffect(() => {
    logPageView(location.pathname);
  }, [location.pathname]);

  const logPageView = (pathname: string): void => {
    // Define mapping between routes and events
    const eventMap: Record<string, string> = {
      "/": "Lectures_page",
      "/course": "Courses_page",
      "/flashcard": "Flashcards_page",
      "/quiz": "Quiz_page",
      "/recap": "Recaps_page",
      "/discuss": "Discuss_page",
      "/study-guide": "StudyGuide_page",
      "/auth": "Register_page",
      "/auth/login": "Login_page",
      "/auth/forgot": "ForgotPassword_page",
      "/auth/reset": "ResetPassword_page",
      "/auth/type": "AuthType_page",
      "/auth/password": "Password_page",
      // Add more routes and corresponding events as needed
    };

    const event = eventMap[pathname];

    if (event) {
      // Log event to Firebase Analytics
      logEvent(analytics, event);
    }
  };
};

export function useGetYoutubeVideo(searchItem: string, successAction?: any) {
  const url = `https://www.googleapis.com/youtube/v3/search?key=${
    process.env["REACT_APP_YOUTUBE_API_KEY"]
  }&q=${encodeURIComponent(searchItem)}&type=video&part=snippet&maxResults=1`;
  return useQuery(
    ["get:youtube_video", searchItem],
    async () => otherRequest(url, "get", {}, {}, {}, true),
    //   {
    //   const response = await axios.get(url);
    //   return response.data;
    // },
    {
      
      enabled: Boolean(searchItem),
      onSuccess: (response: any) => successAction?.(response),
      onError: (error: any) => {
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : error?.error?.message || "something went wrong please check internet connection.",
        });
      },
    }
  );
}
