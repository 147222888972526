import React from 'react'

type Props = {
    color?: string,
    bg?: string,
}
function FlashCardIcon({
    color,
    bg,
}: Props) {
    return (
        <svg width="197" height="194" viewBox="0 0 197 194" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_2498_10847)">
                <path d="M20.0905 85.6338V24.7892L12.8384 28.1363C12.7898 28.1587 12.7407 28.1799 12.691 28.1998C5.35835 31.1328 1.60399 40.0538 4.56067 47.4563C4.56154 47.4585 4.56242 47.4607 4.56329 47.4629L20.0905 85.6338ZM44.8468 125.924C44.8194 125.99 44.7899 126.054 44.7581 126.118C44.5322 126.57 44.3751 127.246 44.1291 128.476C44.1194 128.524 44.1095 128.574 44.0993 128.625C43.9378 129.437 43.716 130.551 43.3039 131.62C43.498 137.404 46.441 142.13 52.0329 144.227C52.0583 144.236 52.0837 144.246 52.1089 144.256L118.38 171.123C118.574 171.202 118.76 171.3 118.934 171.416C119.985 172.117 120.732 172.389 121.407 172.524C122.173 172.677 122.976 172.688 124.382 172.688C130.446 172.688 135.435 169.737 137.606 163.948C137.619 163.913 137.632 163.879 137.647 163.845L182.425 56.3775C182.452 56.312 182.482 56.2474 182.513 56.1839C186.097 49.0161 182.631 41.0332 175.176 38.0509L175.163 38.0456L108.981 11.2152C108.291 10.9841 107.703 10.7751 107.183 10.5901C105.424 9.96472 104.439 9.61431 102.889 9.61431C96.8251 9.61431 91.8366 12.5644 89.6657 18.3536C89.6527 18.3883 89.639 18.4229 89.6247 18.4572L44.8468 125.924ZM102.954 122.732C102.061 123.401 100.876 123.538 99.8539 123.089C98.8316 122.64 98.1306 121.675 98.0195 120.564L94.4372 84.7421C94.3295 83.6645 94.793 82.609 95.6594 81.9592L124.317 60.4658C125.21 59.7959 126.395 59.6592 127.418 60.1082C128.44 60.5571 129.141 61.522 129.252 62.633L132.834 98.4553C132.942 99.5329 132.478 100.588 131.612 101.238L102.954 122.732ZM43.2936 65.3832L66.9038 8.71875H58.111C49.9685 8.71875 43.2936 15.3937 43.2936 23.5362V65.3832Z" fill={bg} stroke={color} stroke-width="6.1875" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <filter id="filter0_d_2498_10847" x="0.455078" y="0" width="196.482" height="197.325" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dx="7.425" dy="14.85"/>
                    <feGaussianBlur stdDeviation="1.2375"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2498_10847"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2498_10847" result="shape"/>
                </filter>
            </defs>
        </svg>
    )
}

export default FlashCardIcon